import { Link } from 'react-router-dom'
import React from 'react'

export const RouterLink = ({ to, children }: { to: string; children: React.ReactNode }) => {
  return (
    <Link to={to} style={{ textDecoration: `none`, color: `inherit`, display: `contents` }}>
      {children}
    </Link>
  )
}
