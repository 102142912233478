import { Dispatch, SetStateAction, useEffect, useState, DependencyList } from 'react'

export const useLocalState = <T extends unknown>(
  source: T | (() => T),
  deps: DependencyList = [source],
): [T, Dispatch<SetStateAction<T>>] => {
  const [state, setState] = useState(source)
  useEffect(() => setState(source), deps)
  return [state, setState]
}
