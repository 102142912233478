import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CurrentResourceContainer } from '../../context/current-resource'
import { CurrentCompanyContainer } from '../../context/current-company'
import { Card } from '../../components/Card'
import { DialogHeader } from '../../components/Header'
import { MaterialIcon } from '../../components/Icon'
import { List, ListItem } from '../../components/List'
import { SearchInput } from '../../components/SearchInput'
import { Section, SectionList } from '../../components/Section'
import { QueryAlert } from '../../shared/query-alert'
import { defined } from '../../shared/typescript'
import { useHistory } from '../../shared/use-history'
import { useDebounceValue } from '../../shared/debounce-value'
import { useResourcesQuery } from '../../gateway/react.generated'
import { DialogBackButton, DialogSaveButton } from '../../shared/dialog/DialogButtons'
import { MColor } from '@mprise/react-ui'

export const SettingsResourceRoute = () => {
  const { t } = useTranslation()
  const h = useHistory()

  const [search, setSearch] = useState(``)
  const defaultResource = CurrentResourceContainer.useCurrent()

  const debouncedSearch = useDebounceValue(search, 500)
  const resourceQuery = SettingsResourceRoute.useResourcesQuery(debouncedSearch)

  const [selected, setSelected] = useState(defaultResource.current)

  const handleCancel = () => h.goBack()
  const handleSave = () => {
    defaultResource.setCurrent(selected)
    h.goBack()
  }

  // Select not blocked and not deleted resourcePerson
  const resourcePerson = (resourceQuery.data?.page ?? [])
    .filter(defined)
    .filter(i => i.type === 'Person' && !i.blocked && !i.removed)

  return (
    <>
      <DialogHeader
        title={t('TITLE_SETTINGS')}
        backward={<DialogBackButton onClick={handleCancel} />}
        forward={<DialogSaveButton onClick={handleSave} />}
      />

      <SectionList>
        <Section>
          <Card header={t('TITLE_CHOOSE_RESOURCE')}>
            <QueryAlert queries={[resourceQuery]} />
            <div className='ml-search'>
              <SearchInput onChange={setSearch} />
            </div>
            <List>
              {resourcePerson.map((resource, idx) => (
                <ListItem
                  key={resource?.id ?? idx}
                  icon={<MaterialIcon value='person' style={{ fontSize: `2rem` }} />}
                  primary={resource?.name ?? resource?.name}
                  action={
                    selected?.id === resource?.id ? (
                      <MaterialIcon value='check' style={{ color: MColor.primary }} />
                    ) : null
                  }
                  onClick={() =>
                    resource &&
                    setSelected({
                      id: resource.id,
                      name: resource.name ?? ``,
                      code: resource.code ?? ``,
                    })
                  }
                />
              ))}
            </List>
          </Card>
        </Section>
      </SectionList>
    </>
  )
}

SettingsResourceRoute.useResourcesQuery = (search: string) => {
  const company = CurrentCompanyContainer.useCurrent()
  const query = useResourcesQuery({
    variables: {
      companyId: company.current?.id ?? null,
      whereResource: {
        removed_eq: false,
        or: [{ name_containslower: search }, { code_containslower: search }],
      },
    },
  })
  const data = query.data?.masterdata.company?.resources
  return { ...query, data }
}
