import { useApolloClient } from '@apollo/client'
import { Formik, MAudio, useMField } from '@mprise/react-ui'
import React, { ForwardedRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CurrentCompanyContainer } from '../../context/current-company'
import { ScanningContainer } from '../../context/scanning'
import {
  Position,
  PositionByCodeDocument,
  PositionByCodeQuery,
  PositionByCodeQueryVariables,
} from '../../gateway/react.generated'
import { TransferEntryForm } from '../../routes/transfer/transfer-job/Home'
import { makeQueryCallback } from '../apollo'
import { SelectPositionDialog } from '../dialog/SelectPositionDialog'
import { FlashAlerts } from '../flash-alerts'
import { fail } from '../typescript'
import { FieldBarcode } from '../../mprise-light/FieldBarcode'
import { useHistory } from '../use-history'

export type FieldPositionValue = Pick<Position, 'id' | 'name' | 'code'>

export const FieldPosition = React.forwardRef(
  (
    {
      title,
      label,
      neverDisable,
    }: {
      title: string
      label: string
      neverDisable?: boolean
    },
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    const h = useHistory()
    const { t } = useTranslation()
    const f = useMField()
    const { current: currentCompany } = CurrentCompanyContainer.useCurrent()
    const companyId = currentCompany?.id ?? h.push('/')
    const apollo = useApolloClient()
    const scanningStatus = ScanningContainer.useDefault()
    const alerts = FlashAlerts.useAlert()
    const fc = Formik.useFormikContext<TransferEntryForm>()
    const disabled = neverDisable ? false : fc.values.jobInventoryDetail == null

    const [open, setOpen] = useState(false)

    const handleOpen = () => !disabled && setOpen(true)
    const handleClose = () => setOpen(false)

    // Prevent selection of position that is the same as original position
    const positionOrigin = fc.values.jobInventoryDetail?.position.code
    const scanDialogTitle = t('SCAN_TO_POSITION_CODE')

    return (
      <>
        <SelectPositionDialog
          open={open}
          onClose={handleClose}
          onSave={(position: FieldPositionValue) => {
            if (fc.values.isWorkItemTransfer || label !== `FROM_POSITION`) {
              f.onChange?.(position)
            } else {
              // Empty form values when selected fromPosition manually changes
              resetForm(fc, position)
            }
            setOpen(false)
          }}
          title={title}
        />
        <FieldBarcode<FieldPositionValue>
          disabled={disabled}
          title={t(label)}
          scanningSetting={scanningStatus.scanning}
          valueAsText={x => x.code ?? t(`PLACEHOLDER_NOT_SET`)}
          onLookup={async text => {
            if (!fc.values.isWorkItemTransfer && text === positionOrigin) {
              MAudio.scanError()
              alerts.push(t('NOTIFICATION_POSITION_INVALID'), `error`)
            }
            if (!companyId) {
              fail('expects company id')
            }

            const position = await fetchPositionByCode({ companyId, positionCode: text }, apollo)
            if (position && label === `FROM_POSITION` && !fc.values.isWorkItemTransfer) {
              // Empty form values when selected fromPosition manually changes
              resetForm(fc, position)
            }
            return position
          }}
          onError={() => {
            MAudio.scanError()
          }}
          onSuccess={(text, item) => {
            if (text && item) {
              MAudio.scanSuccess()
            }
            if (text && !item) {
              MAudio.scanError()
              alerts.push(t('NOTIFICATION_NOT_FOUND'), `error`)
            }
          }}
          onExplore={handleOpen}
          scanDialogTitle={scanDialogTitle}
          ref={ref}
        />
      </>
    )
  },
)

const fetchPositionByCode = makeQueryCallback<PositionByCodeQuery, PositionByCodeQueryVariables>(
  PositionByCodeDocument,
)(x => x.masterdata.position ?? null)

function resetForm(fc: Formik.FormikContextType<TransferEntryForm>, position: FieldPositionValue) {
  fc.setValues(current => ({
    ...current,
    job: { id: '', name: '', code: '' },
    fromPosition: { id: position.id ?? '', name: position.name ?? '', code: position.code ?? '' },
    jobInventoryDetail: null,
    originalQuantity: 0,
  }))
}
