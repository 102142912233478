import { useLocation, useNavigate, Location } from 'react-router-dom'

export function useHistory() {
  const navigate = useNavigate()
  const location = useLocation()

  const listen = (callback: (location: Location) => void) => {
    callback(location)
  } // implement the hook yourself

  return {
    push: navigate,
    go: navigate,
    goBack: () => navigate(-1),
    goForward: () => navigate(1),
    listen,
    location,
  }
}
