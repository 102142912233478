import React from 'react'
import { Formik, MAudio, ValidationIssues, withFormikCompareFix } from '@mprise/react-ui'
import { useTranslation } from 'react-i18next'
import { TransferCarrierCodesForm } from './TransferCarriersForm'
import {
  Maybe,
  TransferAndOffloadCarriersToPositionMutation,
  TransferCarriersToPositionMutation,
  WorkItemTemplateTaskOption,
  useStopTaskMutation,
  useTransferAndOffloadCarriersToPositionMutation,
  useTransferCarriersToPositionMutation,
} from '../../../gateway/react.generated'
import { useHistory } from '../../../shared/use-history'
import { FlashAlertApi, FlashAlerts } from '../../../shared/flash-alerts'
import { CurrentResourceContainer } from '../../../context/current-resource'
import { useLocalState } from '../../../shared/local-state'
import { DialogFormik } from '../../../mprise-light/DialogFormik'
import { SavingSwitchPanel } from '../../../shared/saving-switch-panel'
import { MutationErrorMessage } from '../../../shared/apollo'
import { useParams } from 'react-router-dom'
import { FetchResult } from '@mprise/gateway-client'
import { Helmet } from 'react-helmet'
import { fail } from '../../../shared/typescript'
import { ScannedJobInventory } from '../../../shared/interfaces'
import { i18n } from '../../../i18n/instance'

export interface TransferCarrierCodesEntryForm {
  carrierCode: Maybe<{ id: string; name: string; code: Maybe<string> }>
  position: Maybe<{ id: string; name: string; code: Maybe<string> }>
  scannedJobInventories: Maybe<ScannedJobInventory[]>
  jobId: Maybe<string>
  isWorkItemTransfer: boolean
  workItemId: Maybe<string>
  taskOptions: Maybe<WorkItemTemplateTaskOption[]>
  userSelectedStayOnCarriers: boolean
  userSelectedOffloadCarriers: boolean
}

export const TransferCarriersRoute = () => {
  const h = useHistory()
  const { t } = useTranslation()
  const alerts = FlashAlerts.useAlert()

  const schema = TransferCarrierCodesForm.useSchema()
  const resourceId = CurrentResourceContainer.useCurrent().current?.id ?? h.push('/')
  const [stopTaskMutation, stopTaskState] = useStopTaskMutation()

  // If Transfer is used by a redirect of a WorkOrder: Prefill form
  const workItemId = useParams().workItemId
  const taskId = useParams().taskId
  // If phaseCode is defined: do an automatic phase transition at transfer
  const phaseCodeText = useParams().phaseCode
  const phaseCode = phaseCodeText === 'null' ? null : phaseCodeText

  const [initialValues] = useLocalState((): TransferCarrierCodesEntryForm => {
    return withFormikCompareFix({
      carrierCode: { id: '', name: '', code: '' },
      position: { id: '', name: '', code: '' },
      scannedJobInventories: null,
      jobId: null,
      isWorkItemTransfer: workItemId ? true : false,
      workItemId: workItemId ?? null,
      taskOptions: null,
      userSelectedStayOnCarriers: false,
      userSelectedOffloadCarriers: false
    })
  }, [])

  const handleClose = async () => {
    if (workItemId && taskId) {
      await stopTaskMutation({ variables: { workItemId: workItemId, taskId: taskId } })
    }
    h.goBack()
  }

  const handleSubmit = (form: TransferCarrierCodesEntryForm, actions: Formik.FormikHelpers<TransferCarrierCodesEntryForm>) => {
    actions.resetForm()
   
      if (form.userSelectedStayOnCarriers || form.taskOptions?.includes(WorkItemTemplateTaskOption.MoveCarriersToLocationGh)) {
        handleSubmitTransferCarriers(form.position!.id, form.scannedJobInventories)
      } else if (form.userSelectedOffloadCarriers || form.taskOptions?.includes(WorkItemTemplateTaskOption.OffloadCarriersGh)) {
        handleSubmitTransferAndOffloadCarriers(form.position!.id, form.scannedJobInventories)
      }
  }

  const [reportTransferCarriersToPosition, reportTransferCarriersToPositionState] =
    useTransferCarriersToPositionMutation()

  const [reportTransferAndOffloadCarriersToPosition, reportTransferAndOffloadCarriersToPositionState] =
    useTransferAndOffloadCarriersToPositionMutation()

  const handleSubmitTransferCarriers = async (
    positionId: string,
    scannedJobInventories: Maybe<ScannedJobInventory[]>,
  ) => {

    if (!resourceId) {
      fail('expects resource id')
    }

    const jobInventoryIdsAndQuantities =
      scannedJobInventories?.map(entry => ({ jobInventoryDetailId: entry.jidId, quantity: entry.quantity })) ?? []
    const result = await reportTransferCarriersToPosition({
      variables: {
        jobInventoryIdsAndQuantities: jobInventoryIdsAndQuantities,
        destinationPositionId: positionId,
        resourceId: resourceId,
        destinationPhase: phaseCode,
        workItemId: workItemId,
      },
    })

    if (result.data) {
      showSuccesTransferCarriers(result, jobInventoryIdsAndQuantities.length, alerts)
    }
    handleClose()
  }

  const handleSubmitTransferAndOffloadCarriers = async (
    positionId: string,
    scannedJobInventories: Maybe<ScannedJobInventory[]>,
  ) => {
    if (!resourceId) {
      fail('expects resource id')
    }

    const jobInventoryIdsAndQuantities =
      scannedJobInventories?.map(entry => ({ jobInventoryDetailId: entry.jidId, quantity: entry.quantity })) ?? []
    const result = await reportTransferAndOffloadCarriersToPosition({
      variables: {
        jobInventoryIdsAndQuantities: jobInventoryIdsAndQuantities,
        destinationPositionId: positionId,
        resourceId: resourceId,
        destinationPhase: phaseCode ?? null,
        workItemId: workItemId,
      },
    })

    if (result.data) {
      showSuccessTransferAndOffloadCarriers(result, jobInventoryIdsAndQuantities.length, alerts)
    }
    handleClose()
  }

  return (
    <>
      <Helmet title={t('TRANSFER_CARRIER_CODES')} />

      <Formik.Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        <DialogFormik
          minWidth='xl'
          title={t('TRANSFER_CARRIER_CODES')}
          onClose={handleClose}
          onCloseWithContext={handleClose}
          open={true}
        >
          <SavingSwitchPanel
            mutation={[
              reportTransferCarriersToPositionState,
              reportTransferAndOffloadCarriersToPositionState,
              stopTaskState,
            ]}
          >
            <ValidationIssues />
            <MutationErrorMessage
              mutation={[
                reportTransferCarriersToPositionState,
                reportTransferAndOffloadCarriersToPositionState,
                stopTaskState,
              ]}
            />
            <TransferCarrierCodesForm />
          </SavingSwitchPanel>
        </DialogFormik>
      </Formik.Formik>
    </>
  )
}

export function showSuccessTransferAndOffloadCarriers(
  result: FetchResult<TransferAndOffloadCarriersToPositionMutation>,
  totalAmount: number,
  alerts: FlashAlertApi,
) {
  MAudio.scanSuccess()
  const successCount = result.data!.MStransferAndOffloadCarriersToPositionGH?.succeeded?.length
  alerts.push(i18n.t('X_OUT_OF_Y_SUCCESSFULLY_POSTED', { x: successCount, y: totalAmount }), 'success')
}

export function showSuccesTransferCarriers(
  result: FetchResult<TransferCarriersToPositionMutation>,
  totalAmount: number,
  alerts: FlashAlertApi,
) {
  MAudio.scanSuccess()
  const successCount = result.data!.MStransferCarriersToPositionGH?.succeeded?.length
  alerts.push(i18n.t('X_OUT_OF_Y_SUCCESSFULLY_POSTED', { x: successCount, y: totalAmount }), 'success')
}
