import { Formik, MAudio } from '@mprise/react-ui'
import { ScannedUploadCarrier } from '../../../shared/interfaces'
import { FlashAlerts } from '../../../shared/flash-alerts'
import { UploadCarriersEntryForm } from './Home'
import { EntryInfo } from './UpdateUploadCarrierDialog'
import { MDialog, MDialogContentText } from '../../../mprise-light/Dialog'
import { useTranslation } from 'react-i18next'

export const DeleteUploadCarrierDialog = ({
  entry,
  open,
  handleClose,
}: {
  entry: ScannedUploadCarrier
  open: boolean
  handleClose: () => void
}) => {
  const { t } = useTranslation()

  const fc = Formik.useFormikContext<UploadCarriersEntryForm>()
  const alerts = FlashAlerts.useAlert()

  const deleteEntryFromFormContext = async () => {
    MAudio.scanSuccess()
    alerts.push(t('ENTRY_REMOVED'), 'success')

    fc.setValues({
      ...fc.values,
      scannedCarriers: fc.values.scannedCarriers?.filter(i => i.carrierCode !== entry.carrierCode) ?? [],
    })
    handleClose()
  }

  return (
    <MDialog open={open} title={t('REMOVE_FROM_SELECTION')} onSubmit={deleteEntryFromFormContext} onClose={handleClose}>
      <MDialogContentText>{t('QUESTION_ARE_YOU_SURE')}</MDialogContentText>
      <EntryInfo entry={entry} />
    </MDialog>
  )
}
