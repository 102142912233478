import { MpriseAuthProvider, MpriseUser } from '@mprise/react-auth'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useGetResourceByExternalIdLazyQuery } from '../gateway/react.generated'
import { usePersistentState } from '../shared/persistant-state'
import { fail } from '../shared/typescript'

export type ResourceSubset = {
  id: string
  code: string
  name: string
}

export const CurrentResourceContainer = ({ children }: { children: React.ReactNode }) => {
  const [_current, setCurrent] = usePersistentState<ResourceSubset | null>(`__RESOURCE_GH`, null)
  const { t } = useTranslation()

  const { user } = MpriseAuthProvider.useContext()
  const allowedRoles = [`IDENTITYSERVER_USER_ADMIN`, `IDENTITYSERVER_TENANT_ADMIN`, `IDENTITYSERVER_ROLE_ADMIN`]
  const hasAdminRole = user?.roles.some(role => allowedRoles.includes(role))

  const [resourceByExternalId] = useGetResourceByExternalIdLazyQuery({
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    ;(async () => {
      const subjectId = user ? (user as MpriseUser & { sub: string }).sub : null

      if (subjectId) {
        const { data } = await resourceByExternalId({
          variables: {
            subjectId,
          },
        })

        const resource = data?.masterdata?.resource
        // if admin, allow selection of resource.
        if (!hasAdminRole && !resource) {
          fail(t(`NO_RESOURCE_FOUND`, { x: user?.email }))
        }

        if (resource) {
          setCurrent({
            code: resource.code ?? '',
            id: resource.id,
            name: resource.name ?? '',
          })
        }
      }
    })()
  }, [user])

  return (
    <CurrentResourceContainer.Context.Provider value={{ isAdmin: !!hasAdminRole, current: _current, setCurrent }}>
      {children}
    </CurrentResourceContainer.Context.Provider>
  )
}

CurrentResourceContainer.Context = React.createContext<{
  isAdmin: boolean
  current: ResourceSubset | null
  setCurrent: (s: ResourceSubset | null) => void
}>({
  isAdmin: false,
  current: {} as any /* TODO: make position properties optional */,
  setCurrent: () => {
    throw Error(`CurrentResourceContainer missing`)
  },
})
CurrentResourceContainer.useCurrent = () => React.useContext(CurrentResourceContainer.Context)
