
      export type PossibleTypesResultData = {
  "possibleTypes": {
    "TaskExecutor": [
      "Resource",
      "Team"
    ]
  }
};
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "TaskExecutor": [
      "Resource",
      "Team"
    ]
  }
};
      export default result;
    