import {
  Formik,
  MAudio,
  MFieldConnector,
  MFieldInteger,
  MFlexBlock,
  MFlexItem,
  MText,
  withFormikCompareFix,
} from '@mprise/react-ui'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { UploadCarriersEntryForm } from './Home'
import { IconButton } from '@material-ui/core'
import { FlashAlerts } from '../../../shared/flash-alerts'
import { useLocalState } from '../../../shared/local-state'
import { DialogFormik } from '../../../mprise-light/DialogFormik'
import { Section } from '../../../components/Section'
import { MaterialIcon } from '../../../components/Icon'
import { ScannedUploadCarrier } from '../../../shared/interfaces'
import { DeleteUploadCarrierDialog } from './DeleteUploadCarrierDialog'
import { i18n } from '../../../i18n/instance'
import { suppressEnter } from '../../../shared/util/util'

export interface UpdateCarrierCodeForm {
  quantity: number
}

export const UpdateUploadCarrierDialog = ({
  entry,
  open,
  handleClose,
}: {
  entry: ScannedUploadCarrier
  open: boolean
  handleClose: () => void
}) => {
  const { t } = useTranslation()

  const fc = Formik.useFormikContext<UploadCarriersEntryForm>()
  const alerts = FlashAlerts.useAlert()

  const [showDeleteDialog, setShowDeleteDialog] = useState(false)

  const handleDelete = () => setShowDeleteDialog(true)
  const handleStopDelete = () => {
    setShowDeleteDialog(false)
    handleClose()
  }
  const [initialValues] = useLocalState<UpdateCarrierCodeForm>(
    withFormikCompareFix({
      quantity: entry.quantity ?? 0,
    }),
    [],
  )

  const changeQuantityInForm = async (input: ScannedUploadCarrier) => {
    const recordToUpdate = fc.values.scannedCarriers?.find(i => i.carrierCode === input.carrierCode)
    if (!fc.values.scannedCarriers || !recordToUpdate) {
      return
    }
    const updatedScannedCarriers = [
      ...fc.values.scannedCarriers.filter(i => i.carrierCode !== input.carrierCode),
      { ...input, timestamp: Date.now() },
    ]
    MAudio.scanSuccess()
    alerts.push(i18n.t('Quantity updated'), 'success')

    fc.setValues({ ...fc.values, scannedCarriers: updatedScannedCarriers })
  }

  return (
    <>
      <Formik.Formik
        initialValues={initialValues}
        onSubmit={async form => {
          const userInput = {
            ...entry,
            quantity: form.quantity,
          }
          changeQuantityInForm(userInput)
          handleClose()
        }}
      >
        <DialogFormik minWidth='xs' title={t('EDIT')} onClose={handleClose} open={open}>
          <Section>
            <UpdateScannedQuantityForm entry={entry} />
            <MFlexBlock vertical>
              <MFlexItem style={{ padding: '0.5rem', alignItems: 'flex-end' }}>
                <IconButton onClick={handleDelete}>
                  <MaterialIcon value='delete' style={{ fontSize: `2rem` }} />
                </IconButton>
              </MFlexItem>
            </MFlexBlock>
          </Section>
        </DialogFormik>
      </Formik.Formik>
      <DeleteUploadCarrierDialog entry={entry} open={showDeleteDialog} handleClose={handleStopDelete} />
    </>
  )
}

const UpdateScannedQuantityForm = ({ entry }: { entry: ScannedUploadCarrier }) => {
  const { t } = useTranslation()
  const fc = Formik.useFormikContext<UpdateCarrierCodeForm>()

  useEffect(() => {
    const quantity = fc.values.quantity
    if (quantity < 0) {
      fc.setValues({ quantity: 0 })
    }
  }, [fc.values.quantity])

  return (
    <>
      <EntryInfo entry={entry} />
      <MFlexItem style={{ padding: '0.5rem' }}>
        <Formik.Field component={MFieldConnector} name='quantity'>
          <MFlexItem style={{ marginBottom: '0.5rem' }}>
            <MText inline>{t(`QUANTITY`)}</MText>
          </MFlexItem>
          <MFieldInteger onKeyDown={suppressEnter} min={0} />
        </Formik.Field>
      </MFlexItem>
    </>
  )
}

export const EntryInfo = ({ entry }: { entry: ScannedUploadCarrier }) => {
  const { t } = useTranslation()

  return (
    <MFlexItem style={{ padding: '0.5rem', borderBottom: '1px #ccd1d4 solid' }}>
      <MFlexItem style={{ margin: '0.5rem', display: 'grid' }}>
        <MText inline textVariant='inherit'>
          {entry.carrierCode}
        </MText>
      </MFlexItem>
      <MFlexItem style={{ margin: '0.5rem' }}>
        <MText inline textVariant='inherit'>
          {t('QUANTITY')}:
        </MText>
        <MText inline> {entry.quantity}</MText>
      </MFlexItem>
    </MFlexItem>
  )
}
