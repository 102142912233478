import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from '../../shared/use-history'
import { CurrentCompanyContainer } from '../../context/current-company'
import { CurrentResourceContainer } from '../../context/current-resource'
import { SearchWorkItemsQueryVariables, WorkStatus, useSearchWorkItemsLazyQuery } from '../../gateway/react.generated'
import { MColor, MFlex, MFlexBlock, MFlexItem, MText, MTextColor, ScanningSetting, px } from '@mprise/react-ui'
import { WorkItemValue } from './WorkItemDetails'
import { QueryErrorMessage } from '../../shared/apollo'
import { WorkItemList } from './WorkItemList'
import { Badge, CircularProgress, IconButton, Toolbar } from '@material-ui/core'
import { useParams } from 'react-router'
import { ScanningContainer } from '../../context/scanning'
import { FilterDialog, getSearchVariables } from './FilterDialog'
import { mdiFilter } from '@mdi/js'
import { Icon } from '@mdi/react'
import { useDebounceValue } from '../../shared/debounce-value'
import { DialogHeaderBack } from '../../components/Header'
import { Section } from '../../components/Section'
import { StyledTab, StyledTabs } from '../../components/tabs'
import { Helmet } from 'react-helmet'
import { RefreshIcon } from '../../components/RefreshIcon'
import { useApolloClient } from '@mprise/gateway-client'
import { SearchBox } from '../../shared/util/search'

type PageTabType = 'my' | 'all'

export const TasksRoute = () => {
  const tab = (useParams().tab ?? 'my') as PageTabType
  const h = useHistory()
  const { t } = useTranslation()
  const apollo = useApolloClient()

  const [search, setSearch] = useState(sessionStorage.getItem('searchText') ?? '')

  const filter = FilterDialog.useDialogState()

  const { current: currentCompany } = CurrentCompanyContainer.useCurrent()
  const companyId = currentCompany?.id ?? h.push('/')
  const resourceId = CurrentResourceContainer.useCurrent().current?.id ?? h.push('/')

  const handleClose = () => {
    apollo.cache.evict({ id: 'ROOT_QUERY', fieldName: 'searchWorkItems' })
    h.goBack()
  }

  const handleChangeTab = (_: React.ChangeEvent<{}>, newValue: number) => {
    h.push(`/tasks/${newValue}`, { replace: true })
  }

  const handleClickTask = (c: WorkItemValue) => {
    apollo.cache.evict({ id: 'ROOT_QUERY', fieldName: 'searchWorkItems' })
    h.push(`/tasks/details/${c.id}`)
  }
  const handleSearchChange = (text: string) => {
    if (search !== text) {
      setSearch(text)
      sessionStorage.setItem('searchText', text)
    }
  }

  const searchVariables = useDebounceValue<SearchWorkItemsQueryVariables>(
    getSearchVariables({
      tab: tab,
      query: search,
      filter: filter.values,
      companyId: companyId!,
      resourceId: resourceId ?? '',
    }),
    200,
  )

  const [getSearchQuery, searchQuery] = useSearchWorkItemsLazyQuery({
    fetchPolicy: `cache-first`,
    notifyOnNetworkStatusChange: true,
    variables: searchVariables,
  })

  useEffect(() => {
    getSearchQuery()
  }, [])

  const fetchMore = () => {
    if (nextOffset) {
      searchQuery.fetchMore({ variables: { offset: nextOffset } })
    }
  }
  const refetch = () => {
    if (!loading) {
      apollo.reFetchObservableQueries()
    }
  }

  const searchData = searchQuery.data?.searchWorkItems
  const workItems = searchQuery.data?.searchWorkItems?.workItems ?? []
  const nextOffset = searchData ? searchData.offset + searchData.limit : 0
  const loading = searchQuery.loading
  const total = searchData?.total ?? 0
  const scanningStatus = ScanningContainer.useDefault()
  const [scannedStatus, setScanStatus] = useState(scanningStatus.scanning)
  const handleSearchBoxClick = () => {
    if (scanningStatus.scanning === ScanningSetting.CAMERA) {
      setScanStatus(ScanningSetting.KEYBOARD)
    }
  }
  const hasFilter =
    filter.values.status.length !== FilterDialog.emptyForm.status.length ||
    !filter.values.status.every((status: WorkStatus) => FilterDialog.emptyForm.status.includes(status)) ||
    filter.values.startDate !== FilterDialog.emptyForm.startDate ||
    filter.values.endDate !== FilterDialog.emptyForm.endDate

  return (
    <>
      <Helmet title={t('TASKS')} />

      <DialogHeaderBack title={t(`TASKS`)} onClose={handleClose} />
      <FilterDialog
        open={filter.open}
        initialValues={filter.values}
        onClose={filter.handleClose}
        onSave={filter.handleSave}
      />
      <Section>
        <StyledTabs value={tab} onChange={handleChangeTab}>
          <StyledTab
            label={
              <Badge
                color='primary'
                max={9999}
                showZero
                badgeContent={
                  tab === 'my' ? loading ? <CircularProgress color='inherit' size={px(12)} /> : total : null
                }
                overlap='rectangular'
              >
                {t(`MY_TASKS`)}
              </Badge>
            }
            value='my'
          />
          <StyledTab
            label={
              <Badge
                color='primary'
                max={9999}
                showZero
                badgeContent={
                  tab === 'all' ? loading ? <CircularProgress color='inherit' size={px(12)} /> : total : null
                }
                overlap='rectangular'
              >
                {t(`ALL_TASKS`)}
              </Badge>
            }
            value='all'
          />
        </StyledTabs>
        <Toolbar>
          <MFlex grow={1} alignItems='center'>
            <MFlexItem grow={10} shrink={2} />
            <MFlexItem basis={px(480)} grow={1} shrink={1}>
              <SearchBox
                value={search}
                onChange={handleSearchChange}
                scanningSetting={scannedStatus}
                autoFocus={true}
                showSwitchKeyboardOption={false}
                status={0}
                onClick={handleSearchBoxClick}
              />
            </MFlexItem>
            <IconButton onClick={filter.handleOpen}>
              <Icon size={1} path={mdiFilter} color={hasFilter ? MColor.medium : MColor.dim} />
            </IconButton>
            <IconButton onClick={refetch}>
              <RefreshIcon loading={loading} />
            </IconButton>
          </MFlex>
        </Toolbar>
        <QueryErrorMessage query={searchQuery} />
        {workItems.length === 0 ? (
          <MFlexBlock padding={[1, 4, 2, 4]}>
            <MText block textVariant='content bold' textColor={MTextColor.disabled}>
              {t(`NO_RESULTS`)}
            </MText>
          </MFlexBlock>
        ) : null}
        <WorkItemList
          workItems={workItems}
          tab={tab}
          onItemClick={handleClickTask}
          fetchMore={fetchMore}
          total={total ?? 0}
        />
      </Section>
    </>
  )
}
