import { useApolloClient } from '@apollo/client'
import { MAudio, useMField } from '@mprise/react-ui'
import React, { ForwardedRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CurrentCompanyContainer } from '../../context/current-company'
import { ScanningContainer } from '../../context/scanning'
import {
  Reason,
  ReasonByCodeDocument,
  ReasonByCodeQuery,
  ReasonByCodeQueryVariables,
} from '../../gateway/react.generated'
import { makeQueryCallback } from '../apollo'
import { SelectReasonDialog } from '../dialog/SelectReasonDialog'
import { FlashAlerts } from '../flash-alerts'
import { fail } from '../typescript'
import { FieldBarcode } from '../../mprise-light/FieldBarcode'
import { useHistory } from '../use-history'

export type FieldReasonValue = Pick<Reason, 'id' | 'name' | 'code'>

export const FieldReason = React.forwardRef(
  (
    {
      title,
    }: {
      title: string
    },
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    const h = useHistory()
    const { t } = useTranslation()
    const f = useMField()
    const { current: currentCompany } = CurrentCompanyContainer.useCurrent()
    const companyId = currentCompany?.id ?? h.push('/')
    const apollo = useApolloClient()
    const scanningStatus = ScanningContainer.useDefault()
    const alerts = FlashAlerts.useAlert()

    const [open, setOpen] = useState(false)

    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)
    const scanDialogTitle = t('SCAN_REASON_CODE')

    return (
      <>
        <SelectReasonDialog
          open={open}
          onClose={handleClose}
          onSave={(reason: FieldReasonValue) => {
            f.onChange?.(reason)
            setOpen(false)
          }}
          title={title}
        />
        <FieldBarcode<FieldReasonValue>
          title={t(`REASON`)}
          disabled={false}
          scanningSetting={scanningStatus.scanning}
          valueAsText={x => x.code ?? t(`PLACEHOLDER_NOT_SET`)}
          onLookup={async text => {
            if (!companyId) {
              fail('expects company id')
            }
            return await fetchReasonByCode({ companyId, reasonCode: text }, apollo)
          }}
          onError={() => {
            MAudio.scanError()
          }}
          onSuccess={(text, item) => {
            if (text && item) {
              MAudio.scanSuccess()
            }
            if (text && !item) {
              MAudio.scanError()
              alerts.push(t('NOTIFICATION_NOT_FOUND'), `error`)
            }
          }}
          onExplore={handleOpen}
          scanDialogTitle={scanDialogTitle}
          ref={ref}
        />
      </>
    )
  },
)

const fetchReasonByCode = makeQueryCallback<ReasonByCodeQuery, ReasonByCodeQueryVariables>(ReasonByCodeDocument)(
  x => x.masterdata.reason ?? null,
)
