import { MAvatar, MDialog, MSelectItem, ToMSelectItem } from '@mprise/react-ui'
import { LotByFilterQuery, useLotByFilterQuery, LotFilter } from '../../gateway/react.generated'
import { QueryErrorMessage } from '../apollo'
import { useLocalState } from '../local-state'
import { defined, NotOptional } from '../typescript'
import { ScanningContainer } from '../../context/scanning'
import { PaginatedSearchAndSelect } from '../../mprise-light/PaginatedSearchAndSelect'
import { useDebounceValue } from '../debounce-value'

export type LotValue = NotOptional<NotOptional<LotByFilterQuery['lots']>[0]>

const toSelectItem = ToMSelectItem<LotValue>({
  id: x => x.id ?? ``,
  icon: () => <MAvatar.Icon.TrackingId />,
  primary: x => x.lotNumber ?? ``,
})

export const SelectLotNumberDialog = ({
  filter,
  title,
  open,
  itemId,
  companyId,
  onClose,
  onSave,
}: {
  filter?: { open: boolean; itemId: string; companyId: string }
  title: React.ReactNode
  open: boolean
  itemId: string
  companyId: string
  onClose: () => void
  onSave: (values: LotValue | null) => void
}) => {
  const [search, setSearch] = useLocalState(``, [open])
  const debouncedSearch = useDebounceValue(search, 500)

  const whereLot: LotFilter = {
    companyId: companyId,
    open: true,
    itemId: itemId,
    lotNumber: debouncedSearch,
  }

  if (filter && filter.open) {
    whereLot.open = filter.open
  }

  if (filter && filter.itemId) {
    whereLot.itemId = filter.itemId
  }

  const lotQuery = useLotByFilterQuery({
    variables: {
      lotFilter: whereLot,
    },
  })

  const handleSelect = async (item: MSelectItem<LotValue> | null) => {
    onSave(item?.data ?? null)
  }

  const handleSubmit: React.FormEventHandler = async e => {
    e.stopPropagation()
    e.preventDefault()
    onClose()
  }

  const handleNextPage = (cursor: string) => {
    lotQuery.fetchMore({ variables: { lotCursor: cursor } })
  }

  const lots = lotQuery.data?.lots
  const items = lots?.filter(defined).map(toSelectItem) ?? null
  const nextCursor = null

  const scanningSetting = ScanningContainer.useDefault().scanning

  return (
    <MDialog minWidth='sm' open={open} title={title} onClose={onClose} onSubmit={handleSubmit}>
      <QueryErrorMessage query={lotQuery} />
      <PaginatedSearchAndSelect
        scanningSetting={scanningSetting}
        items={items}
        nextCursor={nextCursor}
        search={search}
        value={null}
        onChange={handleSelect}
        onNextPage={handleNextPage}
        onSearch={setSearch}
        showSwitchKeyboardOption={true}
      />
    </MDialog>
  )
}
