export class Settings {
  static get version() {
    return (global.window?.document?.querySelector(`meta[name="mprise-version"]`) as HTMLMetaElement)?.content ?? ``
  }
  static get gateway() {
    return (global.window?.document?.querySelector(`meta[name="mprise-gateway"]`) as HTMLMetaElement)?.content ?? ``
  }
  static get login() {
    return (global.window?.document?.querySelector(`meta[name="mprise-login"]`) as HTMLMetaElement)?.content ?? ``
  }
  static get clientId() {
    return (global.window?.document?.querySelector(`meta[name="mprise-clientId"]`) as HTMLMetaElement)?.content ?? ``
  }
}
