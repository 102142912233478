import React from 'react'
import { WorkItemValue } from './WorkItemDetails'
import { WorkItemCard } from './WorkItemCard'
import { NextPageView } from '@mprise/react-ui/dist/pagination/next'

export const WorkItemList = ({
  workItems,
  tab,
  onItemClick,
  fetchMore,
  total,
}: {
  workItems: WorkItemValue[]
  tab: string
  onItemClick: (workItem: WorkItemValue) => void
  fetchMore: (tab: string) => void
  total: number
}) => {
  const showNextPageView = workItems.length > 0 && workItems.length < total

  return (
    <>
      {workItems.length > 0
        ? workItems.map(item => <WorkItemCard key={item.id} workItem={item} onClick={onItemClick} />)
        : null}
      {showNextPageView && <NextPageView cursor={tab} fetch={fetchMore} key={`${tab}-${workItems.length}`} />}
    </>
  )
}
