import React from 'react'
import { Button } from './Button'
import { MaterialIcon } from './Icon'
import { MEnvironment } from '@mprise/react-ui'

export const DialogHeader = ({
  backward,
  forward,
  title,
}: {
  backward?: React.ReactNode
  forward?: React.ReactNode
  title?: React.ReactNode
}) => {
  return (
    <DialogHeader.Container>
      <DialogHeader.Backward>{backward}</DialogHeader.Backward>
      <DialogHeader.Center>
        <DialogHeader.Text>{title}</DialogHeader.Text>
      </DialogHeader.Center>
      <DialogHeader.Forward>{forward}</DialogHeader.Forward>
    </DialogHeader.Container>
  )
}
DialogHeader.Container = ({ children }: { children?: React.ReactNode }) => (
  <header className={`ml-dialogheader-container`}>{children}</header>
)
DialogHeader.Backward = ({ children }: { children?: React.ReactNode }) => (
  <div className={`ml-dialogheader-backward`}>{children}</div>
)
DialogHeader.Center = ({ children }: { children?: React.ReactNode }) => (
  <div className={`ml-dialogheader-center`}>{children}</div>
)
DialogHeader.Text = ({ children }: { children?: React.ReactNode }) => (
  <div className={`gh-dialog-header-title`}>{children}</div>
)
DialogHeader.Forward = ({ children }: { children?: React.ReactNode }) => (
  <div className={`ml-dialogheader-forward`}>{children}</div>
)

export const Toolbar = ({
  backward,
  forward,
  center,
}: {
  backward?: React.ReactNode
  forward?: React.ReactNode
  center?: React.ReactNode
}) => {
  return (
    <Toolbar.Container>
      <Toolbar.Backward>{backward}</Toolbar.Backward>
      <Toolbar.Center> {center}</Toolbar.Center>
      <Toolbar.Forward>
        <span className='ml-environment'>
          <MEnvironment />
        </span>
        {forward}
      </Toolbar.Forward>
    </Toolbar.Container>
  )
}
Toolbar.Container = ({ children }: { children?: React.ReactNode }) => (
  <header className={`ml-toolbar-container`}>{children}</header>
)
Toolbar.Backward = ({ children }: { children?: React.ReactNode }) => (
  <div className={`ml-toolbar-backward`}>{children}</div>
)
Toolbar.Center = ({ children }: { children?: React.ReactNode }) => <div className={`ml-toolbar-center`}>{children}</div>
Toolbar.Forward = ({ children }: { children?: React.ReactNode }) => (
  <div className={`ml-toolbar-forward`}>{children}</div>
)

export const DialogHeaderBack = ({ title, onClose }: { title?: React.ReactNode; onClose: () => void }) => {
  return (
    <DialogHeader
      backward={<Button dataCy={''} color={`#009a44`} icon={<MaterialIcon value='arrow_back' />} onClick={onClose} />}
      forward={<div style={{ width: '48px' }}></div>}
      title={title}
    />
  )
}
