import React from 'react'

export const Alert = ({ icon, children }: { icon: React.ReactNode; children: React.ReactNode }) => {
  return (
    <Alert.Container>
      <Alert.Icon>{icon}</Alert.Icon>
      <Alert.Label>{children}</Alert.Label>
    </Alert.Container>
  )
}
Alert.Container = ({ children }: { children?: React.ReactNode }) => (
  <header className={`ml-alert-container`}>{children}</header>
)
Alert.Icon = ({ children }: { children?: React.ReactNode }) => <div className={`ml-alert-icon`}>{children}</div>
Alert.Label = ({ children }: { children?: React.ReactNode }) => <div className={`ml-alert-label`}>{children}</div>
