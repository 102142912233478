import { useTranslation } from 'react-i18next'
import { JobInventoryDetailValue } from '../../shared/dialog/SelectJobInventoryDetailDialog'
import { JobValue } from './JobForm'
import { formatUnitOfMeasure } from '../../shared/formats'

export const JobDetailsCard = ({
  job,
  jobInventoryDetails,
}: {
  job: JobValue
  jobInventoryDetails: JobInventoryDetailValue[]
}) => {
  const { t } = useTranslation()

  const remainingQuantity = jobInventoryDetails
    .map(jid => jid.remainingQuantity)
    .reduce((x, y) => x + y, 0)
    .toLocaleString('nl-NL')

  const unitOfMeasure = formatUnitOfMeasure(jobInventoryDetails[0]?.unitOfMeasure)

  return (
    <div className='gh-jobdetail-card'>
      <div className='gh-jobdetail-row'>
        <div>{job?.code}</div>
        <div>
          # {remainingQuantity}&nbsp;{unitOfMeasure}
        </div>
      </div>
      <div className='gh-jobdetail-row'>
        <div>{job?.name}</div>
      </div>
      <div className='gh-jobdetail-row'>
        <div>{job?.item?.description}</div>
      </div>
    </div>
  )
}
