import styled from '@emotion/styled'
import { MBlock, MColor, MText } from '@mprise/react-ui'
import { useFormikContext } from 'formik'
import React from 'react'

/* Copied from mprise/react-ui.
 * Made sticky so error messages stay visible when scrolling
 */
export const ValidationIssues = () => {
  const formik = useFormikContext()
  const submitted = formik.submitCount > 0
  const errors = unwind(formik.errors)
  return submitted && errors.length ? (
    <ValidationIssues.Container>
      {errors.map((text, idx) => (
        <ValidationIssues.Entry key={idx}>{text}</ValidationIssues.Entry>
      ))}
    </ValidationIssues.Container>
  ) : null
}

ValidationIssues.Container = ({ children }: { children: React.ReactNode }) => {
  return (
    <MBlock bgColor={MColor.medium} margin={0} padding={4} style={{ position: 'sticky', top: '70px', zIndex: '99' }}>
      <MText block textVariant='content'>
        {children}
      </MText>
    </MBlock>
  )
}

ValidationIssues.Entry = styled.p`
  margin: 0;
`

const unwind = (errors: string | string[] | Record<string, any>): string[] => {
  if (typeof errors === `string`) {
    return [errors]
  } else if (Array.isArray(errors)) {
    return errors.flatMap(x => unwind(x))
  } else {
    return unwind(Array.from(Object.values(errors)))
  }
}
