import { DependencyList, useCallback, useEffect, useMemo, useState } from 'react'

export const useAsync = <T,>(fn: (abort: AbortSignal) => Promise<T>, deps: DependencyList) => {
  const [state, setState] = useState<{
    abort: AbortController | null
    loading: boolean
    error: Error | null
    data: T | null
  }>({
    abort: null,
    loading: false,
    error: null,
    data: null,
  })

  const execute = useCallback(async (optionalAbort?: AbortController) => {
    const abort = optionalAbort ?? new AbortController()

    setState(({ data }) => ({
      abort,
      loading: true,
      error: null,
      data,
    }))
    try {
      const data = await fn(abort.signal)
      if (!abort.signal.aborted) {
        setState(() => ({
          abort: null,
          loading: false,
          error: null,
          data,
        }))
      }
    } catch (error) {
      if (!abort.signal.aborted) {
        // setState(() => ({
        //   abort: null,
        //   loading: false,
        //   error,
        //   data: null,
        // }))
      }
    }
  }, deps)

  useEffect(() => {
    const abort = new AbortController()
    execute(abort)
    return () => abort.abort()
  }, [execute])

  const result = useMemo(() => ({ ...state, refetch: execute }), [state, execute])

  return result
}
