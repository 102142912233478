import { useTranslation } from 'react-i18next'
import { JobProductionPhase, Maybe } from '../../gateway/react.generated'
import { JobInventoryDetailValue } from '../dialog/SelectJobInventoryDetailDialog'
import { formatDateWithWeekNo, formatUnitOfMeasure } from '../formats'

export const SelectedJobInventoryDetails = ({
  jobInventoryDetail,
  showPosition,
}: {
  jobInventoryDetail: Maybe<JobInventoryDetailValue>
  showPosition: boolean
}) => {
  const { t } = useTranslation()

  const job = jobInventoryDetail?.job.code ?? ''
  const item = jobInventoryDetail?.job?.item?.name ?? ''
  const position = jobInventoryDetail?.position.code ?? ''
  const sortingCode = jobInventoryDetail?.sortingCode
  const productionPhase = jobInventoryDetail?.productionPhase ?? ''
  const remainingQuantity = jobInventoryDetail?.remainingQuantity ?? 0
  const carrierCode = jobInventoryDetail?.carrierCode
  const remQtyPerUnitOfMeasure = jobInventoryDetail?.remQtyPerUnitOfMeasure ?? 0

  const unitOfMeasure = formatUnitOfMeasure(jobInventoryDetail?.unitOfMeasure)
  const remainingQuantityText =
    remainingQuantity > 0
      ? `${remainingQuantity.toLocaleString('nl-NL')} ${unitOfMeasure}` +
        (remQtyPerUnitOfMeasure === 0 || remQtyPerUnitOfMeasure > 1 ? ` | ${remQtyPerUnitOfMeasure} per UOM` : '')
      : ''

  const classNameHeader = jobInventoryDetail ? 'gh-selected-form-header' : 'gh-selected-form-header-empty'
  const classNameKey = jobInventoryDetail ? 'gh-selected-key' : 'gh-selected-key-empty'

  return (
    <>
      <div className={classNameHeader}>
        {jobInventoryDetail ? t(`SELECTED_INVENTORY`) : t(`NO_SELECTED_INVENTORY`)}{' '}
      </div>

      <div className='gh-selected-form-details'>
        <div className='gh-flexrow'>
          <div className={classNameKey}>{`${t(`ITEM`)}:`}</div>
          <div>{item}</div>
        </div>

        <div className='gh-flexrow'>
          <div className={classNameKey}>{`${t(`PHASE`)}:`}</div>
          <div> {` ${productionPhase + (sortingCode ? ` | ${sortingCode}` : '')}`} </div>
        </div>

        <div className='gh-flexrow'>
          <div className={classNameKey}>{`${t(`QUANTITY`)}:`}</div>
          <div>{remainingQuantityText}</div>
        </div>
        {showPosition && (
          <div className='gh-flexrow'>
            <div className={classNameKey}>{`${t(`POSITION`)}:`}</div>
            <div>{position + (carrierCode ? ` | ${carrierCode}` : '')}</div>
          </div>
        )}
      </div>
    </>
  )
}

export const SelectedPhaseDetails = ({ phase }: { phase: Maybe<JobProductionPhase> | undefined }) => {
  const { t } = useTranslation()

  const productionPhase = phase?.productionPhase
  const sortingCode = phase?.sortingCode
  const startingDate = phase?.startingDate
  const endDate = phase?.endDate

  const classNameHeader = phase ? 'gh-selected-form-header' : 'gh-selected-form-header-empty'
  const classNameKey = phase ? 'gh-selected-key' : 'gh-selected-key-empty'

  return (
    <>
      <div className={classNameHeader}>{phase ? t(`SELECTED_PHASE`) : t(`NO_SELECTED_PHASE`)} </div>

      <div className='gh-selected-form-details'>
        <div className='gh-flexrow'>
          <div className={classNameKey}>{`${t(`PHASE`)}:`}</div>
          <div>{productionPhase}</div>
        </div>

        {sortingCode && (
          <div className='gh-flexrow'>
            <div className={classNameKey}>{`${t(`SORTING_CODE`)}:`}</div>
            <div>{sortingCode}</div>
          </div>
        )}

        <div className='gh-flexrow'>
          <div className={classNameKey}>{`${t('START_DATE')}:`}</div>
          <div>{formatDateWithWeekNo(startingDate)}</div>
        </div>

        <div className='gh-flexrow'>
          <div className={classNameKey}>{`${t('END')}:`}</div>
          <div>{formatDateWithWeekNo(endDate)}</div>
        </div>
      </div>
    </>
  )
}

export const SelectedReasonDetails = ({
  reason,
}: {
  reason: Maybe<{ id: string; name: string; code: Maybe<string> }>
}) => {
  const { t } = useTranslation()
  const reasonName = reason?.name && reason.name.length > 0 ? reason.name : null

  const classNameHeader = reasonName ? 'gh-selected-form-header' : 'gh-selected-form-header-empty'
  const classNameKey = reasonName ? 'gh-selected-key' : 'gh-selected-key-empty'

  return (
    <>
      <div className={classNameHeader}>{reasonName ? t(`SELECTED_REASON`) : t(`NO_SELECTED_REASON`)} </div>
      <div className='gh-selected-form-details'>
        <div className='gh-flexrow'>
          <div className={classNameKey}>{`${t(`REASON`)}:`}</div>
          <div> {reasonName ?? ''} </div>
        </div>
      </div>
    </>
  )
}
