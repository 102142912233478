import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom'
import { MenuRoute } from './routes/Menu'
import { SettingsRoute } from './routes/settings/Home'
import { SettingsLanguageRoute } from './routes/settings/Language'
import { SettingsScanningRoute } from './routes/settings/Scanning'
import { SettingsCompanyRoute } from './routes/settings/Company'
import { TransferRoute } from './routes/transfer/transfer-job/Home'
import { ScrapRoute } from './routes/scrap/Home'
import { PhaseRoute } from './routes/phase/Home'
import { AppLanguageContainer } from './context/app-language'
import { ScanningContainer } from './context/scanning'
import { CurrentCompanyContainer } from './context/current-company'
import { CurrentResourceContainer } from './context/current-resource'
import { SettingsResourceRoute } from './routes/settings/Resource'
import './App.css'
import { TasksRoute } from './routes/tasks/Home'
import { WorkItemDetailsRoute } from './routes/tasks/WorkItemDetails'
import { TransferMenuRoute } from './routes/transfer/TransferMenu'
import { TransferCarriersRoute } from './routes/transfer/transfer-carriers/Home'
import { JobRoute } from './routes/job/Home'
import { UploadCarriersRoute } from './routes/transfer/upload-carriers/Home'
import { CreateInventoryRoute } from './routes/tasks/create-inventory/Home'
import { JobPickRoute } from './routes/tasks/job-pick/Home'

export const App = () => {
  return (
    <AppLanguageContainer>
      <ScanningContainer>
        <CurrentCompanyContainer>
          <CurrentResourceContainer>
            <RouterProvider router={router} />
          </CurrentResourceContainer>
        </CurrentCompanyContainer>
      </ScanningContainer>
    </AppLanguageContainer>
  )
}

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path='/settings/language' element={<SettingsLanguageRoute />} />
      <Route path='/settings/company' element={<SettingsCompanyRoute />} />
      <Route path='/settings/scanning' element={<SettingsScanningRoute />} />
      <Route path='/settings/resource' element={<SettingsResourceRoute />} />
      <Route path='/settings' element={<SettingsRoute />} />
      <Route path='/tasks/:tab?' element={<TasksRoute />} />
      <Route path='/tasks/details/:id' element={<WorkItemDetailsRoute />} />
      <Route path='/job' element={<JobRoute />} />
      <Route path='/transfer' element={<TransferMenuRoute />} />
      <Route path='/transfer/job' element={<TransferRoute />} />
      <Route path='/transfer/job/:workItemId/:taskId/:phaseCode' element={<TransferRoute />} />
      <Route path='/transfer/carriercodes' element={<TransferCarriersRoute />} />
      <Route path='/transfer/carriercodes/:workItemId/:taskId/:phaseCode' element={<TransferCarriersRoute />} />
      <Route path='/transfer/uploadcarriers' element={<UploadCarriersRoute />} />
      <Route path='/transfer/uploadcarriers/:workItemId/:taskId/:phaseCode' element={<UploadCarriersRoute />} />
      <Route path='/transfer/create-inventory/:workItemId/:taskId' element={<CreateInventoryRoute />} />
      <Route path='/transfer/job-pick/:workItemId/:taskId/:phaseCode' element={<JobPickRoute />} />
      <Route path='/scrap' element={<ScrapRoute />} />
      <Route path='/phase' element={<PhaseRoute />} />
      <Route path='/' element={<MenuRoute />} />
    </Route>,
  ),
)
