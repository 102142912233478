import styled from '@emotion/styled'
import IconRadioButtonChecked from '@material-ui/icons/RadioButtonChecked'
import IconRadioButtonUnchecked from '@material-ui/icons/RadioButtonUnchecked'
import {
  MColor,
  MFlexBlock,
  MFlexItem,
  MSelectItem,
  MSelectItemClickHandler,
  MText,
  MTextColor,
} from '@mprise/react-ui'
import { Badge } from '@mprise/react-ui/dist/controls/avatar/badge'
import React from 'react'

/* Copied from mprise/react-ui. 

*/
export interface MSelectListItemProps<T> {
  selected: boolean
  item: MSelectItem<T>
  onClick: MSelectItemClickHandler<T>
}

export const SelectListItem = <T,>(p: MSelectListItemProps<T>) => {
  const { selected, item, onClick } = p
  const radio = selected ? <IconRadioButtonChecked color='primary' /> : <IconRadioButtonUnchecked color='action' />
  const avatar = item.icon
  const primary = item.primary
  const secondary = item.secondary
  const handleClick: React.MouseEventHandler = e => {
    onClick(item, e)
  }

  return (
    <StyledButton type='button' onClick={handleClick}>
      <MFlexBlock alignItems='center' gap={2} padding={2}>
        {/* <MFlexItem shrink={0} grow={0}>
          {radio}
        </MFlexItem> */}
        <MFlexItem shrink={0} grow={0} style={{ marginRight: '0.5rem' }}>
          <Badge size='medium' color={MColor.dim} children={avatar} />
        </MFlexItem>
        <MFlexItem>
          <MText block textVariant='content' textColor={MTextColor.dark}>
            {primary}
          </MText>
          {secondary && (
            <MText block textVariant='small' textColor={MTextColor.dark}>
              {secondary}
            </MText>
          )}
        </MFlexItem>
      </MFlexBlock>
    </StyledButton>
  )
}

const StyledButton = styled.button`
  border: 0;
  display: block;
  width: 100%;
  appearance: none;
  padding: 0;
  background: transparent;
  cursor: pointer;
  text-align: inherit;
`
