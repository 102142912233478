import { useEffect, useState } from 'react'
import { Formik, useMField } from '@mprise/react-ui'
import { PhaseEntryForm } from '../../routes/phase/Home'
import { ScrapEntryForm } from '../../routes/scrap/Home'
import { TransferEntryForm } from '../../routes/transfer/transfer-job/Home'
import { JobInventoryDetailValue, SelectJobInventoryDetailDialog } from '../dialog/SelectJobInventoryDetailDialog'
import { CurrentCompanyContainer } from '../../context/current-company'
import { Item, Maybe, useJobInventoryDetailsByFilterLazyQuery } from '../../gateway/react.generated'
import { defined, fail } from '../typescript'
import { useHistory } from '../use-history'
import { JobPickEntryForm } from '../../routes/tasks/job-pick/Home'

export type EntryFormType = TransferEntryForm | ScrapEntryForm | PhaseEntryForm

export const FieldJobInventoryDetail = ({
  title,
  includeCarrierCodes = false,
  itemConstraint,
}: {
  title: string
  includeCarrierCodes?: boolean
  itemConstraint?: Maybe<Pick<Item, 'id' | 'name'>>
}) => {
  const f = useMField()
  const fc = Formik.useFormikContext<EntryFormType>()
  const h = useHistory()
  const { current: currentCompany } = CurrentCompanyContainer.useCurrent()
  const companyId = currentCompany?.id ?? h.push('/')

  const jobId = fc.values.job?.id
  const fromPositionId = fc.values.fromPosition?.id
  const workItemId = fc.values.workItemId

  const hasSelectedJob = !!jobId
  const hasSelectedFromPosition = !!fromPositionId
  const hasSelectedJid = !!fc.values.jobInventoryDetail

  const [open, setOpen] = useState(false)
  const handleClose = () => setOpen(false)

  const [getJobInventoryDetails, jobInventoryDetailsQuery] = useJobInventoryDetailsByFilterLazyQuery({
    fetchPolicy: `no-cache`,
  })

  useEffect(() => {
    if (hasSelectedJid) {
      return
    }
    const itemId = (fc.values.job as JobPickEntryForm['job'])?.item?.id
    if (itemConstraint && itemId && itemConstraint.id !== itemId) {
      return
    }
    if ((hasSelectedJob && !hasSelectedFromPosition) || (hasSelectedFromPosition && !hasSelectedJob)) {
      if (!companyId) {
        fail('expects company id')
      }

      getJobInventoryDetails({
        variables: {
          filter: {
            companyId: companyId,
            jobId: hasSelectedFromPosition ? null : jobId,
            positionId: hasSelectedJob ? null : fromPositionId,
            includeCarrierCodes: includeCarrierCodes,
            excludedWorkItemId: workItemId ?? null,
          },
        },
      })
    }
  }, [jobId, fromPositionId])

  const jobInventoryDetails = jobInventoryDetailsQuery.data?.MSjobInventoryDetails?.filter(defined) ?? []

  useEffect(() => {
    if (jobInventoryDetailsQuery.data) {
      // Open select jobInventory Dialog if multiple or no jid
      if (jobInventoryDetails.length !== 1 && (hasSelectedJob || hasSelectedFromPosition)) {
        if (jobInventoryDetails.length === 0) {
          f.onChange?.(null)
        }
        setOpen(true)
      } else if (jobInventoryDetails.length === 1) {
        // Set jobInventoryDetail if only 1 jid found
        const jid = jobInventoryDetails[0] as JobInventoryDetailValue
        f.onChange?.(jid)
      }
    }
  }, [jobInventoryDetailsQuery])

  useEffect(() => {
    const jid = fc.values.jobInventoryDetail
    if (jid && jid.job && jid.position) {
      // prefill job and fromPosition when jobInventory is selected
      prefillForm(fc, jid)
    }
  }, [fc.values.jobInventoryDetail])

  return (
    <>
      {jobInventoryDetailsQuery.data && jobInventoryDetails.length !== 1 && (
        <SelectJobInventoryDetailDialog
          open={open}
          jobInventoryDetails={jobInventoryDetails}
          includeCarrierCodes={includeCarrierCodes}
          onClose={handleClose}
          onSave={(jobInventoryDetail: JobInventoryDetailValue) => {
            f.onChange?.(jobInventoryDetail)
            setOpen(false)
          }}
          title={title}
        />
      )}
    </>
  )
}

function prefillForm(fc: Formik.FormikContextType<EntryFormType>, jid: JobInventoryDetailValue) {
  fc.setValues(current => ({
    ...current,
    job: {
      id: jid.job.id!,
      name: jid.job.name!,
      code: jid.job.code!,
    },
    fromPosition: {
      id: jid.position.id!,
      name: jid.position.name!,
      code: jid.position.code!,
    },
    originalQuantity: jid.remainingQuantity,
  }))
}
