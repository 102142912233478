import { useCallback, useEffect, useState } from 'react'

export const usePersistentState = <T extends unknown>(
  key: string,
  initialValue: T | (() => T),
): [T, React.Dispatch<React.SetStateAction<T>>, () => void] => {
  const [state, setState] = useState<T>(() => {
    const saved = global.window?.localStorage?.getItem(key)
    if (saved) {
      try {
        return JSON.parse(saved) as T
      } catch (ex) {
        console.error(`[peristent-state] load`, ex)
      }
    }
    return typeof initialValue === `function` ? (initialValue as Function)() : initialValue
  })
  useEffect(() => {
    try {
      const serialized = JSON.stringify(state)
      global.window?.localStorage?.setItem(key, serialized)
    } catch (ex) {
      console.error(`[peristent-state] save`, ex)
      global.window?.localStorage?.removeItem(key)
    }
  }, [state])
  const reset = useCallback(() => {
    global.window?.localStorage?.removeItem(key)
    setState(initialValue)
  }, [])
  return [state, setState, reset]
}
