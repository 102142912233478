import { DateTimeFormatter, LocalDate } from '@js-joda/core'
import { i18n } from '../i18n/instance'

const dateFormatter = DateTimeFormatter.ofPattern('dd-MM-yyyy')

export const formatDateWithWeekNo = (date?: LocalDate) => {
  if (!date) {
    return ''
  }

  const weekNo = date?.isoWeekOfWeekyear()
  return `${date?.format(dateFormatter)} ${weekNo ? `wk: ${weekNo}` : ''}`
}

export const formatUnitOfMeasure = (unitOfMeasure?: string | null) => {
  if (!unitOfMeasure) {
    return ''
  }
  switch (unitOfMeasure) {
    case 'Production Units of Measure':
    case 'PCS':
    case 'Productie-eenheden': //Dutch value added as a temporary workaround (BC is saving already localized values)
    case 'STUK': //Dutch value added as a temporary workaround (BC is saving already localized values)
      return i18n.t('PRODUCTION_UNITS_OF_MEASURE')
    case 'Containers':
      return i18n.t('CONTAINERS')
    case 'Pots':
      return i18n.t('Pots')
    default:
      return unitOfMeasure
  }
}
