import { Divider, IconButton, makeStyles } from '@material-ui/core'
import { mdiMagnify } from '@mdi/js'
import Icon from '@mdi/react'
import { Formik, MColor, MFlexBlock, MFlexItem, MText, useMField } from '@mprise/react-ui'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ScanningContainer, ScanningSetting } from '../../context/scanning'
import { PhaseEntryForm } from '../../routes/phase/Home'
import { SelectPhaseDialog } from '../dialog/SelectPhaseDialog'
import { FieldTitle } from '../../components/FieldTitle'

export const FieldPhase = ({ title }: { title: string }) => {
  const { t } = useTranslation()

  const f = useMField()
  const fc = Formik.useFormikContext<PhaseEntryForm>()
  const disabled = fc.values.jobInventoryDetail == null
  const scanningStatus = ScanningContainer.useDefault()
  const enableInstantScan = [ScanningSetting.CAMERA].includes(scanningStatus.scanning)
  const phase = fc.values?.phase

  const [open, setOpen] = useState(false)
  const handleOpen = (e: any) => {
    e.stopPropagation()
    e.preventDefault()
    if (!disabled) {
      setOpen(true)
    }
  }
  const handleClose = () => {
    setOpen(false)
  }
  const classes = useStyles()

  return (
    <>
      <FieldTitle disabled={disabled} title={t(`PHASE`)} />

      <SelectPhaseDialog
        open={open}
        onClose={handleClose}
        onSave={(phase: any) => {
          f.onChange?.(phase)
          setOpen(false)
        }}
        title={title}
      />
      <MFlexBlock bgColor={MColor.white} variant='rounded' alignItems='center' grow={1} gap={2}>
        <MFlexItem
          className={classes.fieldPhase}
          style={disabled ? { borderColor: '#e0e0e0' } : {}}
          onClick={handleOpen}
        >
          <MText
            inline
            style={{
              float: 'left',
              padding: '8px',
              fontSize: '1rem',
              fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            }}
          >
            {phase ? phase.productionPhase : ' '}
          </MText>
        </MFlexItem>
        <Divider
          className={classes.divider}
          orientation='vertical'
          style={enableInstantScan ? { marginRight: '56px' } : {}}
        />
        <IconButton onClick={handleOpen}>
          <Icon path={mdiMagnify} size={1} style={disabled ? { color: '#e0e0e0' } : {}} />
        </IconButton>
      </MFlexBlock>
    </>
  )
}

const useStyles = makeStyles(() => ({
  divider: {
    height: 28,
    margin: 4,
  },
  fieldPhase: {
    border: '1px solid #839ab4',
    width: '97%',
    height: '33px',
    borderRadius: '3px',
  },
}))
