import { LazyQueryResult, QueryResult } from '@mprise/gateway-client'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Alert } from '../components/Alert'
import { MaterialIcon } from '../components/Icon'
import { CollapseWrapper } from '../shared/collapse-wrapper'

export const QueryAlert = ({ queries }: { queries: Array<LazyQueryResult<unknown, unknown> | QueryResult> }) => {
  const { t } = useTranslation()
  const errorMessages = Array.from(new Set(queries.flatMap(x => x.error ?? []).map(x => x.message)))

  return (
    <CollapseWrapper isOpened={errorMessages.length > 0}>
      <Alert icon={<MaterialIcon value='warning' />}>
        {t('NOTIFICATION_ERROR_FETCH_FAILED')}
        {errorMessages.map((x, idx) => (
          <pre key={idx}>{x === 'Failed to fetch' ? t(`FAILED_TO_FETCH`) : x}</pre>
        ))}
      </Alert>
    </CollapseWrapper>
  )
}
