import { useEffect, useRef, useState } from 'react'
import { Formik, MAudio, MFieldConnector, MFlex, Yup } from '@mprise/react-ui'
import { useTranslation } from 'react-i18next'
import { TransferCarrierCodesEntryForm } from './Home'
import { ScannedCarriersSection } from '../../../shared/section/ScannedCarriersSection'
import { FlashAlerts } from '../../../shared/flash-alerts'
import { CurrentCompanyContainer } from '../../../context/current-company'
import {
  useJobInventoryDetailsForCarriersLazyQuery,
  useOutboundByWorkItemIdLazyQuery,
  useWorkTaskByIdWithTaskResultsQuery,
} from '../../../gateway/react.generated'
import { ScannedJobInventory } from '../../../shared/interfaces'
import { Section } from '../../../components/Section'
import { defined, nameof, fail } from '../../../shared/typescript'
import { FieldCarrierCode } from '../../../shared/form/FieldCarrierCode'
import { FieldPosition } from '../../../shared/form/FieldPosition'
import { useParams } from 'react-router-dom'
import { TaskDetailsSection } from '../../../shared/form/TaskDetailsSection'
import { useHistory } from '../../../shared/use-history'
import { useBeforeUnload } from '../../../shared/before-unload'
import { i18n } from '../../../i18n/instance'
import { Field } from 'formik'
import { RadioButtonField } from '../../../shared/form/RadioButtonField'

export const TransferCarrierCodesForm = () => {
  const { t } = useTranslation()
  const alerts = FlashAlerts.useAlert()
  const fc = Formik.useFormikContext<TransferCarrierCodesEntryForm>()
  const h = useHistory()
  const { current: currentCompany } = CurrentCompanyContainer.useCurrent()
  const companyId = currentCompany?.id ?? h.push('/')
  const carrierCode = fc.values.carrierCode
  const taskId = useParams().taskId ?? ''

  useBeforeUnload(!!fc.values.scannedJobInventories?.length && !fc.isSubmitting)

  // For workItem transfers only use jids where the job is the same as on the workItem
  const jobId = fc.values.jobId
  const workItemId = fc.values.workItemId

  const [scanned, setScanned] = useState<ScannedJobInventory[]>([])
  const [jobInventoryDetails, setJobInventoryDetails] = useState<any[]>([])

  const [getJobInventoryDetails, jobInventoryDetailsQuery] = useJobInventoryDetailsForCarriersLazyQuery({
    fetchPolicy: 'no-cache',
  })

  const taskQuery = useWorkTaskByIdWithTaskResultsQuery({ variables: { taskId: taskId } })
  const task = taskQuery.data?.workTask
  const isJobInventoryPutAway = task?.jobInventoryPutAway ? true : false

  const [getPreviousScanned, previousScannedQuery] = useOutboundByWorkItemIdLazyQuery({
    fetchPolicy: `network-only`,
  })

  useEffect(() => {
    if (workItemId) {
      getPreviousScanned({
        variables: {
          workItemId: workItemId,
        },
      })
    }
  }, [])

  // carrierCodes that have already been posted in outbound of this workItem
  const previousScanned = workItemId ? previousScannedQuery.data?.outboundJobInventoryByWorkItemId ?? [] : []

  useEffect(() => {
    // Prefill form if WorkItemTask is executed.
    const job = (task?.workItem.jobs ?? [])[0]
    const position = isJobInventoryPutAway ? (task?.jobInventoryPutAway ?? [])[0]?.planned.position : null

    if (task && job && position) {
      fc.setValues(current => ({
        ...current,
        jobId: job!.id!,
        position: {
          id: position!.id!,
          code: position!.code!,
          name: position!.name!,
        },
        taskOptions: task?.taskOptions,
      }))
    }
  }, [taskQuery])

  useEffect(() => {
    // Query job inventory if carrierCode is scanned
    if (carrierCode && carrierCode.code && carrierCode.code.length > 0) {
      if (!companyId) {
        fail('expects company id')
      }

      if (workItemId) {
        const isScannedPreviously = previousScanned?.some(entry => entry?.carrierCode === carrierCode.code)
        if (isScannedPreviously) {
          MAudio.scanError()
          alerts.push(i18n.t('CARRIER_ALREADY_SCANNED', { carrier: carrierCode.code }), 'error')
          return
        }
      }

      // If workItem transfer filter on jobId
      getJobInventoryDetails({
        variables: {
          filter: {
            companyId: companyId,
            jobId: jobId ?? null,
            carrierCode: carrierCode.code,
            excludedWorkItemId: workItemId ?? null,
          },
        },
      })
    }
  }, [carrierCode])

  useEffect(() => {
    if (jobInventoryDetailsQuery.data) {
      const jids = jobInventoryDetailsQuery.data?.MSjobInventoryDetails?.filter(defined) ?? []

      if (jids.length > 0) {
        setJobInventoryDetails(jids)
      } else if (jids.length === 0 && carrierCode?.code) {
        MAudio.scanError()
        alerts.push(
          `${i18n.t('NO_INVENTORY_FOUND_FOR_CARRIER', { carrier: carrierCode.code })} ${
            jobId ? i18n.t('AND_THIS_JOB') : ''
          }`,
          'error',
        )
        fc.setValues({ ...fc.values, carrierCode: { id: '', name: '', code: '' } })
      }
    }
  }, [jobInventoryDetailsQuery])

  useEffect(() => {
    // multiple job inventory can be present on 1 carrier. Add all to cache.
    if (jobInventoryDetails.length > 0 && carrierCode?.code) {
      const scannedJobInvInputs = jobInventoryDetails.map(jid => ({
        jidId: jid.id,
        carrierCode: jid.carrierCode!,
        jobId: jid.job.id,
        jobName: jid.job.code,
        positionId: jid.position.id,
        itemName: jid.job.item.name,
        quantity: jid.remainingQuantity,
        unitOfMeasure: jid.unitOfMeasure,
        timestamp: Date.now(),
      }))
      addToScannnedState(scannedJobInvInputs)
      setJobInventoryDetails([])
    }
  }, [jobInventoryDetails])

  const addToScannnedState = async (input: ScannedJobInventory[]) => {
    const cachedJids = scanned.map(entry => entry.jidId)
    const newlyScannedJids = input.filter(i => !cachedJids.includes(i.jidId))

    if (newlyScannedJids.length === input.length) {
      MAudio.scanSuccess()
      alerts.push(i18n.t('CARRIER_SCANNED', { carrier: input[0]?.carrierCode }), 'success')
    } else {
      MAudio.scanError()
      alerts.push(i18n.t('CARRIER_ALREADY_SCANNED', { carrier: input[0]?.carrierCode }), 'error')
    }

    if (newlyScannedJids.length > 0) {
      fc.setValues({
        ...fc.values,
        carrierCode: { id: '', name: '', code: '' },
        scannedJobInventories: [...(fc.values.scannedJobInventories ?? []), ...newlyScannedJids],
      })
    }
  }

  /** Update scanned state when form updates */
  useEffect(() => {
    setScanned(fc.values.scannedJobInventories?.sort((a, b) => b.timestamp - a.timestamp) ?? [])
  }, [fc.values.scannedJobInventories])

  /** Set focus to the field when the form opens */
  const carrierFieldRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    carrierFieldRef?.current?.focus()
  }, [carrierFieldRef])

  useEffect(() => {
    if (fc.values.userSelectedStayOnCarriers) {
      fc.setFieldValue('userSelectedOffloadCarriers', false)
    }
  }, [fc.values.userSelectedStayOnCarriers])

  useEffect(() => {
    if (fc.values.userSelectedOffloadCarriers) {
      fc.setFieldValue('userSelectedStayOnCarriers', false)
    }
  }, [fc.values.userSelectedOffloadCarriers])

  return (
    <>
      {task && <TaskDetailsSection task={task} />}
      <Section>
        <div style={{ padding: '0.5rem' }}>
          <Formik.Field component={MFieldConnector} name={nameof<TransferCarrierCodesEntryForm>('carrierCode')}>
            <FieldCarrierCode title={t('ASSIGN_CARRIER_CODE')} ref={carrierFieldRef} />
          </Formik.Field>

          <Formik.Field component={MFieldConnector} name={nameof<TransferCarrierCodesEntryForm>('position')}>
            <FieldPosition title={t('ASSIGN_POSITION')} label={`TO_POSITION`} neverDisable />
          </Formik.Field>
        </div>
        {!fc.values.isWorkItemTransfer && (
          <MFlex style={{ justifyContent: 'space-evenly' }}>
            <Field
              style={{ border: '1px solid', alignSelf: 'center' }}
              component={RadioButtonField}
              name={nameof<TransferCarrierCodesEntryForm>(`userSelectedStayOnCarriers`)}
              label={t('STAY_ON_CARRIER')}
            />
            <Field
              style={{ border: '1px solid' }}
              component={RadioButtonField}
              name={nameof<TransferCarrierCodesEntryForm>(`userSelectedOffloadCarriers`)}
              label={t('OFFLOAD_CARRIER')}
            />
          </MFlex>
        )}
      </Section>
      {scanned.length > 0 && <ScannedCarriersSection scanned={scanned} />}
    </>
  )
}

TransferCarrierCodesForm.useSchema = () => {
  const { t } = useTranslation()

  const [schema] = useState(
    (): Yup.SchemaOf<TransferCarrierCodesEntryForm> =>
      Yup.object().shape({
        carrierCode: Yup.mixed()
          .optional()
          .nullable()
          .label(t(`JOB`))
          .test('carrierCode', t('NO_CARRIERS_SCANNED'), async (input: any, context: any) => {
            return !!context.parent.scannedJobInventories?.length
          }),
        position: Yup.object()
          .shape({
            id: Yup.string().required(t('POSITION_REQUIRED')),
            name: Yup.string().nullable(),
            code: Yup.string().nullable(),
          })
          .required()
          .nullable()
          .label(t(`POSITION`)),
        scannedJobInventories: Yup.mixed().optional().nullable(),
        jobId: Yup.mixed().optional().nullable(),
        isWorkItemTransfer: Yup.boolean().required(),
        workItemId: Yup.mixed().optional().nullable(),
        taskOptions: Yup.mixed().optional(),
        userSelectedStayOnCarriers: Yup.mixed()
          .optional()
          .test('userSelectedStayOnCarriers', t('SELECT_OFFLOAD_MESSAGE'), (input: any, context: any) => {
            if (!input && !context.parent.userSelectedOffloadCarriers && !context.parent.isWorkItemTransfer) {
              return false
            } else return true
          }),
        userSelectedOffloadCarriers: Yup.mixed().optional(),
      }),
  )
  return schema
}
