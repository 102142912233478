import { createMpriseAuthRoleBasedComponents } from '@mprise/react-auth'

// https://dev.azure.com/mprise-indigo/AgriwareDevelopment/_wiki/wikis/AgriwareDevelopment.wiki/121/Authorization

export const { RoleVisible, RoleMissing, useRoleAvailable } = createMpriseAuthRoleBasedComponents([
  `GREENHOUSE_MY_TASKS`,
  `GREENHOUSE_TRANSFER`,
  `GREENHOUSE_TRANSFER_CARRIERS`,
  `GREENHOUSE_SCRAP`,
  `GREENHOUSE_PHASE`,
  `GREENHOUSE_OUTPUT`,
  `GREENHOUSE_JOB`,
  `GREENHOUSE_CHANGE_PHASE_DATES`,
])
