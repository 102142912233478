import { useEffect, useRef, useState } from 'react'
import { Formik, MAudio, MColor, MFieldConnector, MFlexItem, Yup } from '@mprise/react-ui'
import { useTranslation } from 'react-i18next'
import { JobPickEntryForm } from './Home'
import { Section } from '../../../components/Section'
import { defined, nameof } from '../../../shared/typescript'
import { FieldJob } from '../../../shared/form/FieldJob'
import { FieldJobInventoryDetail } from '../../../shared/form/FieldJobInventoryDetail'
import { FieldPosition } from '../../../shared/form/FieldPosition'
import { useParams } from 'react-router-dom'
import { FieldQuantity } from '../../../shared/form/FieldQuantity'
import { ValidationFriendlyWarning } from '../../../shared/form/ValidationFriendlyWarning'
import { SelectedJobInventoryDetails } from '../../../shared/form/selectionDetails'
import { TaskDetailsSection } from '../../../shared/form/TaskDetailsSection'
import {
  useJobInventoryDetailsForCarriersLazyQuery,
  useWorkTaskByIdWithTaskResultsQuery,
} from '../../../gateway/react.generated'
import { FieldCarrierCode } from '../../../shared/form/FieldCarrierCode'
import { ScannedCarriersSection } from '../../../shared/section/ScannedCarriersSection'
import { FlashAlerts } from '../../../shared/flash-alerts'
import { ScannedJobInventory } from '../../../shared/interfaces'
import { CurrentCompanyContainer } from '../../../context/current-company'
import { useHistory } from '../../../shared/use-history'
import Icon from '@mdi/react'
import { mdiChevronRight, mdiCircleSmall } from '@mdi/js'
import { Collapse, IconButton } from '@material-ui/core'
import { useBeforeUnload } from '../../../shared/before-unload'
import { i18n } from '../../../i18n/instance'
import { JobInventoryDetailValue } from '../../../shared/dialog/SelectJobInventoryDetailDialog'
import { MultiSelectJobInventoryDialog } from '../../../shared/dialog/MultiSelectJobInventoryDialog'

export const JobPickForm = () => {
  const { t } = useTranslation()
  const alerts = FlashAlerts.useAlert()
  const h = useHistory()

  const fc = Formik.useFormikContext<JobPickEntryForm>()
  const taskId = useParams().taskId!

  useBeforeUnload(fc.dirty && !fc.isSubmitting)

  const [scanned, setScanned] = useState<ScannedJobInventory[]>([])
  const [jobInventoryDetails, setJobInventoryDetails] = useState<any[]>([])

  const [openJobToPosition, setOpenJobToPosition] = useState(false)
  const [openCarrierToPosition, setOpenCarrierToPosition] = useState(false)

  const [showMultiSelect, setShowMultiSelect] = useState(false)
  const handleOpenJobToPosition = (e: any) => {
    setOpenJobToPosition(!openJobToPosition)
  }
  const handleOpenCarrierToPosition = (e: any) => {
    setOpenCarrierToPosition(!openCarrierToPosition)
  }

  const [getJobInventoryDetails, jobInventoryDetailsQuery] = useJobInventoryDetailsForCarriersLazyQuery({
    fetchPolicy: 'no-cache',
  })

  const maxQuantity = fc.values.originalQuantity ?? 0
  const exceedsMaxQuantity = fc.values.quantity ? fc.values.quantity > maxQuantity : false
  const hasSelectedJid = fc.values.jobInventoryDetail ? true : false
  const hasSelectedQuantity = fc.values.quantity ? true : false

  const taskQuery = useWorkTaskByIdWithTaskResultsQuery({ variables: { taskId: taskId } })
  const task = taskQuery.data?.workTask
  const carrierCode = fc.values.carrierCode
  const { current: currentCompany } = CurrentCompanyContainer.useCurrent()
  const companyId = currentCompany?.id ?? h.push('/')

  useEffect(() => {
    // Query job inventory if carrierCode is scanned
    if (carrierCode && carrierCode.code && carrierCode.code.length > 0) {
      if (!companyId) {
        fail('expects company id')
      }

      getJobInventoryDetails({
        variables: {
          filter: {
            companyId: companyId,
            carrierCode: carrierCode.code,
          },
        },
      })
    }
  }, [carrierCode])

  useEffect(() => {
    if (jobInventoryDetailsQuery.data) {
      const jids = jobInventoryDetailsQuery.data?.MSjobInventoryDetails?.filter(defined) ?? []

      if (jids.length === 1) {
        const hasIncorrectItem = jids[0]!.job.item?.id !== task?.workItem.item?.id
        if (hasIncorrectItem) {
          setShowMultiSelect(true)
        } else {
          setJobInventoryDetails(jids)
        }
      } else if (jids.length > 0) {
        setShowMultiSelect(true)
      } else if (jids.length === 0 && carrierCode?.code) {
        MAudio.scanError()
        alerts.push(i18n.t('NO_INVENTORY_FOUND_FOR_CARRIER', { carrier: carrierCode.code }), 'error')
        fc.setValues({ ...fc.values, carrierCode: { id: '', name: '', code: '' } })
      }
    }
  }, [jobInventoryDetailsQuery])

  useEffect(() => {
    // multiple job inventory can be present on 1 carrier. Add all to state if correct item.
    if (jobInventoryDetails.length > 0 && carrierCode?.code) {
      const scannedJobInvInputs = jobInventoryDetails.map(jid => ({
        jidId: jid.id,
        carrierCode: jid.carrierCode!,
        jobId: jid.job.id,
        jobName: jid.job.code,
        positionId: jid.position.id,
        itemName: jid.job.item.name,
        quantity: jid.remainingQuantity,
        unitOfMeasure: jid.unitOfMeasure,
        timestamp: Date.now(),
      }))
      addToScannnedState(scannedJobInvInputs)
      setJobInventoryDetails([])
    }
  }, [jobInventoryDetails])

  const addToScannnedState = async (input: ScannedJobInventory[]) => {
    const cachedJids = scanned.map(entry => entry.jidId)
    const newlyScannedJids = input.filter(i => !cachedJids.includes(i.jidId))

    MAudio.scanSuccess()
    if (newlyScannedJids.length === input.length) {
      alerts.push(i18n.t('CARRIER_SCANNED', { carrier: input[0]?.carrierCode }), 'success')
    } else {
      alerts.push(i18n.t('CARRIER_ALREADY_SCANNED', { carrier: input[0]?.carrierCode }), 'success')
    }

    if (newlyScannedJids.length > 0) {
      fc.setValues({
        ...fc.values,
        carrierCode: { id: '', name: '', code: '' },
        scannedJobInventories: [...(fc.values.scannedJobInventories ?? []), ...newlyScannedJids],
      })
    }
  }

  /** Update scanned state when form updates */
  useEffect(() => {
    setScanned(fc.values.scannedJobInventories?.sort((a, b) => b.timestamp - a.timestamp) ?? [])
  }, [fc.values.scannedJobInventories])

  const jobFieldRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    jobFieldRef?.current?.focus()
  }, [openJobToPosition])

  const carrierFieldRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    carrierFieldRef?.current?.focus()
  }, [openCarrierToPosition])

  const [selected, setSelected] = useState<Array<JobInventoryDetailValue>>([])

  return (
    <>
      {task && <TaskDetailsSection task={task} />}
      <Section>
        <div className='gh-taskItem-card-header' onClick={handleOpenJobToPosition}>
          <MFlexItem shrink={0}>
            <IconButton style={{ paddingLeft: '0.5rem' }}>
              <Icon
                path={mdiChevronRight}
                size={1}
                rotate={openJobToPosition ? 90 : 0}
                style={{ transition: 'transform 0.3s' }}
              />
            </IconButton>
          </MFlexItem>
          <div style={{ flexGrow: 1 }}>{t('PICK_FROM_JOB')}</div>
          {hasSelectedJid && hasSelectedQuantity && <Icon path={mdiCircleSmall} size={2} color={MColor.medium} />}
        </div>
        <Collapse in={openJobToPosition}>
          <div style={{ padding: '0 0.5rem 0.5rem 0.5rem' }}>
            <Formik.Field component={MFieldConnector} name={nameof<JobPickEntryForm>('job')} color={MColor.status_todo}>
              <FieldJob title={t('ASSIGN_JOB')} ref={jobFieldRef} itemConstraint={task?.workItem.item} />
            </Formik.Field>
            <div style={{ display: 'none' }}>
              <Formik.Field component={MFieldConnector} name={nameof<JobPickEntryForm>('jobInventoryDetail')}>
                <FieldJobInventoryDetail
                  title={t('ASSIGN_JOB_INVENTORY_DETAIL')}
                  itemConstraint={task?.workItem.item}
                />
              </Formik.Field>
            </div>
            <Formik.Field component={MFieldConnector} name={nameof<JobPickEntryForm>('position')}>
              <FieldPosition title={t('ASSIGN_POSITION')} label={'TO_POSITION'} neverDisable />
            </Formik.Field>
            <Formik.Field component={MFieldConnector} name={nameof<JobPickEntryForm>('quantity')}>
              <FieldQuantity disabled={false} />
              <ValidationFriendlyWarning
                open={exceedsMaxQuantity && hasSelectedJid}
                text={t('WARNING_EXCEEDS_QUANTITY')}
              />
            </Formik.Field>
            <div style={{ marginTop: '1rem' }}>
              <Section>
                <SelectedJobInventoryDetails jobInventoryDetail={fc.values.jobInventoryDetail} showPosition={true} />
              </Section>
            </div>
          </div>
        </Collapse>
      </Section>
      <Section>
        <div className='gh-taskItem-card-header' onClick={handleOpenCarrierToPosition}>
          <MFlexItem shrink={0}>
            <IconButton style={{ paddingLeft: '0.5rem' }}>
              <Icon
                path={mdiChevronRight}
                size={1}
                rotate={openCarrierToPosition ? 90 : 0}
                style={{ transition: 'transform 0.3s' }}
              />
            </IconButton>
          </MFlexItem>
          <div style={{ flexGrow: 1 }}>{t('PICK_FROM_CARRIER')}</div>
          {scanned.length > 0 && <Icon path={mdiCircleSmall} size={2} color={MColor.medium} />}
        </div>
        <Collapse in={openCarrierToPosition}>
          <div style={{ padding: '0.5rem' }}>
            <Formik.Field component={MFieldConnector} name={nameof<JobPickEntryForm>('carrierCode')}>
              <FieldCarrierCode title={t('ASSIGN_CARRIER_CODE')} ref={carrierFieldRef} />
            </Formik.Field>
            <Formik.Field component={MFieldConnector} name={nameof<JobPickEntryForm>('position')}>
              <FieldPosition title={t('ASSIGN_POSITION')} label={'TO_POSITION'} neverDisable />
            </Formik.Field>
          </div>
          {scanned.length > 0 && <ScannedCarriersSection scanned={scanned} />}
        </Collapse>
      </Section>
      {showMultiSelect && task && (
        <MultiSelectJobInventoryDialog
          open={showMultiSelect}
          onClose={() => {
            setSelected([])
            setShowMultiSelect(false)
          }}
          onSave={selected => {
            setJobInventoryDetails(selected)
          }}
          jobInventoryDetails={jobInventoryDetailsQuery.data?.MSjobInventoryDetails?.filter(defined) ?? []}
          selectedJids={selected}
          task={task}
          onChange={setSelected}
        />
      )}
    </>
  )
}

JobPickForm.useSchema = () => {
  const { t } = useTranslation()

  const [schema] = useState(
    (): Yup.SchemaOf<JobPickEntryForm> =>
      Yup.object().shape({
        job: Yup.object()
          .shape({
            id: Yup.string().nullable(),
            name: Yup.string().nullable(),
            code: Yup.string().nullable(),
          })
          .nullable()
          .label(t(`JOB`)),
        jobInventoryDetail: Yup.mixed().nullable().label(t(`JOB_INVENTORY_DETAIL`)),
        position: Yup.object()
          .shape({
            id: Yup.string().required('Position is a required field'),
            name: Yup.string().nullable(),
            code: Yup.string().nullable(),
          })
          .test(`position`, t('NOTIFICATION_POSITION_INVALID'), (input, context) => {
            if (context.parent.jobInventoryDetail && context.parent.jobInventoryDetail.position) {
              return input.code !== context.parent.jobInventoryDetail.position.code
            } else return true
          })
          .required()
          .nullable()
          .label(t(`POSITION`)),
        originalQuantity: Yup.number().nullable().defined().label('originalQuantity'),
        quantity: Yup.number().nullable().defined(),
        carrierCode: Yup.mixed()
          .optional()
          .nullable()
          .label(t(`JOB`))
          .test(
            'carrierCode',
            'Select either a carrier or an inventory / quantity combination',
            async (input: any, context: any) => {
              if (!context.parent.scannedJobInventories?.length) {
                const hasQuantitySelected = context.parent.quantity && context.parent.quantity > 0
                const hasJobInventorySelected = context.parent.jobInventoryDetail
                return hasQuantitySelected && hasJobInventorySelected
              } else return true
            },
          ),
        scannedJobInventories: Yup.mixed().optional().nullable(),
      }),
  )

  return schema
}
