import React, { ReactNode, useContext, useEffect, useMemo, useState } from 'react'

export type AlertType = 'error' | 'info' | 'success'

export type AlertEntry = { message: string; type: AlertType }

export interface FlashAlertApi {
  push(message: string, type: AlertType): void
}

/**
 * Shows messages at the the top of the screen that automatically disappear.
 * Clicking on a message closes it.
 *
 */
export const FlashAlerts = ({ children }: { children: ReactNode }) => {
  const { context, current, close } = FlashAlerts.useInternalState()
  return (
    <FlashAlertContext.Provider value={context}>
      {current ? <FlashAlerts.Alert value={current} onClick={close} /> : null}
      {children}
    </FlashAlertContext.Provider>
  )
}

const FlashAlertContext = React.createContext<FlashAlertApi>({
  push() {
    throw Error(`missing <FlashAlerts />`)
  },
})

FlashAlerts.useAlert = () => {
  return useContext(FlashAlertContext)
}

FlashAlerts.Alert = ({ value, onClick }: { value: AlertEntry; onClick: () => void }) => {
  const classes = value.type === `error` ? 'ml-flash-alert-warning' : 'ml-flash-alert-success'

  return (
    <div className='ml-flash-alert-container'>
      <div onClick={onClick} className={classes}>
        {value.message}
      </div>
    </div>
  )
}

FlashAlerts.useInternalState = () => {
  const [stack, setStack] = useState<Array<AlertEntry>>([])

  // Context value, able to push alerts onto the stack
  const context = useMemo(
    () => ({
      push(message: string, type: AlertType) {
        setStack(prev => prev.concat({ message, type }))
      },
    }),
    [],
  )

  // Close first entry
  const close = () => {
    setStack(prev => prev.slice(1))
  }

  // Automatically close messages
  const current = stack[0]
  useEffect(() => {
    if (current) {
      const timer = setTimeout(() => close(), stack.length > 1 ? 200 : 2000)
      return () => clearTimeout(timer)
    }
  }, [stack])

  return { current, context, close }
}
