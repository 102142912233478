import { Section } from '../../components/Section'
import { TaskDetails, TaskValue } from '../../routes/tasks/TaskItem'

export const TaskDetailsSection = ({ task }: { task: TaskValue }) => {
  return (
    <Section>
      <div className='gh-form-task-card'>
        <div className='gh-form-task-header'>
          {task?.order ?? `?`}. {task?.name ?? ``}
        </div>
        <TaskDetails task={task} />
      </div>
    </Section>
  )
}
