import { useApolloClient } from '@mprise/gateway-client'
import { MFlexBlock, MSelectItem, ToMSelectItem } from '@mprise/react-ui'
import React from 'react'
import { MaterialIcon } from '../../components/Icon'
import { CurrentCompanyContainer } from '../../context/current-company'
import { ScanningContainer } from '../../context/scanning'
import {
  Reason,
  ReasonsByFilterDocument,
  ReasonsByFilterQuery,
  ReasonsByFilterQueryVariables,
} from '../../gateway/react.generated'
import { PaginatedSearchAndSelect } from '../../mprise-light/PaginatedSearchAndSelect'
import { QueryErrorMessage } from '../apollo'
import { useDebounceValue } from '../debounce-value'
import { useLocalState } from '../local-state'
import { defined, NotOptional, fail } from '../typescript'
import { useAsync } from '../use-async'
import { DialogWithBackButton } from '../../components/Dialog'

export type ReasonValue = NotOptional<NotOptional<any['masterdata']['company']>['reasons']['page'][0]>

const toSelectItem = ToMSelectItem<ReasonValue>({
  id: x => x.id,
  icon: () => <MaterialIcon value='delete_forever' />,
  primary: x => x.name ?? ``,
  secondary: x => x.code ?? null,
})

export const SelectReasonDialog = ({
  title,
  open,
  onClose,
  onSave,
}: {
  title: React.ReactNode
  open: boolean
  onClose: () => void
  onSave: (values: any) => void
}) => {
  const scanningStatus = ScanningContainer.useDefault()
  const [search, setSearch] = useLocalState(``, [open])
  const debouncedSearch = useDebounceValue(search, 500)
  const [cursor, setCursor] = useLocalState<string | null>(null, [search])

  const reasonsQuery = SelectReasonDialog.useQuery({
    cursor,
    query: debouncedSearch,
  })
  const nextCursor = reasonsQuery?.data?.nextCursor ?? null
  const reasons = reasonsQuery.data?.page.filter(defined).map(toSelectItem) ?? null

  const handleSelect = async (item: MSelectItem<ReasonValue> | null) => {
    onSave(item?.data ?? null)
  }
  const handleNextPage = (cursor: string) => {
    setCursor(cursor)
  }

  const handleSubmit: React.FormEventHandler = async e => {
    e.stopPropagation()
    e.preventDefault()
    onClose()
  }

  return (
    <MFlexBlock alignItems='center' style={{ position: 'relative' }}>
      <DialogWithBackButton open={open} title={title} onClose={onClose} onSubmit={handleSubmit}>
        <QueryErrorMessage query={reasonsQuery} />
        <PaginatedSearchAndSelect
          scanningSetting={scanningStatus.scanning}
          items={reasons}
          nextCursor={nextCursor}
          search={search}
          value={null}
          onChange={handleSelect}
          onNextPage={handleNextPage}
          onSearch={setSearch}
          showSwitchKeyboardOption={true}
        />
      </DialogWithBackButton>
    </MFlexBlock>
  )
}

SelectReasonDialog.useQuery = ({ query, cursor }: { query: string; cursor: string | null }) => {
  const { current: currentCompany } = CurrentCompanyContainer.useCurrent()
  const companyId = currentCompany?.id
  const apollo = useApolloClient()

  const a = useAsync<{
    page: Array<Pick<Reason, `id` | `name` | `code`>>
    nextCursor: string | null
  }>(async () => {
    if (!companyId) {
      fail('expects company id')
    }

    const response = await apollo.query<ReasonsByFilterQuery, ReasonsByFilterQueryVariables>({
      query: ReasonsByFilterDocument,
      variables: {
        companyId,
        whereReason: {
          removed_eq: false,
          type_eq: 'SCRAP',
          or: [{ name_containslower: query }, { code_containslower: query }],
        },
      },
    })
    const page = response.data.masterdata.company?.reasons.page.filter(defined) ?? []
    const nextCursor = response.data.masterdata.company?.reasons.nextCursor ?? null
    return { page, nextCursor }
  }, [query, cursor])

  return a
}
