import { MouseEventHandler } from 'react'
import { MColor } from '@mprise/react-ui'
import { Button } from '../../components/Button'
import { MaterialIcon } from '../../components/Icon'

export const DialogBackButton = ({ onClick }: { onClick: MouseEventHandler<Element> }) => {
  return <Button color={MColor.primary} icon={<MaterialIcon value='arrow_back' />} onClick={onClick} />
}

export const DialogSaveButton = ({ onClick }: { onClick: MouseEventHandler<Element> }) => {
  return <Button color={MColor.primary} icon={<MaterialIcon value='check' />} onClick={onClick} />
}
