import React from 'react'
import { Formik, MAudio, withFormikCompareFix } from '@mprise/react-ui'
import { useTranslation } from 'react-i18next'
import { useHistory } from '../../../shared/use-history'
import { useLocalState } from '../../../shared/local-state'
import {
  Item,
  Maybe,
  useReportTransferToPositionMutation,
  useStopTaskMutation,
} from '../../../gateway/react.generated'
import { TransferForm } from './TransferForm'
import { JobInventoryDetailValue } from '../../../shared/dialog/SelectJobInventoryDetailDialog'
import { FlashAlerts } from '../../../shared/flash-alerts'
import { SavingSwitchPanel } from '../../../shared/saving-switch-panel'
import { MutationErrorMessage } from '../../../shared/apollo'
import { ValidationIssues } from '../../../mprise-light/ValidationIssues'
import { CurrentResourceContainer } from '../../../context/current-resource'
import { DialogFormik } from '../../../mprise-light/DialogFormik'
import { useParams } from 'react-router'
import { Helmet } from 'react-helmet'

export interface TransferEntryForm {
  job: Maybe<{ id: string; name: string; code: Maybe<string>; item?: Maybe<Pick<Item, 'id' | 'name'>> }>
  fromPosition: Maybe<{ id: string; name: string; code: Maybe<string> }>
  toPosition: Maybe<{ id: string; name: string; code: Maybe<string> }>
  jobInventoryDetail: Maybe<JobInventoryDetailValue>
  originalQuantity: Maybe<number>
  quantity: Maybe<number>
  isWorkItemTransfer: boolean
  workItemId: Maybe<string>
}

export const TransferRoute = () => {
  const h = useHistory()
  const { t } = useTranslation()
  const schema = TransferForm.useSchema()
  const alerts = FlashAlerts.useAlert()
  const resourceId = CurrentResourceContainer.useCurrent().current?.id ?? h.push('/')

  // If Transfer is used by a redirect of a WorkOrder: Prefill form
  const workItemId = useParams().workItemId ?? null
  const taskId = useParams().taskId
  // If phaseCode is defined: do an automatic phase transition at transfer
  const phaseCodeText = useParams().phaseCode
  const phaseCode = phaseCodeText === 'null' ? null : phaseCodeText

  const [stopTaskMutation, stopTaskState] = useStopTaskMutation()

  const handleClose = async (e: React.FormEvent<Element>) => {
    e.preventDefault()
    e.stopPropagation()

    if (workItemId && taskId) {
      await stopTaskMutation({ variables: { workItemId: workItemId, taskId: taskId } })
    }
    h.goBack()
  }

  const [reportTransferToPosition, reportTransferToPositionState] = useReportTransferToPositionMutation()

  const [initialValues] = useLocalState((): TransferEntryForm => {
    return withFormikCompareFix({
      job: { id: '', name: '', code: '' },
      fromPosition: { id: '', name: '', code: '' },
      toPosition: { id: '', name: '', code: '' },
      jobInventoryDetail: null,
      originalQuantity: null,
      quantity: null,
      isWorkItemTransfer: workItemId ? true : false,
      workItemId: workItemId ?? null,
    })
  }, [])

  const handleSubmit = async (form: TransferEntryForm, actions: Formik.FormikHelpers<TransferEntryForm>) => {
    actions.resetForm()
    
    if (!resourceId) {
      fail('expects resource id')
    }

    const originJobInventoryDetailId = form.jobInventoryDetail!.id
    const destinationPositionId = form.toPosition!.id
    const quantity = form.quantity!

    const result = await reportTransferToPosition({
      variables: {
        originJobInventoryDetailId: originJobInventoryDetailId,
        destinationPositionId: destinationPositionId,
        quantity: quantity,
        resourceId: resourceId!,
        destinationPhase: phaseCode,
        workItemId: workItemId,
      },
    })

    if (result.data) {
      MAudio.scanSuccess()
      alerts.push(t('SUCCESS_MESSAGE'), `success`)
    }

    if (workItemId && taskId) {
      await stopTaskMutation({ variables: { workItemId: workItemId, taskId: taskId } })
    }
    h.goBack()
  }

  return (
    <>
      <Helmet title={t('TITLE_JOB_TO_POSITION')} />

      <Formik.Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        <DialogFormik minWidth='xl' title={t('TRANSFER')} onClose={handleClose} open={true}>
          <SavingSwitchPanel mutation={[reportTransferToPositionState, stopTaskState]}>
            <ValidationIssues />
            <MutationErrorMessage
              mutation={[reportTransferToPositionState, stopTaskState]}
            />
            <TransferForm />
          </SavingSwitchPanel>
        </DialogFormik>
      </Formik.Formik>
    </>
  )
}
