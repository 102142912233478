import { MFieldInteger } from '@mprise/react-ui'
import { FieldTitle } from '../../components/FieldTitle'
import { useTranslation } from 'react-i18next'
import { suppressEnter } from '../util/util'

export const FieldQuantity = ({ disabled, title }: { disabled?: boolean; title?: string }) => {
  const { t } = useTranslation()

  return (
    <>
      <FieldTitle title={title ?? t(`QUANTITY`)} />
      <div className='gh-field-quantity'>
        <div className='gh-field-quantity-input'>
          <MFieldInteger onKeyDown={suppressEnter} min={0} step={1} disabled={disabled} />
        </div>
      </div>
    </>
  )
}
