import { useTranslation } from 'react-i18next'
import { MDialog, MDialogContentText } from '../../mprise-light/Dialog'
import { MutationErrorMessage } from '../../shared/apollo'
import { MutationResult } from '@mprise/gateway-client'
import { FinishTaskMutation, useOutboundByWorkItemIdLazyQuery } from '../../gateway/react.generated'
import { MColor, MText } from '@mprise/react-ui'
import { TaskValue } from './TaskItem'
import { formatUnitOfMeasure } from '../../shared/formats'
import IconWarning from '@material-ui/icons/Warning'
import { useEffect } from 'react'

export const ConfirmFinishDialog = ({
  task,
  open,
  onConfirmFinish,
  onCancelFinish,
  finishTaskState,
}: {
  task: TaskValue
  open: boolean
  onConfirmFinish: () => void
  onCancelFinish: () => void
  finishTaskState: MutationResult<FinishTaskMutation>
}) => {
  const { t } = useTranslation()

  const workItemId = task.workItem.id
  const plannedQuantity = task?.workItem.plannedQuantity ?? 0

  const [getReportedQuantity, reportedQuantityQuery] = useOutboundByWorkItemIdLazyQuery({
    fetchPolicy: `network-only`,
  })

  useEffect(() => {
    if (task.jobInventoryPutAway && !task.itemConsumption && workItemId) {
      getReportedQuantity({
        variables: {
          workItemId: workItemId
        }
      })
    }
  }, [])

  let reportedQuantity = 0
  if (task.jobInventoryPutAway && !task.itemConsumption) {
    // get from outbound
    reportedQuantity = (reportedQuantityQuery.data?.outboundJobInventoryByWorkItemId ?? []).reduce((a, b) => a + (b?.quantity ?? 0), 0)
  } else if (task.itemOutput) {
    // get from cosmos
    reportedQuantity = task.itemOutput![0]?.reported.reduce((a, b) => a + (b.quantity ?? 0), 0) ?? 0
  } else if (task.itemConsumption && task.jobInventoryPutAway) {
    //get from cosmos
    reportedQuantity = task.jobInventoryPutAway![0]?.reported.reduce((a, b) => a + (b.quantity ?? 0), 0) ?? 0
  }

  const unitOfMeasure = formatUnitOfMeasure(task?.workItem.unitOfMeasure)

  const isIncomplete = reportedQuantity < plannedQuantity

  const incompleteText = t('QUANTITY_INCOMPLETE_PLANNED_REPORTED_UNIT', {
    QTY_PLANNED: plannedQuantity.toLocaleString('nl-NL'),
    QTY_REPORTED: reportedQuantity.toLocaleString('nl-NL'),
    UNIT: unitOfMeasure,
  })

  return (
    <MDialog open={open} title={t(`CONFIRM`)} onSubmit={onConfirmFinish} onClose={onCancelFinish}>
      <MutationErrorMessage mutation={finishTaskState} />
      <MDialogContentText>
        <div style={{ padding: '1rem' }}>
          {isIncomplete && (
            <div style={{ display: 'flex', flexDirection: 'row', paddingBottom: '1rem' }}>
              <IconWarning style={{ color: MColor.medium }} />
              <span style={{ paddingLeft: '0.5rem' }}>{incompleteText}</span>
            </div>
          )}
          <MText block>{t('CONFIRM_FINISH_ARE_YOU_SURE')}</MText>
        </div>
      </MDialogContentText>
    </MDialog>
  )
}
