import { FormikContextType, useFormikContext } from 'formik'
import React, { useCallback } from 'react'
import { MDialog, MDialogProps } from './Dialog'

/* Copied from MDialogFormik mprise/react-ui to adjust css */

export const DialogFormik = (
  props: Omit<MDialogProps, 'onSubmit' | 'onReset'> & {
    onCloseWithContext?: (e: React.FormEvent<Element>, fc: FormikContextType<any>) => void
  },
) => {
  const formik = useFormikContext()
  const handleSubmit = useCallback<React.FormEventHandler>(
    e => {
      e.preventDefault()
      e.stopPropagation()
      formik.submitForm()
    },
    [formik.submitForm],
  )
  const handleReset = useCallback<React.FormEventHandler>(
    e => {
      e.preventDefault()
      e.stopPropagation()
      formik.resetForm()
    },
    [formik.resetForm],
  )
  const onClose = props.onCloseWithContext
    ? (e: React.FormEvent<Element>) => props.onCloseWithContext?.(e, formik)
    : props.onClose
  return <MDialog {...props} onSubmit={handleSubmit} onReset={handleReset} onClose={onClose} />
}
