export type Optional<T> = T | null | undefined
export type NotOptional<T> = Exclude<T, null | undefined>

export const defined = <T extends unknown>(value: T | null | undefined): value is T =>
  value !== null && value !== undefined

export const nameof = <T,>(name: Extract<keyof T, string>): string => name

export type ObjectWithDefaults<Values, Defaults extends Values> = {
  [key in keyof Values]-?: Exclude<Values[key], null | undefined> | Defaults[key]
}

export function withFallbackValues<Values extends {}, Defaults extends Values>(
  input: Values,
  defaults: Defaults,
): ObjectWithDefaults<Values, Defaults>
export function withFallbackValues(input: {}, defaults: Record<PropertyKey, unknown>): {} {
  if (input) {
    const entries = Object.entries(input)
    const patched = entries.map(([key, value]) => [key, value ?? defaults[key]])
    const result = Object.fromEntries(patched)
    return result as any
  } else {
    throw Error(`expects input`)
  }
}

export function FallbackValues<Values extends {}, Defaults extends Values>(
  defaults: Defaults,
): (input: Values) => ObjectWithDefaults<Values, Defaults>
export function FallbackValues(defaults: Record<PropertyKey, unknown>): {} {
  return (input: {}) => withFallbackValues(input, defaults)
}

export function withFallbackValuesMaybe<Values extends {}, Defaults extends Values>(
  input: Values | undefined | null,
  defaults: Defaults,
): ObjectWithDefaults<Values, Defaults> | Defaults
export function withFallbackValuesMaybe(
  input: {} | null | undefined,
  defaults: Record<PropertyKey, unknown>,
): {} | null | undefined {
  if (input) {
    const entries = Object.entries(input)
    const patched = entries.map(([key, value]) => [key, value ?? defaults[key]])
    const result = Object.fromEntries(patched)
    return result as any
  } else {
    return null
  }
}

export function WithFallbackValuesMaybe<Values extends {}, Defaults extends Values>(
  defaults: Defaults,
): (input: Values | undefined | null) => ObjectWithDefaults<Values, Defaults> | Defaults
export function WithFallbackValuesMaybe(defaults: Record<PropertyKey, unknown>): {} | null | undefined {
  return (input: {} | null | undefined) => withFallbackValuesMaybe(input, defaults)
}

export function fail(msg: string): never {
  throw Error(msg)
}

export function not<T, R>(fn: (input: T) => R): (input: T) => boolean {
  return input => !fn(input)
}

export function spliceItem<T>(arr: Array<T>, predicate: (item: T) => boolean) {
  const index = arr.findIndex(predicate)
  if (index !== -1) {
    return arr.splice(index, 1)
  } else {
    return null
  }
}
