import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CurrentResourceContainer } from '../../context/current-resource'
import { CurrentCompanyContainer } from '../../context/current-company'
import { Card } from '../../components/Card'
import { MaterialIcon } from '../../components/Icon'
import { List, ListItem } from '../../components/List'
import { SearchInput } from '../../components/SearchInput'
import { Section, SectionList } from '../../components/Section'
import { QueryAlert } from '../../shared/query-alert'
import { defined } from '../../shared/typescript'
import { useDebounceValue } from '../../shared/debounce-value'
import { useResourcesQuery } from '../../gateway/react.generated'
import { DialogWithoutButton } from '../../components/Dialog'
import { FlashAlerts } from '../flash-alerts'

export const SelectResourceDialog = () => {
  const { t } = useTranslation()
  const alerts = FlashAlerts.useAlert()

  const [search, setSearch] = useState(``)
  const defaultResource = CurrentResourceContainer.useCurrent()

  const debouncedSearch = useDebounceValue(search, 500)
  const resourceQuery = SelectResourceDialog.useResourcesQuery(debouncedSearch)

  const [open, setOpen] = useState(!defaultResource.current)

  // Select not blocked and not deleted resourcePerson
  const resourcePerson = (resourceQuery.data?.page ?? [])
    .filter(defined)
    .filter(i => i.type === 'Person' && !i.blocked && !i.removed)

  return (
    <>
      <DialogWithoutButton open={open} title={t('TITLE_CHOOSE_RESOURCE')} onClose={() => {}} onSubmit={() => {}}>
        <SectionList>
          <Section>
            <Card>
              <QueryAlert queries={[resourceQuery]} />
              <div className='ml-search'>
                <SearchInput onChange={setSearch} />
              </div>
              <List>
                {resourcePerson.map((resource, idx) => (
                  <ListItem
                    key={resource?.id ?? idx}
                    icon={<MaterialIcon value='person' style={{ fontSize: `2rem` }} />}
                    primary={resource?.name ?? resource?.name}
                    onClick={() => {
                      resource &&
                        defaultResource.setCurrent({
                          id: resource.id,
                          name: resource.name ?? ``,
                          code: resource.code ?? ``,
                        })
                      alerts.push(`${t('RESOURCE')} ${resource.name} ${t('IS_SELECTED')}`, `success`)
                      setOpen(false)
                    }}
                  />
                ))}
              </List>
            </Card>
          </Section>
        </SectionList>
      </DialogWithoutButton>
    </>
  )
}

SelectResourceDialog.useResourcesQuery = (search: string) => {
  const company = CurrentCompanyContainer.useCurrent()
  const query = useResourcesQuery({
    variables: {
      companyId: company.current?.id ?? null,
      whereResource: {
        removed_eq: false,
        or: [{ name_containslower: search }, { code_containslower: search }],
      },
    },
    fetchPolicy: 'cache-first',
  })
  const data = query.data?.masterdata.company?.resources
  return { ...query, data }
}
