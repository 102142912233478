import React, { useEffect, useRef, useState } from 'react'
import { Formik, MFieldConnector, MFlexBlock, MFlexItem } from '@mprise/react-ui'
import { Section } from '../../components/Section'
import { FieldJob } from '../../shared/form/FieldJob'
import { defined, nameof, NotOptional } from '../../shared/typescript'
import { useTranslation } from 'react-i18next'
import { Collapse, IconButton } from '@material-ui/core'
import Icon from '@mdi/react'
import { mdiChevronRight } from '@mdi/js'
import {
  JobByIdQuery,
  useJobByIdLazyQuery,
  useJobInventoryDetailsByFilterLazyQuery,
} from '../../gateway/react.generated'
import { CurrentCompanyContainer } from '../../context/current-company'
import { QueryErrorMessage } from '../../shared/apollo'
import { LoadingSwitchPanel } from '../../shared/loading-switch-panel'
import { JobEntryForm } from './Home'
import { PhasesSection } from './PhasesSection'
import { JobDetailsCard } from './JobDetailsCard'
import { fail } from '../../shared/typescript'
import { FieldTitle } from '../../components/FieldTitle'
import { useHistory } from '../../shared/use-history'

export type JobValue = NotOptional<NotOptional<JobByIdQuery['masterdata']>['job']>

export const JobForm = () => {
  const h = useHistory()

  const { t } = useTranslation()
  const fc = Formik.useFormikContext<JobEntryForm>()
  const { current: currentCompany } = CurrentCompanyContainer.useCurrent()
  const companyId = currentCompany?.id ?? h.push('/')

  const [openDetails, setOpenDetails] = useState(false)
  const [openPhases, setOpenPhases] = useState(false)
  const handleOpenDetails = (e: any) => {
    setOpenDetails(!openDetails)
  }
  const handleOpenPhases = (e: any) => {
    setOpenPhases(!openPhases)
  }

  const jobId = fc.values.job?.id

  const [getJobById, jobQuery] = useJobByIdLazyQuery({
    fetchPolicy: `network-only`,
  })

  const [getJobInventoryDetails, jobInventoryDetailsQuery] = useJobInventoryDetailsByFilterLazyQuery({
    fetchPolicy: `no-cache`,
  })

  const jobFieldRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    jobFieldRef?.current?.focus()
  }, [jobFieldRef])

  useEffect(() => {
    if (jobId && jobId.length > 0) {
      if (!companyId) {
        fail('expects company id')
      }

      getJobById({
        variables: {
          companyId: companyId,
          jobId: jobId,
        },
      })
      getJobInventoryDetails({
        variables: {
          filter: {
            companyId: companyId,
            jobId: jobId,
          },
        },
      })
      setOpenDetails(true)
      setOpenPhases(true)
    }
  }, [jobId])

  const job = jobId ? jobQuery.data?.masterdata.job : undefined
  const jobInventoryDetails = jobInventoryDetailsQuery.data?.MSjobInventoryDetails?.filter(defined) ?? []
  const unfilteredPhases = job?.jobProductionPhases ?? []
  const phases = unfilteredPhases.filter(phase => phase.noOfSortSubLines === 0)

  return (
    <LoadingSwitchPanel query={[jobQuery, jobInventoryDetailsQuery]}>
      <QueryErrorMessage query={[jobQuery, jobInventoryDetailsQuery]} />
      <Section>
        <MFlexBlock vertical padding={2}>
          <Formik.Field component={MFieldConnector} name={nameof<JobEntryForm>('job')}>
            <FieldJob title={t('ASSIGN_JOB')} ref={jobFieldRef} />
          </Formik.Field>
        </MFlexBlock>
      </Section>
      {job && (
        <Section>
          <MFlexBlock vertical padding={2}>
            <SectionHeader title={'JOB_DETAILS'} open={openDetails} handleOpen={handleOpenDetails} />
            <Collapse in={openDetails}>
              <JobDetailsCard job={job} jobInventoryDetails={jobInventoryDetails} />
            </Collapse>
          </MFlexBlock>
        </Section>
      )}
      {job && phases && phases.length > 0 && (
        <Section>
          <MFlexBlock vertical padding={2}>
            <SectionHeader title={'PRODUCTION_PHASES'} open={openPhases} handleOpen={handleOpenPhases} />
            <Collapse in={openPhases}>
              <PhasesSection
                phases={phases}
                jobInventoryDetails={jobInventoryDetails}
                unfilteredPhases={unfilteredPhases}
              />
            </Collapse>
          </MFlexBlock>
        </Section>
      )}
    </LoadingSwitchPanel>
  )
}

const SectionHeader = ({ title, open, handleOpen }: { title: string; open: boolean; handleOpen: (e: any) => void }) => {
  const { t } = useTranslation()

  return (
    <div className='gh-section-header' onClick={handleOpen}>
      <MFlexItem shrink={0}>
        <IconButton>
          <Icon path={mdiChevronRight} size={1} rotate={open ? 90 : 0} style={{ transition: `transform 0.3s` }} />
        </IconButton>
      </MFlexItem>
      <div style={{ flexGrow: 1 }}>
        <FieldTitle title={t(title)} />
      </div>
    </div>
  )
}
