import { useTranslation } from 'react-i18next'
import { CircularProgress, makeStyles } from '@material-ui/core'
import {
  BarcodeInputStatus,
  MColor,
  MFlex,
  MFlexBlock,
  MFlexItem,
  MSelectItem,
  MSelectItemClickHandler,
  MText,
  px,
  ScanningSetting,
  SearchBox,
} from '@mprise/react-ui'
import { SelectList } from './SelectList'
import { NextPageView } from './NextPageView'

/* Copied from mprise/react-ui. 
   Removed multi-select option.
*/
export interface PaginatedSearchAndSelectSingleProps<T> {
  scanningSetting: ScanningSetting
  nextCursor: string | null
  value: MSelectItem<T> | null
  /** items: null indicates loading */
  items: Array<MSelectItem<T>> | null
  search: string
  onSearch: (search: string) => void
  onChange: (value: MSelectItem<T> | null) => void
  onNextPage: (cursor: string) => void
  showSwitchKeyboardOption: boolean
}

export function PaginatedSearchAndSelect<T>(p: PaginatedSearchAndSelectSingleProps<T>): JSX.Element | null {
  const { nextCursor, items, search, onSearch, onNextPage, showSwitchKeyboardOption } = p

  const handleToggle: MSelectItemClickHandler<T> = i => {
    p.onChange(i)
  }

  const classes = useStyles()

  return (
    <MFlex vertical gap={2}>
      <MFlexItem shrink={0} grow={0} className={classes.searchBox}>
        <SearchBox
          onChange={onSearch}
          status={BarcodeInputStatus.NONE}
          scanningSetting={ScanningSetting.KEYBOARD}
          value={search}
          showSwitchKeyboardOption={showSwitchKeyboardOption}
        />
      </MFlexItem>
      <MFlexItem shrink={1} grow={1} style={{ overflow: `auto` }}>
        {items ? <SelectList items={items} value={p.value} onClick={handleToggle} /> : null}
        {items ? <NextPageView cursor={nextCursor} fetch={onNextPage} /> : null}
        {!items ? <LoadingMessage /> : null}
      </MFlexItem>
    </MFlex>
  )
}

const LoadingMessage = () => {
  const { t } = useTranslation()
  return (
    <MFlexBlock justifyContent='center' alignItems='center' padding={5} gap={5}>
      <MText block textColor={MColor.primary}>
        <CircularProgress color='inherit' size={px(24)} />
      </MText>
      <MText block textColor={MColor.primary}>
        {t(`NOTIFICATION_PAGE_LOADING`)}
      </MText>
    </MFlexBlock>
  )
}

const useStyles = makeStyles(() => ({
  searchBox: {
    backgroundColor: '#ffffff',
    padding: '0.25rem 0.75rem 0.75rem 0.75rem',
    position: 'sticky',
    top: '69px',
    zIndex: 99,
  },
}))
