import { useCallback } from 'react'
import { useBeforeUnload as _useBeforeUnload, unstable_usePrompt as usePrompt } from 'react-router-dom'
import { i18n } from '../i18n/instance'

export function useBeforeUnload(doBlock: boolean) {
  _useBeforeUnload(
    useCallback(
      e => {
        if (doBlock) {
          e.preventDefault()
          return (e.returnValue = '')
        }
      },
      [doBlock],
    ),
  )

  usePrompt({
    when: doBlock,
    message: i18n.t('CONFIRM_DISCARD_UNSAVED_CHANGES'),
  })
}
