import React, { useState } from 'react'
import { Formik, MAudio, withFormikCompareFix } from '@mprise/react-ui'
import { useTranslation } from 'react-i18next'
import { useHistory } from '../../../shared/use-history'
import { useLocalState } from '../../../shared/local-state'
import {
  Item,
  Maybe,
  ReportItemOutputMutationFn,
  useBulkReduceJobInventoryMutation,
  useReportItemOutputMutation,
  useStopTaskMutation,
  useWorkTaskByIdWithTaskResultsQuery,
} from '../../../gateway/react.generated'
import { JobInventoryDetailValue } from '../../../shared/dialog/SelectJobInventoryDetailDialog'
import { FlashAlerts } from '../../../shared/flash-alerts'
import { SavingSwitchPanel } from '../../../shared/saving-switch-panel'
import { MutationErrorMessage } from '../../../shared/apollo'
import { ValidationIssues } from '../../../mprise-light/ValidationIssues'
import { CurrentResourceContainer } from '../../../context/current-resource'
import { DialogFormik } from '../../../mprise-light/DialogFormik'
import { useParams } from 'react-router'
import { Helmet } from 'react-helmet'
import { TaskValue } from '../TaskItem'
import { JobPickForm } from './JobPickForm'
import { ConfirmCancelDialog } from '../../../shared/dialog/ConfirmCancelDialog'
import { fail } from '../../../shared/typescript'
import { ScannedJobInventory } from '../../../shared/interfaces'
import { FormikContextType } from 'formik'

export interface JobPickEntryForm {
  job: Maybe<{ id: string; name: string; code: Maybe<string>; item?: Maybe<Pick<Item, 'id' | 'name'>> }>
  position: Maybe<{ id: string; name: string; code: Maybe<string> }>
  jobInventoryDetail: Maybe<JobInventoryDetailValue>
  originalQuantity: Maybe<number>
  quantity: Maybe<number>
  carrierCode: Maybe<{ id: string; name: string; code: Maybe<string> }>
  scannedJobInventories: Maybe<ScannedJobInventory[]>
}

export const JobPickRoute = () => {
  const h = useHistory()
  const { t } = useTranslation()
  const schema = JobPickForm.useSchema()
  const alerts = FlashAlerts.useAlert()
  const resourceId = CurrentResourceContainer.useCurrent().current?.id ?? h.push('/')
  const [showCancelConfirmDialog, setShowCancelConfirmDialog] = useState<boolean>(false)

  const workItemId = useParams().workItemId!
  const taskId = useParams().taskId!

  const taskQuery = useWorkTaskByIdWithTaskResultsQuery({ variables: { taskId: taskId } })
  const task = taskQuery.data?.workTask

  const [initialValues] = useLocalState((): JobPickEntryForm => {
    return withFormikCompareFix({
      job: { id: '', name: '', code: '' },
      position: { id: '', name: '', code: '' },
      jobInventoryDetail: null,
      originalQuantity: null,
      quantity: null,
      carrierCode: { id: '', name: '', code: '' },
      scannedJobInventories: null,
    })
  }, [])

  const [stopTaskMutation, stopTaskState] = useStopTaskMutation()
  const [reportItemOutput, reportItemOutputState] = useReportItemOutputMutation()
  const [bulkReduceJobInventory, bulkReduceJobInventoryState] = useBulkReduceJobInventoryMutation()

  const handleClose = async (e: React.FormEvent<Element>, fc?: FormikContextType<JobPickEntryForm>) => {
    e.preventDefault()
    e.stopPropagation()

    // If carriers are in cache, confirm if user wants to cancel
    if (fc?.values.scannedJobInventories?.length) {
      setShowCancelConfirmDialog(true)
    } else {
      await stopTaskMutation({ variables: { workItemId: workItemId, taskId: taskId } })
      h.goBack()
    }
  }

  const handleCloseConfirm = async () => {
    if (workItemId && taskId) {
      await stopTaskMutation({ variables: { workItemId: workItemId, taskId: taskId } })
    }
    setShowCancelConfirmDialog(false)
    h.goBack()
  }

  const handleSubmit = async (form: JobPickEntryForm) => {
    if (!resourceId) {
      fail('expects resource id')
    }

    if (!task) {
      return
    }

    let success = true

    const scanned = form.scannedJobInventories ?? []
    const reported = scanned.map(entry => ({
      jidId: entry.jidId,
      jobId: entry.jobId,
      quantity: entry.quantity,
      originPositionId: entry.positionId,
      carrierCode: entry.carrierCode,
    }))

    if (form.jobInventoryDetail && form.quantity) {
      // add manual input to reported (scanned)
      const manualItemOutput = {
        jidId: form.jobInventoryDetail.id,
        jobId: form.jobInventoryDetail.job.id,
        quantity: form.quantity,
        originPositionId: form.jobInventoryDetail.position.id,
        carrierCode: form.carrierCode?.code ?? '',
      }
      reported.push(manualItemOutput)
    }

    const destinationPositionId = form.position!.id
    const succeededIO: string[] = []

    if (reported.length > 0) {
      for (const report of reported) {
        const result = await handleReportItemOutput(
          reportItemOutput,
          workItemId,
          task,
          report.jobId,
          resourceId,
          report.quantity,
          report.originPositionId,
          destinationPositionId,
          report.carrierCode,
        )

        if (result.data) {
          succeededIO.push(report.jidId)
        }
        success &&= !!result.data
      }

      const jobInventoryIdsAndQuantities = reported
        .filter(report => succeededIO.includes(report.jidId))
        .map(report => {
          return { jobInventoryDetailId: report.jidId, quantity: report.quantity }
        })

      const result = await bulkReduceJobInventory({
        variables: {
          jobInventoryIdsAndQuantities: jobInventoryIdsAndQuantities,
        },
      })
      success &&= !!result.data
    }

    if (success) {
      MAudio.scanSuccess()
      alerts.push(t('SUCCESS_MESSAGE'), `success`)
    }

    handleCloseConfirm()
  }

  return (
    <>
      <Helmet title={t('JOB_PICK')} />
      <Formik.Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
        <DialogFormik
          minWidth='xl'
          title={t('JOB_PICK')}
          onClose={handleClose}
          onCloseWithContext={handleClose}
          open={true}
        >
          <SavingSwitchPanel mutation={[reportItemOutputState, bulkReduceJobInventoryState, stopTaskState]}>
            <ValidationIssues />
            <MutationErrorMessage mutation={[reportItemOutputState, bulkReduceJobInventoryState, stopTaskState]} />
            <JobPickForm />
          </SavingSwitchPanel>
        </DialogFormik>
      </Formik.Formik>
      <ConfirmCancelDialog
        open={showCancelConfirmDialog}
        onClose={() => {
          setShowCancelConfirmDialog(false)
        }}
        onConfirm={handleCloseConfirm}
      />
    </>
  )
}

export async function handleReportItemOutput(
  reportItemOutput: ReportItemOutputMutationFn,
  workItemId: string,
  task: TaskValue,
  jobId: string,
  resourceId: string | null,
  quantity: number,
  positionId: string,
  outputPositionId: string,
  carrierCode: string,
) {
  return await reportItemOutput({
    variables: {
      workItemId: workItemId,
      taskId: task.id,
      taskResultId: task.itemOutput![0]!.id,
      values: {
        jobId: jobId,
        resourceId: resourceId,
        quantity: quantity,
        positionId: positionId,
        outputPositionId: outputPositionId,
        trackingIds: [],
        carrierCode: carrierCode,
      },
    },
  })
}
