import { SectionList, MenuSection } from '../components/Section'
import { Toolbar } from '../components/Header'
import { MAppsButton, AppDefinition } from '../components/AppsButton'
import { useAppsQuery, useCompaniesLazyQuery } from '../gateway/react.generated'
import { useTranslation } from 'react-i18next'
import { RoleVisible } from '../auth'
import { CollapseWrapper } from '../components/CollapseWrapper'
import { CurrentCompanyContainer } from '../context/current-company'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { QueryErrorMessage } from '../shared/apollo'
import { LoadingSwitchPanel } from '../shared/loading-switch-panel'
import { Helmet } from 'react-helmet'
import { CurrentResourceContainer } from '../context/current-resource'
import { SelectResourceDialog } from '../shared/dialog/SelectResourceDialog'

export const MenuRoute = () => {
  const { t } = useTranslation()

  const appsQuery = useAppsQuery({
    fetchPolicy: `cache-first`,
  })
  const apps = (appsQuery.data?.my?.tenant?.applications ?? [])
    .map(x => x?.application)
    .filter(x => x?.id && x.name && x.publicUrl)
    .map(
      (x): AppDefinition => ({
        id: x!.id!,
        name: x!.name!,
        url: x!.publicUrl!,
        logoUrl: x!.logoUrl!,
      }),
    )

  const companySetting = CurrentCompanyContainer.useCurrent()
  let redirect = useNavigate()
  const [getCompanies, companiesQuery] = useCompaniesLazyQuery({})
  const companies = companiesQuery.data?.masterdata.companies.page ?? []
  const autoSelectCompany = companiesQuery.data?.masterdata.companies.page.length === 1 ? true : false
  const [initialRender, setInitialRender] = useState(true)

  const resourceId = CurrentResourceContainer.useCurrent().current?.id

  useEffect(() => {
    if (!companySetting.current) {
      getCompanies()
    }
  }, [])

  useEffect(() => {
    const handleUnload = () => {
      var clearCache = localStorage.removeItem('__DEFAULT_COMPANY_GH')
      sessionStorage.clear()
      return clearCache
    }

    // Add the event listener when the component mounts
    window.addEventListener('unload', handleUnload)

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('unload', handleUnload)
    }
  }, [])

  useEffect(() => {
    if (!companySetting.current && autoSelectCompany) {
      const companyName = companies[0]?.name!
      const companyId = companiesQuery.data?.masterdata.companies.page[0]?.id!

      companySetting.setCurrent({ name: companyName, id: companyId })
      setInitialRender(false)
    }
    if (companies.length > 1) {
      setInitialRender(false)
    }
  }, [companiesQuery])

  if (!autoSelectCompany && !companySetting?.current) {
    if (!initialRender) {
      redirect('/settings/company')
    } else if (initialRender && companies.length > 0) {
      redirect('/settings/company')
    }
  }

  return (
    <>
      <Helmet title={t('AGRIWARE_GREENHOUSE')} />
      <LoadingSwitchPanel query={appsQuery}>
        <QueryErrorMessage query={appsQuery} />
        <Toolbar forward={<MAppsButton apps={apps} />} />
        <SectionList>
          <CollapseWrapper isOpened={!!companySetting.current}>
            <RoleVisible role='GREENHOUSE_MY_TASKS'>
              <MenuSection to='/tasks/my' iconName='pending_actions' title={t('MY_TASKS')} />
            </RoleVisible>
            <RoleVisible role='GREENHOUSE_JOB'>
              <MenuSection to='/job' iconName='format_list_bulleted' title={t('JOB')} />
            </RoleVisible>
            <RoleVisible role='GREENHOUSE_TRANSFER'>
              <MenuSection to='/transfer' iconName='low_priority' title={t('TRANSFER')} />
            </RoleVisible>
            <RoleVisible role='GREENHOUSE_PHASE'>
              <MenuSection to='/phase' iconName='arrow_right_alt' title={t('PHASE')} />
            </RoleVisible>
            <RoleVisible role='GREENHOUSE_SCRAP'>
              <MenuSection to='/scrap' iconName='delete_forever' title={t('SCRAP')} />
            </RoleVisible>
            {/* <RoleVisible role='GREENHOUSE_OUTPUT'>
        <MenuSection to='/output' iconName='exit_to_app' title={t('OUTPUT')} />
      </RoleVisible> */}
          </CollapseWrapper>
          <MenuSection to='/settings' iconName='settings' title={t('TITLE_SETTINGS')} />
        </SectionList>
        {!resourceId && !!companySetting.current && <SelectResourceDialog />}
      </LoadingSwitchPanel>
    </>
  )
}
