import { MColor } from '@mprise/react-ui'
import React from 'react'
import Ripples from 'react-ripples'

export const Button = ({
  color,
  dataCy,
  icon,
  children,
  onClick,
  disabled,
}: {
  color?: string
  dataCy?: string
  icon?: React.ReactNode
  children?: React.ReactNode
  onClick?: React.MouseEventHandler
  disabled?: boolean
}) => {
  if (children) {
    return (
      <Button.NormalContainer dataCy={dataCy} color={color ?? MColor.status_todo} onClick={onClick}>
        {icon && <Button.Content>{icon}</Button.Content>}
        <Button.Content>{children}</Button.Content>
      </Button.NormalContainer>
    )
  }
  return (
    <Button.IconContainer dataCy={dataCy} color={color ?? MColor.status_todo} onClick={onClick}>
      <Button.Content>{icon}</Button.Content>
    </Button.IconContainer>
  )
}

Button.Content = ({ children }: { children?: React.ReactNode }) => <div className='ml-button-content'>{children}</div>

Button.IconContainer = ({
  color,
  dataCy,
  children,
  onClick,
  disabled,
}: {
  color: string
  dataCy?: string
  children?: React.ReactNode
  onClick?: React.MouseEventHandler
  disabled?: boolean
}) => (
  <div>
    <Ripples placeholder={undefined}>
      <button
        data-cy={dataCy}
        className='ml-icon-button'
        style={{ '--color': color } as any}
        onClick={onClick}
        disabled={disabled}
      >
        {children}
      </button>
    </Ripples>
  </div>
)

Button.NormalContainer = ({
  color,
  dataCy,
  children,
  onClick,
  disabled,
}: {
  color: string
  dataCy?: string
  children?: React.ReactNode
  onClick?: React.MouseEventHandler
  disabled?: boolean
}) => (
  <div>
    <Ripples placeholder={undefined}>
      <button
        data-cy={dataCy}
        className='ml-button'
        style={{ '--color': color } as any}
        onClick={onClick}
        disabled={disabled}
      >
        {children}
      </button>
    </Ripples>
  </div>
)
