import React from 'react'
import './theme.css'

export const Card = ({
  header,
  children,
  onClick,
}: {
  header?: React.ReactNode
  children?: React.ReactNode
  onClick?: () => void
}) => {
  return (
    <Card.Container>
      {header && (
        <Card.Header>
          <Card.HeaderContent>{header}</Card.HeaderContent>
        </Card.Header>
      )}
      <Card.Content>{children}</Card.Content>
    </Card.Container>
  )
}
Card.Container = ({ children }: { children?: React.ReactNode }) => <section className={`ml-card`}>{children}</section>
Card.Header = ({ children }: { children?: React.ReactNode }) => <div className={`ml-card-header`}>{children}</div>
Card.HeaderContent = ({
  children,
  onClick,
}: {
  children?: React.ReactNode
  onClick?: React.MouseEventHandler<HTMLDivElement>
}) => (
  <div className={`ml-card-header-content`} onClick={onClick}>
    {children}
  </div>
)
Card.Content = ({ children }: { children?: React.ReactNode }) => <div className={`ml-card-content`}>{children}</div>
