import { useEffect, useRef, useState } from 'react'
import { Formik, MAudio, MFieldConnector, MFieldInteger, MFlexBlock, Yup } from '@mprise/react-ui'
import { useTranslation } from 'react-i18next'
import { FlashAlerts } from '../../../shared/flash-alerts'
import { ScannedUploadCarrier } from '../../../shared/interfaces'
import { Section } from '../../../components/Section'
import { nameof } from '../../../shared/typescript'
import { FieldCarrierCode } from '../../../shared/form/FieldCarrierCode'
import { FieldPosition } from '../../../shared/form/FieldPosition'
import { FieldJob } from '../../../shared/form/FieldJob'
import { FieldJobInventoryDetail } from '../../../shared/form/FieldJobInventoryDetail'
import { UploadCarriersEntryForm } from './Home'
import { ScannedUploadCarriersSection } from './ScannedUploadCarriersSection'
import {
  Maybe,
  useOutboundByWorkItemIdLazyQuery,
  useWorkTaskByIdWithTaskResultsQuery,
} from '../../../gateway/react.generated'
import { useParams } from 'react-router-dom'
import { FieldTitle } from '../../../components/FieldTitle'
import { SelectedJobInventoryDetails } from '../../../shared/form/selectionDetails'
import { TaskDetailsSection } from '../../../shared/form/TaskDetailsSection'
import { useBeforeUnload } from '../../../shared/before-unload'
import { suppressEnter } from '../../../shared/util/util'
import { i18n } from '../../../i18n/instance'

export const UploadCarriersForm = () => {
  const { t } = useTranslation()
  const alerts = FlashAlerts.useAlert()
  const fc = Formik.useFormikContext<UploadCarriersEntryForm>()
  const carrierCode = fc.values.carrierCode
  const taskId = useParams().taskId ?? ''
  const workItemId = fc.values.workItemId

  useBeforeUnload(!!fc.values.carrierQuantity && !fc.isSubmitting)

  const [scanned, setScanned] = useState<ScannedUploadCarrier[]>([])
  const [totalQuantity, setTotalQuantity] = useState<Maybe<number>>(null)

  const taskQuery = useWorkTaskByIdWithTaskResultsQuery({ variables: { taskId: taskId } })
  const task = taskQuery.data?.workTask

  const [getPreviousScanned, previousScannedQuery] = useOutboundByWorkItemIdLazyQuery({
    fetchPolicy: `network-only`,
  })

  useEffect(() => {
    // Prefill form if WorkItemTask is executed
    const job = (task?.workItem.jobs ?? [])[0]
    const position = (task?.jobInventoryPutAway ?? [])[0]?.planned.position

    if (task && job && position) {
      fc.setValues(current => ({
        ...current,
        job: { id: job.id, code: job.code!, name: job.name! },
        toPosition: { id: position.id, code: position.code!, name: position.name! },
      }))
    }
  }, [taskQuery])

  useEffect(() => {
    if (workItemId) {
      getPreviousScanned({
        variables: {
          workItemId: workItemId,
        },
      })
    }
  }, [])

  // carrierCodes that have already been posted in outbound of this workItem
  const previousScanned = workItemId ? previousScannedQuery.data?.outboundJobInventoryByWorkItemId ?? [] : []

  /** Add scanned carriers to state */
  useEffect(() => {
    if (carrierCode && carrierCode.code && carrierCode.code.length > 0) {
      if (!fc.values.carrierQuantity || fc.values.carrierQuantity < 0) {
        alerts.push(i18n.t('FILL_IN_CARRIER_QTY_ABOVE_0'), 'error')
        MAudio.scanError()
      } else {
        addToScannnedState({
          carrierCode: fc.values.carrierCode?.code ?? '',
          quantity: fc.values.carrierQuantity,
          timestamp: Date.now(),
        })
      }
    }
  }, [carrierCode])

  const addToScannnedState = async (input: ScannedUploadCarrier) => {
    const isAlreadyScanned = scanned.some(entry => entry.carrierCode === input.carrierCode)
    const isScannedPreviously = previousScanned?.some(entry => entry?.carrierCode === input.carrierCode)

    if (isAlreadyScanned || isScannedPreviously) {
      MAudio.scanError()
      alerts.push(i18n.t('CARRIER_ALREADY_SCANNED', { carrier: input.carrierCode }), 'error')
    } else {
      MAudio.scanSuccess()
      alerts.push(i18n.t('CARRIER_SCANNED', { carrier: input.carrierCode }), 'success')

      fc.setValues({
        ...fc.values,
        carrierCode: { id: '', name: '', code: '' },
        scannedCarriers: [...(fc.values.scannedCarriers ?? []), input],
      })
    }
  }

  /** Update scanned state when form updates */
  useEffect(() => {
    setScanned(fc.values.scannedCarriers?.sort((a, b) => b.timestamp - a.timestamp) ?? [])
  }, [fc.values.scannedCarriers])

  /** Update total quantity state when scanned state updates */
  useEffect(() => {
    const sumQuantity = scanned.map(x => x.quantity).reduce((x, y) => x + y, 0)
    setTotalQuantity(sumQuantity)
  }, [scanned])

  /** Set focus to the field when the form opens */
  const jobFieldRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    jobFieldRef?.current?.focus()
  }, [jobFieldRef])

  return (
    <>
      {task && <TaskDetailsSection task={task} />}
      <Section>
        <MFlexBlock vertical padding={2}>
          <Formik.Field component={MFieldConnector} name={nameof<UploadCarriersEntryForm>('job')}>
            <FieldJob title={t('ASSIGN_JOB')} ref={jobFieldRef} />
          </Formik.Field>
          <Formik.Field component={MFieldConnector} name={nameof<UploadCarriersEntryForm>('fromPosition')}>
            <FieldPosition title={t('ASSIGN_POSITION')} label={`FROM_POSITION`} neverDisable />
          </Formik.Field>
          <div style={{ display: 'none' }}>
            <Formik.Field component={MFieldConnector} name={nameof<UploadCarriersEntryForm>('jobInventoryDetail')}>
              <FieldJobInventoryDetail title={t('ASSIGN_JOB_INVENTORY_DETAIL')} />
            </Formik.Field>
          </div>
          <Formik.Field component={MFieldConnector} name={nameof<UploadCarriersEntryForm>('toPosition')}>
            <FieldPosition title={t('ASSIGN_POSITION')} label={`TO_POSITION`} neverDisable />
          </Formik.Field>
        </MFlexBlock>
      </Section>

      <Section>
        <SelectedJobInventoryDetails jobInventoryDetail={fc.values.jobInventoryDetail} showPosition={false} />
      </Section>

      <Section>
        <MFlexBlock vertical padding={2}>
          <Formik.Field
            style={{}}
            component={MFieldConnector}
            name={nameof<UploadCarriersEntryForm>(`carrierQuantity`)}
          >
            <FieldTitle title={t(`CARRIER_QUANTITY`)} />
            <div style={{ marginTop: '8px', maxWidth: '50%' }}>
              <MFieldInteger onKeyDown={suppressEnter} min={0} />
            </div>
          </Formik.Field>
          <Formik.Field component={MFieldConnector} name={nameof<UploadCarriersEntryForm>('carrierCode')}>
            <FieldCarrierCode title={t('ASSIGN_CARRIER_CODE')} />
          </Formik.Field>
        </MFlexBlock>
      </Section>

      {scanned.length > 0 && <ScannedUploadCarriersSection scanned={scanned} totalQuantity={totalQuantity} />}
    </>
  )
}

UploadCarriersForm.useSchema = () => {
  const { t } = useTranslation()

  // Transfer to same position is allowed.

  const [schema] = useState(
    (): Yup.SchemaOf<UploadCarriersEntryForm> =>
      Yup.object().shape({
        job: Yup.object()
          .shape({
            id: Yup.string().required('Job is a required field'),
            name: Yup.string().nullable(),
            code: Yup.string().nullable(),
          })
          .required('Job is a required field')
          .nullable()
          .label(t(`JOB`)),
        fromPosition: Yup.object()
          .shape({
            id: Yup.string().nullable(),
            name: Yup.string().nullable(),
            code: Yup.string().nullable(),
          })
          .nullable(),
        jobInventoryDetail: Yup.mixed().required().label(t(`JOB_INVENTORY_DETAIL`)),
        toPosition: Yup.object()
          .shape({
            id: Yup.string().required('Position is a required field'),
            name: Yup.string().nullable(),
            code: Yup.string().nullable(),
          })
          .required()
          .nullable()
          .label(t(`POSITION`)),
        carrierCode: Yup.mixed()
          .optional()
          .nullable()
          .label(t(`JOB`))
          .test('carrierCode', 'No carriers scanned', async (input: any, context: any) => {
            return !!context.parent.scannedCarriers?.length
          }),
        carrierQuantity: Yup.mixed().nullable().optional().label('carrierQuantity'),
        scannedCarriers: Yup.mixed().optional().nullable(),
        isWorkItemTransfer: Yup.boolean().required(),
        workItemId: Yup.mixed().optional().nullable(),
      }),
  )
  return schema
}
