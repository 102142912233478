import { Formik, MAudio, withFormikCompareFix } from '@mprise/react-ui'
import { useTranslation } from 'react-i18next'
import { useHistory } from '../../../shared/use-history'
import { useLocalState } from '../../../shared/local-state'
import {
  Maybe,
  WorkItemConsumptionEntryLotNumberInput,
  useReportItemConsumptionMutation,
  useReportJobInventoryPutAwayMutation,
  useStopTaskMutation,
  useWorkTaskByIdWithTaskResultsQuery,
} from '../../../gateway/react.generated'
import { CreateInventoryForm } from './CreateInventoryForm'
import { FlashAlerts } from '../../../shared/flash-alerts'
import { SavingSwitchPanel } from '../../../shared/saving-switch-panel'
import { MutationErrorMessage } from '../../../shared/apollo'
import { ValidationIssues } from '../../../mprise-light/ValidationIssues'
import { CurrentResourceContainer } from '../../../context/current-resource'
import { DialogFormik } from '../../../mprise-light/DialogFormik'
import { useParams } from 'react-router'
import { Helmet } from 'react-helmet'
import { fail } from '../../../shared/typescript'

export interface CreateInventoryFormDefinition {
  reportedItemConsumptions: Array<{
    itemId: string
    quantity: Maybe<number>
    quantityUnit?: Maybe<string>
    taskResultId: string
    lotNumbers: Array<WorkItemConsumptionEntryLotNumberInput>
  }>
  quantityPutAway: Maybe<number>
  plannedQuantityPutAway: string | undefined
  reportedQuantityPutAway: string | undefined
  quantityUnitPutAway: Maybe<string> | undefined
  toPositionPutAway: Maybe<{ id: string; name: string; code: Maybe<string> }>
}

export const CreateInventoryRoute = () => {
  const h = useHistory()
  const { t } = useTranslation()
  const alerts = FlashAlerts.useAlert()
  const resourceId = CurrentResourceContainer.useCurrent().current?.id ?? h.push('/')

  const workItemId = useParams().workItemId!
  const taskId = useParams().taskId!
  const [stopTaskMutation, stopTaskState] = useStopTaskMutation()

  const taskQuery = useWorkTaskByIdWithTaskResultsQuery({ variables: { taskId: taskId } })
  const task = taskQuery.data?.workTask

  const jobInventoryPutAway = task?.jobInventoryPutAway ? task.jobInventoryPutAway[0] : null
  const plannedPosition = jobInventoryPutAway?.planned.position ?? null

  const handleClose = async (e: React.FormEvent<Element>) => {
    if (workItemId && taskId) {
      await stopTaskMutation({ variables: { workItemId: workItemId, taskId: taskId } })
    }
    h.goBack()
  }

  const [reportItemConsumption, reportItemConsumptionState] = useReportItemConsumptionMutation()
  const [reportJobInventoryPutAway, reportJobInventoryPutAwayState] = useReportJobInventoryPutAwayMutation()

  const [initialValues] = useLocalState((): CreateInventoryFormDefinition => {
    return withFormikCompareFix({
      toPositionPutAway: plannedPosition
        ? { id: plannedPosition!.id!, name: plannedPosition!.name!, code: plannedPosition!.code! }
        : { id: '', name: '', code: '' },
      quantityPutAway: null,
      plannedQuantityPutAway: jobInventoryPutAway?.planned.quantity?.toLocaleString('nl-NL'),
      reportedQuantityPutAway: jobInventoryPutAway?.reported
        .reduce((a, b) => a + (b.quantity ?? 0), 0)
        .toLocaleString('nl-NL'),
      quantityUnitPutAway: jobInventoryPutAway?.planned.quantityUnit,
      reportedItemConsumptions:
        task?.itemConsumption?.map(i => {
          return {
            taskResultId: i.id,
            itemId: i.planned.item?.id!,
            itemName: i.planned.itemDescription,
            quantity: null,
            plannedQuantity: i.planned.quantity?.toLocaleString('nl-NL'),
            reportedQuantity: i.reported.reduce((a, b) => a + (b.quantity ?? 0), 0).toLocaleString('nl-NL'),
            quantityUnit: i.planned.quantityUnit,
            lotNumbersRequired: i.planned.lotNumbersRequired,
            lotNumbers: [],
          }
        }) ?? [],
    })
  }, [])

  const handleSubmit = async (form: CreateInventoryFormDefinition) => {
    if (!resourceId) {
      fail('expects resource id')
    }

    let success = true

    //TODO: Doing a submit for every ItemConsumption separately is not the most efficient. Ideally the api should accept multiple at once.
    for (let i = 0; i < form.reportedItemConsumptions.length; i++) {
      const itemConsumption = form.reportedItemConsumptions[i]

      if (itemConsumption && itemConsumption.quantity) {
        const result = await reportItemConsumption({
          variables: {
            workItemId: workItemId,
            taskId: taskId,
            taskResultId: itemConsumption.taskResultId!,
            values: {
              itemId: itemConsumption.itemId,
              lotNumbers: itemConsumption.lotNumbers,
              quantity: itemConsumption.quantity,
              quantityUnit: itemConsumption.quantityUnit,
              resourceId: resourceId,
            },
          },
        })
        success &&= !!result.data
      }
    }

    if (jobInventoryPutAway && form.quantityPutAway) {
      const result = await reportJobInventoryPutAway({
        variables: {
          workItemId: workItemId!,
          taskId: taskId!,
          taskResultId: jobInventoryPutAway.id,
          quantity: form.quantityPutAway,
          quantityUnit: jobInventoryPutAway.planned.quantityUnit,
          quantityPerArea: jobInventoryPutAway.planned.quantityPerArea,
          quantityPerAreaUnit: jobInventoryPutAway.planned.quantityPerAreaUnit,
          resourceId: resourceId,
          positionId: form.toPositionPutAway!.id,
        },
      })
      success &&= !!result.data
    }

    if (success) {
      MAudio.scanSuccess()
      alerts.push(t('SUCCESS_MESSAGE'), `success`)
    }

    if (workItemId && taskId) {
      await stopTaskMutation({ variables: { workItemId: workItemId, taskId: taskId } })
    }
    h.goBack()
  }

  return (
    <>
      <Helmet title={t('EXECUTE_TASK')} />

      <Formik.Formik initialValues={initialValues} validate={CreateInventoryForm.validate} onSubmit={handleSubmit}>
        <DialogFormik minWidth='xl' title={t('EXECUTE_TASK')} onClose={handleClose} open={true}>
          <SavingSwitchPanel mutation={[reportItemConsumptionState, reportJobInventoryPutAwayState, stopTaskState]}>
            <ValidationIssues />
            <MutationErrorMessage
              mutation={[reportItemConsumptionState, reportJobInventoryPutAwayState, stopTaskState]}
            />
            <CreateInventoryForm />
          </SavingSwitchPanel>
        </DialogFormik>
      </Formik.Formik>
    </>
  )
}
