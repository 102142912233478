import styled from '@emotion/styled'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  withStyles,
} from '@material-ui/core'
import IconSwitchCamera from '@material-ui/icons/SwitchCamera'
import { Alert } from '@material-ui/lab'
import { mdiChevronRight, mdiInformation } from '@mdi/js'
import Icon from '@mdi/react'
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BarcodeCompletedHandler, useScannerDialogHooks } from '@mprise/react-ui'

export const ScannerDialog = ({
  autoComplete = true,
  open,
  onCancelled,
  onCompleted,
  scanDialogTitle,
}: {
  autoComplete?: boolean
  open: boolean
  onCompleted: BarcodeCompletedHandler
  onCancelled: () => void
  scanDialogTitle?: string
}) => {
  const { t } = useTranslation()
  const dialog = useRef<HTMLDivElement>(null)
  const [title, setTitle] = useState<string>('')
  useEffect(() => {
    if (scanDialogTitle) {
      setTitle(scanDialogTitle)
    }
  }, [scanDialogTitle])

  const { setViewportElement, state, pressedNext, device, loading, error, retry, canNextDevice, handleNextDevice } =
    useScannerDialogHooks(open, dialog, autoComplete, onCompleted)

  if (error) {
    return (
      <Dialog open={open} onClose={onCancelled}>
        {!title && <DialogTitle>{t('BarcodeDialogTitle')}</DialogTitle>}
        {title && <DialogTitle>{title?.trim().length === 0 ? t('BarcodeDialogTitle') : title}</DialogTitle>}
        <DialogContent>
          <DialogContentText>{error}</DialogContentText>
          <DialogContentText>{t('BarcodeIssueHint')}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button type='button' onClick={onCancelled} color='primary'>
            {t('Close')}
          </Button>
          <Button type='button' onClick={retry} color='primary' autoFocus>
            {t('Retry')}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  const ifEmptyItem = !loading && state.result.length === 0 && (
    <ListItem>
      <ListItemIcon>
        <Icon size={1} path={mdiInformation} />
      </ListItemIcon>
      <ListItemText primary={t('BarcodeDialogHint')} />
    </ListItem>
  )

  const ifMultipleAlert = state.result.length > 1 && (
    <Alert severity='warning'>{t('BarcodeMultipleResults', { count: state.result.length })}</Alert>
  )

  return (
    <Dialog open={open} maxWidth='xl' onClose={onCancelled}>
      {loading && <LinearProgress color='primary' variant='indeterminate' />}
      <DialogTitle>
        <Box display='flex' alignItems='center'>
          {title && <Box flexGrow={1}>{title?.trim().length === 0 ? t('BarcodeDialogTitle') : title}</Box>}
          {!title && <Box flexGrow={1}>{t('BarcodeDialogTitle')}</Box>}
          <Box>
            {canNextDevice && (
              <IconButton onClick={handleNextDevice}>
                <IconSwitchCamera />
              </IconButton>
            )}
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent ref={dialog}>
        <Box display='flex' flexDirection='column' justifyContent='center' style={{ gap: `0.5rem` }}>
          {pressedNext ? <Alert severity='info'>{device ?? `No device`}</Alert> : null}
          {ifMultipleAlert}
          <Viewport ref={setViewportElement} />
          <Divider />
          <List style={{ minHeight: 100 }}>
            {ifEmptyItem}
            {state.result.map((r, idx) => (
              <BarcodeItem key={idx} {...r} onClick={onCompleted} />
            ))}
          </List>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

const BarcodeItem = ({
  code,
  format,
  timeoutScore,
  confidenceScore,
  onClick,
}: {
  code: string
  format: string
  timeoutScore: number
  confidenceScore: number
  onClick: (arg: { code: string; format: string }) => void
}) => {
  return (
    <ListItem
      button
      onClick={() => {
        onClick({ code, format })
      }}
    >
      <ListItemIcon>
        {timeoutScore > 0.3 ? (
          <TimingOutCircularProgress variant='determinate' value={Math.pow(confidenceScore, 2) * 100} />
        ) : (
          <CircularProgress color='primary' variant='determinate' value={Math.pow(confidenceScore, 2) * 100} />
        )}
      </ListItemIcon>
      <ListItemText primary={code} secondary={format} />
      <ListItemSecondaryAction>
        <IconButton>
          <Icon size={1} path={mdiChevronRight} />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  )
}

const TimingOutCircularProgress = withStyles({
  root: {
    color: 'orange',
  },
})(CircularProgress)

const Viewport = styled.video`
  position: relative;
  width: 240px;
  margin: auto;
`
