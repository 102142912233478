import { ApolloClient, ApolloError, DocumentNode, isApolloError, MutationOptions, QueryOptions } from '@apollo/client'
import { Button, Collapse } from '@material-ui/core'
import { MColor, MFlexBlock, MFlexItem, MText, MTextColor } from '@mprise/react-ui'
import { useTranslation } from 'react-i18next'
import { MaterialIcon } from '../components/Icon'

export function makeQueryCallback<Return, Variables>(query: DocumentNode) {
  return <Output,>(project?: (data: Return) => Output) => {
    return async function (
      variables: Variables,
      apollo: ApolloClient<unknown>,
      options?: Omit<QueryOptions<Return, Variables>, `errorPolicy` | 'mutation' | 'variables'>,
    ): Promise<Output> {
      const { data } = await apollo.query<Return, Variables>({
        ...options,
        errorPolicy: `all`,
        query,
        variables,
      })
      return project ? project(data) : (data as unknown as Output)
    }
  }
}

export function makeMutationCallback<Return, Variables>(mutation: DocumentNode) {
  return <Output,>(project?: (data: Return | null | undefined) => Output) => {
    return async function (
      variables: Variables,
      apollo: ApolloClient<unknown>,
      options?: Omit<MutationOptions<Return, Variables>, `errorPolicy` | 'mutation' | 'variables'>,
    ): Promise<Output> {
      const { data } = await apollo.mutate<Return, Variables>({
        ...options,
        errorPolicy: `all`,
        mutation,
        variables,
      })
      return project ? project(data) : (data as unknown as Output)
    }
  }
}

type QueryingValueInterface = {
  loading: boolean
  error?: Error | ApolloError | null
  refetch: () => void
}

export const QueryErrorMessage = ({ query }: { query: QueryingValueInterface | Array<QueryingValueInterface> }) => {
  query = [query].flat()
  const { t } = useTranslation()

  const firstWithAnIssue = query.find(x => !x.loading && !!x.error)
  const handleRetry = () => firstWithAnIssue?.refetch()
  return (
    <Collapse in={!!firstWithAnIssue} unmountOnExit timeout={100}>
      <MFlexBlock bgColor={MColor.medium} margin={0} padding={2}>
        <MFlexItem grow={1}>
          <MFlexBlock textColor={MTextColor.bright} gap={2}>
            <MaterialIcon value='warning' />
            <MText block textVariant='content'>
              {t(`NOTIFICATION_ERROR_FETCH_FAILED`)}
            </MText>
          </MFlexBlock>
          <MText block textVariant='small' style={{ marginLeft: '2rem' }}>
            {firstWithAnIssue?.error?.message === 'Failed to fetch'
              ? t(`FAILED_TO_FETCH`)
              : firstWithAnIssue?.error?.message}
          </MText>
        </MFlexItem>
        <MFlexItem shrink={0}>
          <Button variant='outlined' size='small' onClick={handleRetry}>
            Retry
          </Button>
        </MFlexItem>
      </MFlexBlock>
    </Collapse>
  )
}

export const MutationErrorMessage = ({
  mutation,
}: {
  mutation: Omit<QueryingValueInterface, 'refetch'> | Array<Omit<QueryingValueInterface, 'refetch'>>
}) => {
  const { t } = useTranslation()

  mutation = Array.isArray(mutation) ? mutation : [mutation]

  const firstWithAnIssue = mutation.find(x => !x.loading && !!x.error)
  const codes =
    firstWithAnIssue?.error && isApolloError(firstWithAnIssue.error)
      ? firstWithAnIssue?.error.graphQLErrors.map(x => x.extensions?.code)
      : []

  const singleCode = codes?.length === 1 ? codes[0] : null

  const codeMap: Record<string, string> = {
    W0001: t(`ERROR_TASK_REPORT_CONFLICT_INPUT_DUPLICATE`),
  }

  const message = (singleCode && codeMap[singleCode]) || firstWithAnIssue?.error?.message

  return (
    <Collapse in={!!firstWithAnIssue} unmountOnExit timeout={100}>
      <MFlexBlock bgColor={MColor.medium} margin={0} padding={2}>
        <MFlexItem grow={1}>
          <MFlexBlock textColor={MTextColor.bright} gap={2}>
            <MaterialIcon value='warning' />
            <MText block textVariant='content'>
              {t('TITLE_MUTATION_ERROR')}
            </MText>
          </MFlexBlock>
          <MText block textVariant='small' style={{ marginLeft: '2rem' }}>
            {message === 'Failed to fetch' ? t(`FAILED_TO_FETCH`) : message}
          </MText>
        </MFlexItem>
      </MFlexBlock>
    </Collapse>
  )
}
