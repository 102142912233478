import {
  MDivider,
  MFlexBlock,
  MJoinChildren,
  MSelectItem,
  MSelectItemClickHandler,
  MText,
  MTextColor,
} from '@mprise/react-ui'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { SelectListItem } from './SelectListItem'

/* Copied from mprise/react-ui. 

*/
export interface MSelectListProps<T> {
  value: Array<MSelectItem<T>> | MSelectItem<T> | null
  items: Array<MSelectItem<T>>
  onClick: MSelectItemClickHandler<T>
}

export const SelectList = <T,>(p: MSelectListProps<T>) => {
  const { value, items, onClick } = p
  const selected = [value ?? []].flat().map(x => x.id)

  return (
    <div>
      <MJoinChildren divider={MDivider}>
        {items.map(x => (
          <SelectListItem key={x.id} item={x} selected={selected.includes(x.id)} onClick={onClick} />
        ))}
        {items.length > 0 ? null : <EmptyMessage />}
      </MJoinChildren>
    </div>
  )
}

const EmptyMessage = () => {
  const { t } = useTranslation()
  return (
    <MFlexBlock justifyContent='center'>
      <MText block textVariant='content' textColor={MTextColor.shadow} style={{ padding: '1rem' }}>
        {t(`NOTIFICATION_NO_RESULTS`)}
      </MText>
    </MFlexBlock>
  )
}
