import { MpriseAuthProvider } from '@mprise/react-auth'
import { useTranslation } from 'react-i18next'
import { AppLanguageContainer } from '../../context/app-language'
import { CurrentCompanyContainer } from '../../context/current-company'
import { ScanningContainer } from '../../context/scanning'
import { DialogHeaderBack } from '../../components/Header'
import { MaterialIcon } from '../../components/Icon'
import { List, ListItem } from '../../components/List'
import { Section, SectionList } from '../../components/Section'
import { useHistory } from '../../shared/use-history'
import { CurrentResourceContainer } from '../../context/current-resource'
import { StatusText } from '../../components/status-text'
import { useCompaniesQuery } from '../../gateway/react.generated'
import { IconButton } from '@material-ui/core'
import { mdiBarcodeScan } from '@mdi/js'
import { Icon } from '@mdi/react'
import { Helmet } from 'react-helmet'

export const SettingsRoute = () => {
  const h = useHistory()
  const { t } = useTranslation()

  const { mpriseId } = MpriseAuthProvider.useContext()
  const handleSignout = () => {
    localStorage.removeItem('__DEFAULT_COMPANY_GH')
    localStorage.removeItem('__RESOURCE_GH')
    sessionStorage.clear()
    mpriseId?.logout()
  }

  const { user } = MpriseAuthProvider.useContext()
  const company = CurrentCompanyContainer.useCurrent()
  const defaultLanguage = AppLanguageContainer.useDefault()
  const defaultScanning = ScanningContainer.useDefault()
  const currentResource = CurrentResourceContainer.useCurrent()

  const chevronRightIcon = (
    <MaterialIcon value='chevron_right' style={{ marginRight: '0.5rem', verticalAlign: 'middle' }} />
  )

  return (
    <>
      <Helmet title={t('TITLE_SETTINGS')} />

      <DialogHeaderBack title={t('TITLE_SETTINGS')} onClose={() => h.goBack()} />
      <SectionList>
        <Section>
          <List>
            <ListItem
              icon={
                <IconButton size='small'>
                  <MaterialIcon value='person' style={{ fontSize: `2rem` }} />
                </IconButton>
              }
              primary={user?.name ?? user?.email ?? `-`}
            />
          </List>
        </Section>

        <Section>
          <List>
            <ListItem
              icon={
                <IconButton>
                  <MaterialIcon value='language' style={{ fontSize: `2rem` }} />
                </IconButton>
              }
              primary={t('MENU_LANGUAGE')}
              secondary={
                defaultLanguage.default.name ??
                defaultLanguage.default.id ?? <StatusText status='neutral'>{t(`PLACEHOLDER_NOT_SET`)}</StatusText>
              }
              action={chevronRightIcon}
              onClick={() => h.push(`/settings/language`)}
            />
            <ListItem
              icon={
                <IconButton>
                  <MaterialIcon value='business' style={{ fontSize: `2rem` }} />
                </IconButton>
              }
              primary={t('MENU_COMPANY')}
              secondary={
                company.current?.name ??
                company.current?.id ?? <StatusText status='bad'>{t(`PLACEHOLDER_NOT_SET`)}</StatusText>
              }
              action={chevronRightIcon}
              onClick={() => h.push(`/settings/company`)}
            />
            <ListItem
              icon={
                <IconButton>
                  <MaterialIcon value='person' style={{ fontSize: `2rem` }} />
                </IconButton>
              }
              primary={t('MENU_RESOURCE')}
              secondary={
                currentResource.current?.name ??
                currentResource.current?.id ?? <StatusText status='neutral'>{t(`PLACEHOLDER_NOT_SET`)}</StatusText>
              }
              action={chevronRightIcon}
              onClick={() => h.push(`/settings/resource`)}
            />

            <ListItem
              icon={
                <IconButton>
                  <Icon size={1.2} path={mdiBarcodeScan} style={{ paddingLeft: '2px' }} />
                </IconButton>
              }
              primary={t('SCANNING')}
              secondary={t(`SCANNING_${defaultScanning.scanning}`)}
              action={chevronRightIcon}
              onClick={() => h.push(`/settings/scanning`)}
            />
            <ListItem
              icon={
                <IconButton>
                  <MaterialIcon value='description' style={{ fontSize: `2rem` }} />
                </IconButton>
              }
              primary={t('CURRENT_VERSION')}
              secondary={'v1.0.0'}
            />
          </List>
        </Section>

        <Section>
          <List>
            <ListItem
              icon={
                <IconButton size='small'>
                  <MaterialIcon value='exit_to_app' style={{ transform: `rotate(180deg)` }} />
                </IconButton>
              }
              onClick={handleSignout}
              primary={t('ACTION_SIGN_OUT')}
            />
          </List>
        </Section>
      </SectionList>
    </>
  )
}

SettingsRoute.useCompaniesQuery = (search: string) => {
  const query = useCompaniesQuery({
    variables: {
      whereCompany: {
        or: [{ name_containslower: search }],
      },
    },
  })
  const data = query.data?.masterdata.companies.page
  return { ...query, data }
}
