import { Formik, MAudio, MFieldConnector, MFlexBlock, MFlexItem, MText, MTextColor } from '@mprise/react-ui'
import { useTranslation } from 'react-i18next'
import { DialogFormik } from '../../mprise-light/DialogFormik'
import { Section } from '../../components/Section'
import { LocalDate, Period } from '@js-joda/core'
import { nameof } from '../../shared/typescript'
import { JobProductionPhase, Maybe, useUpdateJobProductionPhasesMutation } from '../../gateway/react.generated'
import { FlashAlerts } from '../../shared/flash-alerts'
import { FieldDate } from '../../mprise-light/FieldDate'
import { fail } from '../../shared/typescript'
import { MutationErrorMessage } from '../../shared/apollo'

export interface EditJobPhaseForm {
  job: Maybe<{ id: string; name: string; code: Maybe<string> }>
  startDate: LocalDate
  phaseId: string
  parentLineNo: number
}

export const EditPhaseStartDateDialog = ({
  phaseText,
  phase,
  startDate,
  unfilteredPhases,
  open,
  handleClose,
}: {
  phaseText: string
  phase: JobProductionPhase
  startDate: LocalDate
  unfilteredPhases: JobProductionPhase[]
  open: boolean
  handleClose: () => void
}) => {
  const { t } = useTranslation()
  const alerts = FlashAlerts.useAlert()

  const fc = Formik.useFormikContext<EditJobPhaseForm>()
  const jobId = fc.values.job?.id

  const initialValues = {
    startDate: startDate,
    phaseId: phase.id,
    parentLineNo: phase.parentLineNo,
    job: fc.values.job,
  }

  const [updateJobProductionPhases, updateJobProductionPhasesState] = useUpdateJobProductionPhasesMutation()

  const handleSubmitChangeStartDate = async (form: EditJobPhaseForm) => {
    if (!jobId) {
      fail('expects job id')
    }
    // To do: Change this mutation when jobProductionPhases are migrated to a seperate postgres db.

    // change start and enddate for phases current and in the future. Change enddate of the previous phase.
    const dateChange = Period.between(startDate, form.startDate)

    const changedPhases = unfilteredPhases.map(phase => {
      if (phase.parentLineNo >= form.parentLineNo) {
        return {
          ...phase,
          startingDate: phase.startingDate.plus(dateChange),
          endDate: phase.endDate.plus(dateChange),
          __typename: undefined,
        }
      } else if (phase.endDate.equals(startDate)) {
        return {
          ...phase,
          endDate: phase.endDate.plus(dateChange),
          __typename: undefined,
        }
      } else {
        return { ...phase, __typename: undefined }
      }
    })

    const result = await updateJobProductionPhases({
      variables: { jobId: jobId, jobProductionPhases: changedPhases },
    })

    if (result.data) {
      MAudio.scanSuccess()
      alerts.push(t('SUCCESS_MESSAGE'), `success`)
    }
    handleClose()
  }

  return (
    <>
      <Formik.Formik initialValues={initialValues} onSubmit={handleSubmitChangeStartDate}>
        <DialogFormik minWidth='xs' title={t(`EDIT_STARTDATE_PHASE`)} onClose={handleClose} open={open}>
          <MutationErrorMessage mutation={updateJobProductionPhasesState} />
          <Section>
            <MFlexBlock vertical padding={[6, 4, 7, 4]}>
              <MFlexItem alignSelf='center'>
                <MText block textVariant='inherit' textColor={MTextColor.dark}>
                  {phaseText}
                </MText>
              </MFlexItem>
              <MFlexItem>
                <Formik.Field
                  component={MFieldConnector}
                  name={nameof<EditJobPhaseForm>(`startDate`)}
                  label={t(`START_DATE`)}
                >
                  <FieldDate minWidth='xs' dialogHeader={t(`EDIT_STARTDATE_PHASE`)} />
                </Formik.Field>
              </MFlexItem>
            </MFlexBlock>
          </Section>
        </DialogFormik>
      </Formik.Formik>
    </>
  )
}
