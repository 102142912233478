import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ScanningContainer, ScanningSetting } from '../../context/scanning'
import { Card } from '../../components/Card'
import { DialogHeader } from '../../components/Header'
import { MaterialIcon } from '../../components/Icon'
import { List, ListItem } from '../../components/List'
import { Section, SectionList } from '../../components/Section'
import { useHistory } from '../../shared/use-history'
import { DialogBackButton, DialogSaveButton } from '../../shared/dialog/DialogButtons'
import { MColor } from '@mprise/react-ui'

export const SettingsScanningRoute = () => {
  const { t } = useTranslation()
  const h = useHistory()

  const scanningStatus = ScanningContainer.useDefault()
  const [selected, setSelected] = useState(scanningStatus.scanning)

  const handleCancel = () => h.goBack()
  const handleSave = () => {
    scanningStatus.setScanning(selected)
    h.goBack()
  }

  return (
    <>
      <DialogHeader
        title={t('TITLE_SETTINGS')}
        backward={<DialogBackButton onClick={handleCancel} />}
        forward={<DialogSaveButton onClick={handleSave} />}
      />

      <SectionList>
        <Section>
          <Card header={t('CHOOSE_SCANNING_METHOD')}>
            <List>
              {Object.values(ScanningSetting)
                .map(x => ({ id: x, name: x }))
                .map(x => (
                  <ListItem
                    key={x.id}
                    icon={<MaterialIcon value={getScanningIconByScanningSetting(x.id)} style={{ fontSize: `2rem` }} />}
                    primary={t(`SCANNING_${x.name}`)}
                    action={selected === x.id ? <MaterialIcon value='check' style={{ color: MColor.primary }} /> : null}
                    onClick={() => x && setSelected(x.id)}
                  />
                ))}
            </List>
          </Card>
        </Section>
      </SectionList>
    </>
  )
}

const getScanningIconByScanningSetting = (setting: ScanningSetting): string => {
  switch (setting) {
    case ScanningSetting.CAMERA:
      return 'photo_camera'
    case ScanningSetting.KEYBOARD:
      return 'keyboard'
    case ScanningSetting.SCANNER:
      return 'qr_code_scanner'
    default:
      return 'qr_code_scanner'
  }
}
