import { LocalDate } from '@js-joda/core'
import { Locale as JsJodaLocale } from '@js-joda/locale_en-us'
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints'
import { DatePicker, DatePickerProps, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { parseDate, useMField } from '@mprise/react-ui'
import createJsJodaUtils from '@prinzdezibel/date-io-js-joda'
import React, { useState } from 'react'
import { DateDialog } from './DateDialog'

export const FieldDateWithDialog = ({
  dialogHeader,
  minWidth = 'sm',
  ...props
}: Partial<DatePickerProps> & { dialogHeader?: React.ReactNode; minWidth?: number | Breakpoint }) => {
  const f = useMField()
  const [instance] = useState(() => createJsJodaUtils(LocalDate))

  const [open, setOpen] = useState(false)
  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = (date: LocalDate | undefined) => {
    f.onChange?.(date)
    setOpen(false)
  }

  const date = f.value instanceof LocalDate ? f.value : parseDate(f.value)

  return (
    <MuiPickersUtilsProvider utils={instance} locale={JsJodaLocale.US}>
      <DateDialog
        open={open}
        title={dialogHeader}
        value={date ?? undefined}
        onClose={handleClose}
        minWidth={minWidth}
      />
      <DatePicker
        name={f.name ?? undefined}
        id={f.id ?? undefined}
        value={date ?? null}
        onChange={e => f.onChange?.(e)}
        {...props}
        fullWidth
        open={false}
        onOpen={handleOpen}
      />
    </MuiPickersUtilsProvider>
  )
}
