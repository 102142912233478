import React from 'react'

export const CounterBadge = ({ children, count }: { children?: React.ReactNode; count?: React.ReactNode }) => {
  return (
    <CounterBadge.Container>
      <CounterBadge.Content>{children}</CounterBadge.Content>
      {count !== null ? <CounterBadge.Count>{count}</CounterBadge.Count> : null}
    </CounterBadge.Container>
  )
}

CounterBadge.Container = ({ children }: { children?: React.ReactNode }) => (
  <div className='ml-counterbadge-container'>{children}</div>
)
CounterBadge.Content = ({ children }: { children?: React.ReactNode }) => (
  <div className='ml-counterbadge-content'>{children}</div>
)
CounterBadge.Count = ({ children }: { children?: React.ReactNode }) => (
  <div className='ml-counterbadge-count'>{children}</div>
)
