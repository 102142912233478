import { MColor, MFlexBlock, MFlexItem, MText } from '@mprise/react-ui'
import { Badge } from '@mprise/react-ui/dist/controls/avatar/badge'
import { useTranslation } from 'react-i18next'
import { MaterialIcon } from '../../components/Icon'
import { JobProductionPhase } from '../../gateway/react.generated'
import { StyledButton } from '../../shared/dialog/JobInventoryDetailCard'
import { formatDateWithWeekNo } from '../../shared/formats'

export const PhaseCard = ({
  phase,
  handleSelect,
}: {
  phase: JobProductionPhase
  handleSelect: (values: JobProductionPhase) => void
}) => {
  const { productionPhase, sortingCode, startingDate, endDate } = phase
  const avatar = <MaterialIcon value='arrow_right_alt' style={{ fontSize: `2rem` }} />
  const { t } = useTranslation()

  return (
    <StyledButton type='button' onClick={() => handleSelect(phase)}>
      <MFlexBlock alignItems='center' gap={2} padding={3}>
        <MFlexItem shrink={0} grow={0} style={{ margin: '0.5rem' }}>
          <Badge size='medium' color={MColor.dim} children={avatar} />
        </MFlexItem>
        <MFlexBlock vertical>
          <MFlexItem grow={1} style={{ width: '100%', color: 'black' }}>
            <MFlexItem>
              <MText inline textVariant='small bold'>
                {productionPhase} &nbsp;
              </MText>
              <MText inline textVariant='small bold'>
                {sortingCode === '' ? '' : ` | ${sortingCode}`}
              </MText>
            </MFlexItem>
            <MFlexItem>
              <MText block textVariant='small'>
                {t('START_DATE')} : &nbsp;{formatDateWithWeekNo(startingDate)}
              </MText>
            </MFlexItem>
            <MFlexItem>
              <MText block textVariant='small'>
                {t('END')} &nbsp; : &nbsp;{formatDateWithWeekNo(endDate)}
              </MText>
            </MFlexItem>
          </MFlexItem>
        </MFlexBlock>
      </MFlexBlock>
    </StyledButton>
  )
}
