import { useApolloClient } from '@mprise/gateway-client'
import { MAvatar, MSelectItem, ToMSelectItem } from '@mprise/react-ui'
import React from 'react'
import { CurrentCompanyContainer } from '../../context/current-company'
import { ScanningContainer } from '../../context/scanning'
import {
  Item,
  Job,
  JobByFilterDocument,
  JobByFilterQuery,
  JobByFilterQueryVariables,
  Maybe,
} from '../../gateway/react.generated'
import { PaginatedSearchAndSelect } from '../../mprise-light/PaginatedSearchAndSelect'
import { QueryErrorMessage } from '../apollo'
import { useDebounceValue } from '../debounce-value'
import { useLocalState } from '../local-state'
import { defined, NotOptional, fail } from '../typescript'
import { useAsync } from '../use-async'
import { DialogWithBackButton } from '../../components/Dialog'

export type JobValue = NotOptional<NotOptional<JobByFilterQuery['masterdata']['company']>['jobs']['page'][0]>

const toSelectItem = ToMSelectItem<JobValue>({
  id: x => x.id,
  icon: () => <MAvatar.Icon.Job />,
  primary: x => x.name ?? ``,
  secondary: x => x.code ?? null,
})

export const SelectJobDialog = ({
  title,
  open,
  onClose,
  onSave,
  itemConstraint,
}: {
  title: React.ReactNode
  open: boolean
  onClose: () => void
  onSave: (values: any) => void
  itemConstraint?: Maybe<Pick<Item, 'id' | 'name'>>
}) => {
  const scanningStatus = ScanningContainer.useDefault()
  const [search, setSearch] = useLocalState(``, [open])
  const debouncedSearch = useDebounceValue(search, 500)
  const [cursor, setCursor] = useLocalState<string | null>(null, [search])

  const jobQuery = SelectJobDialog.useQuery({
    cursor,
    query: debouncedSearch,
    itemConstraint,
  })

  const handleSelect = async (item: MSelectItem<JobValue> | null) => {
    onSave(item?.data ?? null)
  }

  const handleSubmit: React.FormEventHandler = async e => {
    e.stopPropagation()
    e.preventDefault()
    onClose()
  }

  const handleNextPage = (cursor: string) => {
    setCursor(cursor)
  }

  const nextCursor = jobQuery?.data?.nextCursor ?? null
  const jobs = jobQuery.data?.page?.filter(defined).map(toSelectItem) ?? null

  return (
    <DialogWithBackButton open={open} title={title} onClose={onClose} onSubmit={handleSubmit}>
      <QueryErrorMessage query={jobQuery} />
      <PaginatedSearchAndSelect
        scanningSetting={scanningStatus.scanning}
        items={jobs}
        nextCursor={nextCursor}
        search={search}
        value={null}
        onChange={handleSelect}
        onNextPage={handleNextPage}
        onSearch={setSearch}
        showSwitchKeyboardOption={true}
      />
    </DialogWithBackButton>
  )
}

SelectJobDialog.useQuery = ({
  query,
  cursor,
  itemConstraint,
}: {
  query: string
  cursor: string | null
  itemConstraint?: Maybe<Pick<Item, 'id' | 'name'>>
}) => {
  const { current: currentCompany } = CurrentCompanyContainer.useCurrent()
  const companyId = currentCompany?.id
  const apollo = useApolloClient()

  const a = useAsync<{
    page: Array<Pick<Job, `id` | `name` | `code`>>
    nextCursor: string | null
  }>(async () => {
    if (!companyId) {
      fail('expects company id')
    }

    const response = await apollo.query<JobByFilterQuery, JobByFilterQueryVariables>({
      query: JobByFilterDocument,
      variables: {
        companyId,
        jobCursor: cursor,
        whereJob: {
          removed_eq: false,
          and: [
            { or: [{ status_eq: 'In production' }, { status_eq: 'In productie' }] },
            { or: [{ name_containslower: query }, { code_containslower: query }] },
          ],
          item_eq: itemConstraint?.id,
        },
      },
    })
    const page = response.data.masterdata.company?.jobs.page.filter(defined) ?? []
    const nextCursor = response.data.masterdata.company?.jobs.nextCursor ?? null
    return { page, nextCursor }
  }, [query, cursor])

  return a
}
