import { useApolloClient } from '@apollo/client'
import { MAudio, useMField } from '@mprise/react-ui'
import React, { ForwardedRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CurrentCompanyContainer } from '../../context/current-company'
import { ScanningContainer } from '../../context/scanning'
import {
  CarrierCodeByCodeDocument,
  CarrierCodeByCodeQuery,
  CarrierCodeByCodeQueryVariables,
  Position,
} from '../../gateway/react.generated'
import { makeQueryCallback } from '../apollo'
import { FlashAlerts } from '../flash-alerts'
import { fail } from '../typescript'
import { FieldBarcode } from '../../mprise-light/FieldBarcode'
import { SelectCarrierCodeDialog } from '../dialog/SelectCarrierCode'
import { useHistory } from '../use-history'

export type FieldCarrierCodeValue = Pick<Position, 'id' | 'name' | 'code'>

export const FieldCarrierCode = React.forwardRef(
  (
    {
      title,
    }: {
      title: string
    },
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    const { t } = useTranslation()
    const h = useHistory()
    const f = useMField()
    const { current: currentCompany } = CurrentCompanyContainer.useCurrent()
    const companyId = currentCompany?.id ?? h.push('/')
    const apollo = useApolloClient()
    const scanningStatus = ScanningContainer.useDefault()
    const alerts = FlashAlerts.useAlert()
    const [open, setOpen] = useState(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)
    const scanDialogTitle = t('SCAN_CARRIER_CODE')

    return (
      <>
        <SelectCarrierCodeDialog
          open={open}
          onClose={handleClose}
          onSave={(carrierCode: FieldCarrierCodeValue) => {
            f.onChange?.(carrierCode)
            setOpen(false)
          }}
          title={title}
        />
        <FieldBarcode<FieldCarrierCodeValue>
          title={t(`CARRIER_CODE`)}
          scanningSetting={scanningStatus.scanning}
          disabled={false}
          valueAsText={x => x.code ?? t(`PLACEHOLDER_NOT_SET`)}
          onLookup={async text => {
            if (!companyId) {
              fail('expects company id')
            }
            return await fetchCarrierCodeByCode({ companyId, carrierCode: text }, apollo)
          }}
          onError={() => {
            MAudio.scanError()
          }}
          onSuccess={(text, item) => {
            if (text && !item) {
              MAudio.scanError()
              alerts.push(t('NOTIFICATION_NOT_FOUND'), `error`)
            }
          }}
          onExplore={handleOpen}
          scanDialogTitle={scanDialogTitle}
          ref={ref}
        />
      </>
    )
  },
)

const fetchCarrierCodeByCode = makeQueryCallback<CarrierCodeByCodeQuery, CarrierCodeByCodeQueryVariables>(
  CarrierCodeByCodeDocument,
)(x => x.masterdata.position ?? null)
