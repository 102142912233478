import React, { useEffect } from 'react'
import { Formik, MAudio, ValidationIssues, withFormikCompareFix } from '@mprise/react-ui'
import { useTranslation } from 'react-i18next'
import {
  Maybe,
  useStopTaskMutation,
  useUploadAndTransferCarriersToPositionMutation,
} from '../../../gateway/react.generated'
import { ScannedUploadCarrier } from '../../../shared/interfaces'
import { useHistory } from '../../../shared/use-history'
import { FlashAlerts } from '../../../shared/flash-alerts'
import { CurrentResourceContainer } from '../../../context/current-resource'
import { useLocalState } from '../../../shared/local-state'
import { DialogFormik } from '../../../mprise-light/DialogFormik'
import { SavingSwitchPanel } from '../../../shared/saving-switch-panel'
import { MutationErrorMessage } from '../../../shared/apollo'
import { UploadCarriersForm } from './UploadCarriersForm'
import { JobInventoryDetailValue } from '../../../shared/dialog/SelectJobInventoryDetailDialog'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { fail } from '../../../shared/typescript'

export interface UploadCarriersEntryForm {
  job: Maybe<{ id: string; name: string; code: Maybe<string> }>
  jobInventoryDetail: Maybe<JobInventoryDetailValue>
  fromPosition: Maybe<{ id: string; name: string; code: Maybe<string> }>
  toPosition: Maybe<{ id: string; name: string; code: Maybe<string> }>
  carrierCode: Maybe<{ id: string; name: string; code: Maybe<string> }>
  carrierQuantity: number | undefined
  scannedCarriers: Maybe<ScannedUploadCarrier[]>
  isWorkItemTransfer: boolean
  workItemId: Maybe<string>
}

export const UploadCarriersRoute = () => {
  const h = useHistory()
  const { t } = useTranslation()
  const alerts = FlashAlerts.useAlert()

  const schema = UploadCarriersForm.useSchema()
  const resourceId = CurrentResourceContainer.useCurrent().current?.id ?? h.push('/')

  // If Transfer is used by a redirect of a WorkOrder: Prefill form
  const workItemId = useParams().workItemId ?? null
  const taskId = useParams().taskId
  // If phaseCode is defined: do an automatic phase transition at transfer
  const phaseCodeText = useParams().phaseCode
  const phaseCode = phaseCodeText === 'null' ? null : phaseCodeText

  const [stopTaskMutation, stopTaskState] = useStopTaskMutation()

  const [initialValues] = useLocalState((): UploadCarriersEntryForm => {
    return withFormikCompareFix({
      job: { id: '', name: '', code: '' },
      fromPosition: { id: '', name: '', code: '' },
      jobInventoryDetail: null,
      toPosition: { id: '', name: '', code: '' },
      carrierCode: { id: '', name: '', code: '' },
      carrierQuantity: undefined,
      scannedCarriers: null,
      isWorkItemTransfer: workItemId ? true : false,
      workItemId: workItemId ?? null,
    })
  }, [])

  const handleClose = async () => {
    if (workItemId && taskId) {
      await stopTaskMutation({ variables: { workItemId: workItemId, taskId: taskId } })
    }
    h.goBack()
  }

  const [reportUploadAndTransferCarriersToPosition, reportUploadAndTransferCarriersToPositionState] =
    useUploadAndTransferCarriersToPositionMutation()

  const handleSubmitUploadCarriers = async (form: UploadCarriersEntryForm, actions: Formik.FormikHelpers<UploadCarriersEntryForm>) => {
    actions.resetForm()

    if (!resourceId) {
      fail('expects resource id')
    }

    const scanned = form.scannedCarriers ?? []
    const carrierCodesAndQuantities = scanned.map(entry => {
      return {
        carrierCode: entry.carrierCode,
        quantity: entry.quantity,
      }
    })

    const result = await reportUploadAndTransferCarriersToPosition({
      variables: {
        originJobInventoryDetailId: form.jobInventoryDetail?.id!,
        carrierCodesAndQuantities: carrierCodesAndQuantities,
        destinationPositionId: form.toPosition?.id!,
        resourceId: resourceId,
        destinationPhase: phaseCode,
        workItemId: workItemId,
      },
    })

    if (result.data) {
      MAudio.scanSuccess()
      const successCount = result.data.MSuploadAndTransferCarriersToPositionGH?.succeeded?.length
      alerts.push(t('X_OUT_OF_Y_SUCCESSFULLY_POSTED', { x: successCount, y: scanned.length }), 'success')
    }
    handleClose()
  }

  return (
    <>
      <Helmet title={t('UPLOAD_CARRIER_CODES')} />

      <Formik.Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={handleSubmitUploadCarriers}
      >
        <DialogFormik
          minWidth='xl'
          title={t('UPLOAD_CARRIER_CODES')}
          onClose={handleClose}
          onCloseWithContext={handleClose}
          open={true}
        >
          <SavingSwitchPanel
            mutation={[reportUploadAndTransferCarriersToPositionState, stopTaskState]}
          >
            <ValidationIssues />
            <MutationErrorMessage
              mutation={[reportUploadAndTransferCarriersToPositionState, stopTaskState]}
            />
            <UploadCarriersForm />
          </SavingSwitchPanel>
        </DialogFormik>
      </Formik.Formik>
    </>
  )
}
