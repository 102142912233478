import { useTranslation } from 'react-i18next'
import { MenuSection, SectionList } from '../../components/Section'
import { RoleVisible } from '../../auth'
import { useHistory } from '../../shared/use-history'
import { DialogHeaderBack } from '../../components/Header'
import { Helmet } from 'react-helmet'

export const TransferMenuRoute = () => {
  const { t } = useTranslation()
  const h = useHistory()

  const handleClose = () => {
    h.goBack()
  }

  return (
    <>
      <Helmet title={t('TRANSFER')} />

      <DialogHeaderBack title={t('TRANSFER')} onClose={handleClose} />
      <SectionList>
        <RoleVisible role='GREENHOUSE_TRANSFER'>
          <MenuSection to='/transfer/job' iconName='low_priority' title={t(`TITLE_JOB_TO_POSITION`)} />
        </RoleVisible>
        <RoleVisible role='GREENHOUSE_TRANSFER_CARRIERS'>
          <MenuSection to='/transfer/carriercodes' iconName='low_priority' title={t(`TITLE_CARRIER_TO_POSITION`)} />
        </RoleVisible>
        <RoleVisible role='GREENHOUSE_TRANSFER_CARRIERS'>
          <MenuSection to='/transfer/uploadcarriers' iconName='low_priority' title={t('UPLOAD_CARRIER_CODES')} />
        </RoleVisible>
      </SectionList>
    </>
  )
}
