import { LocalDate } from '@js-joda/core'
import { Locale as JsJodaLocale } from '@js-joda/locale_en-us'
import { Button } from '@material-ui/core'
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints'
import { Calendar, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { MBlock, MColor, MDialog, MFlexBlock } from '@mprise/react-ui'
import createJsJodaUtils from '@prinzdezibel/date-io-js-joda'
import React, { FormEvent, useEffect, useState } from 'react'
import { DialogHeaderBack } from '../components/Header'
import { Section } from '../components/Section'
import { useTranslation } from 'react-i18next'

// Copied and adjusted from react-ui MDateDialog.

export const DateDialog = ({
  title,
  value,
  open,
  onClose,
  minWidth = 'sm',
}: {
  title: React.ReactNode
  value?: LocalDate
  open: boolean
  onClose: (value?: LocalDate) => void
  minWidth?: number | Breakpoint
}) => {
  const { t } = useTranslation()

  const [instance] = useState(() => createJsJodaUtils(LocalDate))
  const [local, setLocal] = useState<LocalDate | undefined>(undefined)
  const handleClear = () => {
    onClose(undefined)
  }
  const handleToday = () => {
    onClose(LocalDate.now())
  }
  const handleClose = () => {
    onClose(value)
  }
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    e.stopPropagation()
    onClose(local)
  }
  const handleChange = (date: LocalDate) => {
    setLocal(date)
    onClose(date)
  }
  useEffect(() => {
    setLocal(value)
  }, [value, open])

  return (
    <MDialog.Dialog minWidth={minWidth} open={open} onClose={handleClose}>
      <MDialog.Form onSubmit={handleSubmit}>
        <DialogHeaderBack title={title} onClose={handleClose} />
        <Section>
          <div style={{ padding: '1rem 0.5rem 3rem 0.5rem' }}>
            <MuiPickersUtilsProvider utils={instance} locale={JsJodaLocale.US}>
              <MBlock padding={2} bgColor={MColor.white} justifyContent='center'>
                <div style={{ overflow: `hidden` }}>
                  <Calendar date={local ?? LocalDate.now()} onChange={handleChange} />
                </div>
              </MBlock>
            </MuiPickersUtilsProvider>
            <MDialog.Footer>
              <MFlexBlock justifyContent='center' gap={2}>
                <Button variant='contained' onClick={handleClear}>
                  {t(`CLEAR`)}
                </Button>
                <Button variant='contained' onClick={handleToday}>
                  {t(`TODAY`)}
                </Button>
              </MFlexBlock>
            </MDialog.Footer>
          </div>
        </Section>
      </MDialog.Form>
    </MDialog.Dialog>
  )
}
