import { Formik, MAudio } from '@mprise/react-ui'
import { TransferCarrierCodesEntryForm } from '../../routes/transfer/transfer-carriers/Home'
import { EntryInfo } from '../../routes/transfer/transfer-carriers/UpdateJobInventoryDialog'
import { FlashAlerts } from '../flash-alerts'
import { MDialog, MDialogContentText } from '../../mprise-light/Dialog'
import { ScannedJobInventory } from '../interfaces'
import { useTranslation } from 'react-i18next'

export const DeleteScannedJobInventoryDialog = ({
  entry,
  open,
  handleClose,
}: {
  entry: ScannedJobInventory
  open: boolean
  handleClose: () => void
}) => {
  const { t } = useTranslation()

  const fc = Formik.useFormikContext<TransferCarrierCodesEntryForm>()
  const alerts = FlashAlerts.useAlert()

  const deleteEntryFromFormContext = async () => {
    MAudio.scanSuccess()
    alerts.push(t('ENTRY_REMOVED'), 'success')

    fc.setValues({
      ...fc.values,
      scannedJobInventories: fc.values.scannedJobInventories?.filter(i => i.jidId !== entry.jidId) ?? [],
    })
    handleClose()
  }

  return (
    <MDialog open={open} title={t('REMOVE_FROM_SELECTION')} onSubmit={deleteEntryFromFormContext} onClose={handleClose}>
      <MDialogContentText>{t('QUESTION_ARE_YOU_SURE')}</MDialogContentText>
      <EntryInfo entry={entry} />
    </MDialog>
  )
}
