import React, { ForwardedRef, useState } from 'react'
import { useApolloClient } from '@apollo/client'
import { Alerts, MAudio, useMField } from '@mprise/react-ui'
import { useTranslation } from 'react-i18next'
import {
  Lot,
  LotByFilterQuery,
  LotByFilterQueryVariables,
  LotByFilterDocument,
  LotFilter,
} from '../../gateway/react.generated'
import { makeQueryCallback } from '../apollo'
import { SelectLotNumberDialog, LotValue } from '../dialog/SelectLotNumberDialog'
import { CurrentCompanyContainer } from '../../context/current-company'
import { ScanningContainer } from '../../context/scanning'
import { FieldBarcode } from '../../mprise-light/FieldBarcode'

type FieldLotValue = Pick<Lot, 'id' | 'lotNumber' | 'item'>

export const FieldLotNumber = React.forwardRef(
  (
    {
      title,
      label,
      selectedLots,
      itemId,
    }: {
      title: string
      label: string
      selectedLots?: string[]
      itemId: string
    },
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    const { t } = useTranslation()

    const company = CurrentCompanyContainer.useCurrent().current
    const companyId = company?.id ?? ``
    const f = useMField()

    const [open, setOpen] = useState(false)

    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    const alerts = Alerts.useAlert()
    const apollo = useApolloClient()

    const scanningSetting = ScanningContainer.useDefault().scanning

    const validateLot = (lotNumber?: LotValue | FieldLotValue | null) => {
      if (!lotNumber) {
        MAudio.scanError()
        alerts.push(t(`WrongLotNumber`), `error`)
        return false
      }

      if (selectedLots?.includes(lotNumber.lotNumber ?? '')) {
        MAudio.scanError()
        alerts.push(t(`LotNumberAlreadyScannedMessage`, { subject: lotNumber.lotNumber }), `error`)
        return false
      }

      MAudio.scanSuccess()
      return true
    }

    const handleSelected = async (lot: LotValue | null) => {
      setOpen(false)

      const whereLot: LotFilter = {
        companyId: companyId,
        open: true,
        itemId: itemId,
        lotNumber: lot?.lotNumber,
      }

      const lotQuery = await fetchLotByLotNumber({ lotFilter: whereLot }, apollo)

      if (validateLot(lotQuery)) {
        f.onChange?.(lotQuery)
      } else {
        return null
      }
    }

    const handleLookup = async (lotNumber: string) => {
      const whereLot: LotFilter = {
        companyId: companyId,
        open: true,
        itemId: itemId,
        lotNumber: lotNumber,
      }

      const lotQuery = await fetchLotByLotNumber({ lotFilter: whereLot }, apollo)

      if (validateLot(lotQuery)) {
        return lotQuery
      } else {
        return null
      }
    }

    return (
      <>
        <SelectLotNumberDialog
          open={open}
          itemId={itemId}
          companyId={companyId}
          onClose={handleClose}
          onSave={handleSelected}
          title={title}
        />
        <FieldBarcode<FieldLotValue | LotValue>
          title={label}
          scanningSetting={scanningSetting}
          valueAsText={x => x.lotNumber ?? t(`PLACEHOLDER_NOT_SET`)}
          onLookup={handleLookup}
          onError={() => {
            MAudio.scanError()
          }}
          onSuccess={(text, item) => {
            if (text && item) {
              MAudio.scanSuccess()
            }
            if (text && !item) {
              MAudio.scanError()
            }
          }}
          onExplore={handleOpen}
          ref={ref}
        />
      </>
    )
  },
)

const fetchLotByLotNumber = makeQueryCallback<LotByFilterQuery, LotByFilterQueryVariables>(LotByFilterDocument)(
  x => x.lots![0] ?? null,
)
