import { Formik, MBlock, MColor, MFlexBlock, MFlexItem, MText } from '@mprise/react-ui'
import React from 'react'
import { defined } from '../typescript'
import { PhaseCard } from '../../routes/phase/PhaseCard'
import { PhaseEntryForm } from '../../routes/phase/Home'
import { useTranslation } from 'react-i18next'
import { JobProductionPhase, useJobByIdForPhaseQuery } from '../../gateway/react.generated'
import { QueryErrorMessage } from '../apollo'
import { useLocalState } from '../local-state'
import { Collapse, IconButton, makeStyles } from '@material-ui/core'
import { mdiFilter } from '@mdi/js'
import { MaterialIcon } from '../../components/Icon'
import Icon from '@mdi/react'
import { List, ListItem } from '../../components/List'
import { DialogWithBackButton } from '../../components/Dialog'

export const SelectPhaseDialog = ({
  title,
  open,
  onClose,
  onSave,
}: {
  title: React.ReactNode
  open: boolean
  onClose: () => void
  onSave: (values: any) => void
}) => {
  const { t } = useTranslation()

  const fc = Formik.useFormikContext<PhaseEntryForm>()
  const jobId = fc.values.job?.id ?? ''

  const [showPreviousPhases, setShowPreviousPhases] = useLocalState(false, [open])
  const [showFuturePhases, setShowFuturePhases] = useLocalState(true, [open])

  const changeShowPreviousPhases = () => {
    setShowPreviousPhases(!showPreviousPhases)
  }
  const changeShowFuturePhases = () => {
    setShowFuturePhases(!showFuturePhases)
  }

  const phaseQuery = useJobByIdForPhaseQuery({
    variables: { jobId: jobId },
  })

  const handleSelect = (phase: JobProductionPhase) => {
    onSave(phase)
  }

  const handleSubmit: React.FormEventHandler = async e => {
    e.stopPropagation()
    e.preventDefault()
    onClose()
  }

  const phases = phaseQuery.data?.masterdata.job?.jobProductionPhases?.filter(defined) ?? []
  const originJobProductionPhase = phases.find(
    phase => phase.productionPhase === fc.values.jobInventoryDetail?.productionPhase,
  )

  const originParentLineNo = originJobProductionPhase?.parentLineNo ?? 0

  // Filter Phases based on parentLineNo. Future phases have greater parentLineNo than origin.
  const filteredPhases = filterPhases()

  return (
    <DialogWithBackButton open={open} title={title} onClose={onClose} onSubmit={handleSubmit}>
      <QueryErrorMessage query={phaseQuery} />
      <PhaseFilter
        showPreviousPhases={showPreviousPhases}
        showFuturePhases={showFuturePhases}
        handleChangePrevious={changeShowPreviousPhases}
        handleChangeFuture={changeShowFuturePhases}
      />
      <MFlexBlock vertical style={{ overflow: `auto`, position: 'relative' }}>
        {filteredPhases.length > 0 ? (
          filteredPhases.map(phase => <PhaseCard key={phase.id} phase={phase} handleSelect={handleSelect} />)
        ) : (
          <MBlock padding={5}>
            <MText block>{t(`NOTIFICATION_NO_PHASES`)}</MText>
          </MBlock>
        )}
      </MFlexBlock>
    </DialogWithBackButton>
  )

  /*
  Function to filter all the Phases based on the chosen filter options.
   */
  function filterPhases() {
    return phases.filter(phase => {
      if ((!showFuturePhases && !showPreviousPhases) || phase.noOfSortSubLines !== 0) {
        return false
      } else if (showFuturePhases && showPreviousPhases) {
        return phase.parentLineNo !== originParentLineNo
      } else if (showFuturePhases) {
        return phase.parentLineNo > originParentLineNo
      } else if (showPreviousPhases) {
        return phase.parentLineNo < originParentLineNo
      }
    })
  }
}

const PhaseFilter = ({
  showPreviousPhases,
  showFuturePhases,
  handleChangePrevious,
  handleChangeFuture,
}: {
  showPreviousPhases: boolean
  showFuturePhases: boolean
  handleChangePrevious: () => void
  handleChangeFuture: () => void
}) => {
  const [openFilter, setOpenFilter] = useLocalState(false)
  const handleOpenFilter = (e: any) => {
    e.stopPropagation()
    e.preventDefault()
    setOpenFilter(!openFilter)
  }

  const { t } = useTranslation()

  const classes = useStyles()
  const hasFilter = showPreviousPhases || showFuturePhases

  return (
    <>
      <MFlexBlock
        vertical
        grow={1}
        style={{
          backgroundColor: '#ffffff',
          padding: '0.25rem 0.75rem 0.75rem 0.75rem',
          boxSizing: 'border-box',
          position: 'sticky',
          top: '4.3rem',
          width: '100%',
          zIndex: 99,
        }}
      >
        <MFlexBlock style={{ justifyContent: 'right', alignItems: 'right' }}>
          <MFlexItem style={{ display: 'flex' }}>
            <IconButton style={{ padding: '4px' }} onClick={handleOpenFilter}>
              <Icon
                style={{ backgroundColor: '#ebeff2', borderRadius: '50%', padding: '10px' }}
                path={mdiFilter}
                size={1}
                color={hasFilter ? MColor.medium : MColor.dim}
              />
            </IconButton>
          </MFlexItem>
        </MFlexBlock>
        <Collapse in={openFilter} className={classes.filterDiv}>
          <List>
            <MFlexItem className={'select-jid-filterItems'}>
              <ListItem
                key='previous'
                primary={t(`PREVIOUS_PHASES`)}
                action={
                  showPreviousPhases ? (
                    <MaterialIcon
                      style={{ color: `#009a44`, fontSize: '1.3rem', marginRight: '0.8rem' }}
                      value='check'
                    />
                  ) : null
                }
                onClick={handleChangePrevious}
              />
            </MFlexItem>
            <MFlexItem className={'select-jid-filterItems'}>
              <ListItem
                key='future'
                primary={t(`FUTURE_PHASES`)}
                action={
                  showFuturePhases ? (
                    <MaterialIcon
                      style={{ color: `#009a44`, fontSize: '1.3rem', marginRight: '0.8rem' }}
                      value='check'
                    />
                  ) : null
                }
                onClick={handleChangeFuture}
              />
            </MFlexItem>
          </List>
        </Collapse>
      </MFlexBlock>
    </>
  )
}

const useStyles = makeStyles(() => ({
  filterDiv: {
    border: '2px solid #eeeeee',
    borderRadius: '5px',
    padding: '3px',
    borderTop: '0px',
    fontWeight: 'bold',
  },
}))
