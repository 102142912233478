import { LocalDate, TemporalAdjusters, ZonedDateTime, ZoneId } from '@js-joda/core'
import { Button, Checkbox, FormControlLabel, IconButton } from '@material-ui/core'
import { mdiCheck, mdiClose, mdiRestore } from '@mdi/js'
import { Icon } from '@mdi/react'
import {
  Formik,
  MColor,
  MDivider,
  MFieldConnector,
  MFlex,
  MFlexBlock,
  MFlexItem,
  MText,
  MTextColor,
  useMField,
  ValidationIssues,
  Yup,
} from '@mprise/react-ui'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AppName, Maybe, WorkItemType, WorkStatus } from '../../gateway/react.generated'
import { DialogFormik } from '../../mprise-light/DialogFormik'
import { Section } from '../../components/Section'
import { TagStatus } from '../../components/TagStatus'
import { FieldDateWithDialog } from '../../mprise-light/FieldDateWithDialog'
import { DayOfWeek } from '@js-joda/core'

export interface FilterValues {
  status: WorkStatus[]
  startDate?: Maybe<ZonedDateTime> | Maybe<LocalDate>
  endDate?: Maybe<ZonedDateTime> | Maybe<LocalDate>
}

export function getSearchVariables(args: {
  tab: 'my' | 'all'
  query: string
  filter: FilterValues
  companyId: string
  resourceId: string
}): any {
  const baseVariables: any = {
    query: args.query,
    companyId: args.companyId,
    status: args.filter.status ?? [],
    assignment: null,
    type: [WorkItemType.JobPickOrder, WorkItemType.JobWorkOrder],
    appName: AppName.GreenhouseApp,
    limit: 25,
    offset: 0,
  }

  if (args.tab === `my`) {
    baseVariables.assignment = null
    baseVariables.ownerResourceIds = [args.resourceId]
  }

  if (args.filter.startDate) {
    baseVariables.startDate =
      args.filter.startDate instanceof LocalDate
        ? args.filter.startDate.atStartOfDay().atZone(ZoneId.UTC).toString()
        : args.filter.startDate
  }

  if (args.filter.endDate) {
    baseVariables.endDate =
      args.filter.endDate instanceof LocalDate
        ? args.filter.endDate.atTime(23, 59, 59).atZone(ZoneId.UTC).toString()
        : args.filter.endDate
  }
  return baseVariables
}

export const FilterDialog = ({
  open,
  initialValues,
  title = 'Filter',
  onSave,
  onClose,
}: {
  open: boolean
  initialValues: FilterValues
  title?: string
  onSave: (form: FilterValues) => void
  onClose: () => void
}) => {
  const { t } = useTranslation()
  const schema = FilterDialog.useSchema()
  const formInstance = React.useRef<Formik.FormikProps<FilterValues>>(null)
  const handleCancel = () => {
    onClose()
  }
  const handleSubmit = (form: FilterValues) => {
    onSave({
      ...form,
    })
  }

  // Shows date selected in dashboard as default in filter. Must be LocalDate
  initialValues = {
    ...initialValues,
    startDate:
      initialValues.startDate instanceof ZonedDateTime
        ? initialValues.startDate.toLocalDate()
        : initialValues.startDate,
    endDate:
      initialValues.endDate instanceof ZonedDateTime ? initialValues.endDate.toLocalDate() : initialValues.endDate,
  }

  function setDateCurrentWeek(form: Formik.FormikProps<FilterValues> | null) {
    if (form) {
      const currentWeekMonday = LocalDate.now().with(TemporalAdjusters.previousOrSame(DayOfWeek.MONDAY))
      form.setFieldValue('startDate', currentWeekMonday)
      form.setFieldValue('endDate', currentWeekMonday.plusDays(6))
    }
  }

  function setDateNextWeek(form: Formik.FormikProps<FilterValues> | null) {
    if (form) {
      const nextWeekMonday = LocalDate.now().with(TemporalAdjusters.next(DayOfWeek.MONDAY))
      form.setFieldValue('startDate', nextWeekMonday)
      form.setFieldValue('endDate', nextWeekMonday.plusDays(6))
    }
  }

  return (
    <Formik.Formik
      enableReinitialize
      validationSchema={schema}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      innerRef={formInstance}
    >
      <DialogFormik
        open={open}
        onClose={handleCancel}
        title={title}
        children={
          <>
            <ValidationIssues />
            <Section>
              <div style={{ padding: '1rem 0.5rem 2rem 0.5rem' }}>
                <Formik.Field component={MFieldConnector} name='status' label={t(`STATUS`)}>
                  <MFlexBlock vertical padding={[0, 2]}>
                    <MFlex gap={1}>
                      {[WorkStatus.NotAssigned, WorkStatus.Todo].map(status => getStatusLabelFilterItem(status))}
                    </MFlex>
                    <MFlex gap={1}>{[WorkStatus.OnHold].map(status => getStatusLabelFilterItem(status))}</MFlex>
                    <MFlex gap={1}>
                      {[WorkStatus.InProgress, WorkStatus.Done].map(status => getStatusLabelFilterItem(status))}
                    </MFlex>
                  </MFlexBlock>
                </Formik.Field>
                <MDivider style={{ margin: '1rem 0' }} />
                <MFlex wrap='nowrap' gap={2} alignItems='start'>
                  <Formik.Field component={MFieldConnector} name='startDate' label={t(`START_DATE`)}>
                    <FieldDateWithDialog minWidth='xs' />
                  </Formik.Field>
                  <Formik.Field component={MFieldConnector} name='endDate' label={t(`END`)}>
                    <FieldDateWithDialog minWidth='xs' />
                  </Formik.Field>
                </MFlex>
              </div>

              <MFlexBlock className='gh-workItem-filter-date-jump-buttons' padding={[0, 4, 4, 4]}>
                <Button fullWidth={true} onClick={() => setDateCurrentWeek(formInstance.current)}>
                  {t(`CURRENT_WEEK`)}
                </Button>
                <Button
                  fullWidth={true}
                  onClick={() => setDateNextWeek(formInstance.current)}
                  style={{ marginLeft: '1rem' }}
                >
                  {t(`NEXT_WEEK`)}
                </Button>
              </MFlexBlock>

              <MFlexBlock alignItems='center' bgColor={MColor.paper} padding={[2, 4]}>
                <ResetFormButton originalValues={FilterDialog.emptyForm}></ResetFormButton>
              </MFlexBlock>
            </Section>
          </>
        }
      />
    </Formik.Formik>
  )
}

const getStatusLabelFilterItem = (status: WorkStatus) => {
  return (
    <MFlexItem shrink={0} key={status}>
      <CheckBoxItem name={status} value={status}>
        <TagStatus status={status} size='medium' />
      </CheckBoxItem>
    </MFlexItem>
  )
}

FilterDialog.emptyForm = {
  status: [WorkStatus.NotAssigned, WorkStatus.Todo, WorkStatus.InProgress, WorkStatus.OnHold],
  startDate: null,
  endDate: null,
} as Readonly<FilterValues>

FilterDialog.useSchema = () => {
  const { t } = useTranslation()
  const [schema] = useState(
    (): Yup.SchemaOf<FilterValues> =>
      Yup.object().shape({
        status: Yup.array()
          .of(Yup.mixed().oneOf(Object.values(WorkStatus)).required())
          .required()
          .label(t(`STATUS`)),
        startDate: Yup.mixed().optional().nullable().label(t(`START_DATE`)),
        endDate: Yup.mixed()
          .test(
            `startDate`,
            `End date must be greater than start date`,
            (input, context) =>
              !context.parent.startDate || !input || (context.parent.startDate as LocalDate)?.compareTo(input) <= 0,
          )
          .nullable()
          .label(t(`END`)),
      }),
  )
  return schema
}

FilterDialog.useDialogState = () => {
  const getFilterValues = () => {
    const storageFilter = sessionStorage.getItem('filterValues')

    if (storageFilter) {
      const filterValues = JSON.parse(storageFilter)
      const startDate = filterValues.startDate ? LocalDate.parse(filterValues.startDate) : null
      const endDate = filterValues.endDate ? LocalDate.parse(filterValues.endDate) : null

      return {
        ...filterValues,
        startDate,
        endDate,
      }
    } else {
      sessionStorage.setItem('filterValues', JSON.stringify(FilterDialog.emptyForm))
      return FilterDialog.emptyForm
    }
  }
  const [state, setState] = useState({
    open: false,
    values: getFilterValues(),
  })
  return {
    open: state.open,
    values: state.values,
    handleSave(values: FilterValues) {
      // Persist filterValues in sessionStorage
      sessionStorage.setItem('filterValues', JSON.stringify(values))

      setState(x => ({
        ...x,
        open: false,
        values,
      }))
    },
    handleOpen() {
      setState(x => ({
        ...x,
        open: true,
      }))
    },
    handleClose() {
      setState(x => ({
        ...x,
        open: false,
      }))
    },
  }
}

export function getRefetchItemsSearchVariables(tab: 'my' | 'all', companyId: string, resourceId: Maybe<string>) {
  // Returns variables for refetchQueries used on a different Route (finishTask mutation)
  const search = sessionStorage.getItem('searchText') ?? ''
  const storageFilter = sessionStorage.getItem('filterValues')

  let filter = null
  if (storageFilter) {
    const filterValues = JSON.parse(storageFilter)
    const startDate = filterValues.startDate ? LocalDate.parse(filterValues.startDate) : null
    const endDate = filterValues.endDate ? LocalDate.parse(filterValues.endDate) : null

    filter = { ...filterValues, startDate, endDate }
  } else {
    filter = FilterDialog.emptyForm
  }

  const searchVariables = getSearchVariables({
    tab: tab,
    filter: filter,
    companyId: companyId,
    resourceId: resourceId ?? '',
    query: search,
  })

  return searchVariables
}

const CheckBoxItem = ({ name, value, children }: { name: string; value: any; children?: React.ReactNode }) => {
  const f = useMField()
  const checked = Array.isArray(f.value) && f.value.includes(value)
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    f.onChange?.(toggle(f.value, value, e.target.checked))
  }
  return (
    <FormControlLabel
      control={
        <Checkbox
          icon={<Icon size={1} path={mdiClose} color={MTextColor.dark} />}
          checkedIcon={<Icon size={1} path={mdiCheck} />}
          color='primary'
          name={name}
          checked={checked}
          onChange={handleChange}
        />
      }
      label={
        <MText block textColor={MTextColor.dark}>
          {children}
        </MText>
      }
    />
  )
}

const toggle = <T extends unknown>(selected: T[], value: T, checked: boolean) => {
  const list = new Set(selected)
  if (checked) {
    list.add(value)
  } else {
    list.delete(value)
  }
  return Array.from(list)
}

const ResetFormButton = ({ originalValues }: { originalValues: any }) => {
  const { t } = useTranslation()
  const form = Formik.useFormikContext()
  const handleReset = () => {
    form.setValues(originalValues)
  }
  const unchanged = JSON.stringify(form.values) === JSON.stringify(originalValues)

  return (
    <IconButton color='inherit' style={{ paddingLeft: 0 }} disabled={unchanged} onClick={handleReset}>
      <Icon size={1} path={mdiRestore} color={unchanged ? '#bbb' : MColor.primary} />
      <MText block textColor={unchanged ? MTextColor.disabled : MTextColor.dark} style={{ paddingLeft: '0.25rem' }}>
        {t(`RESET_FILTER`)}
      </MText>
    </IconButton>
  )
}
