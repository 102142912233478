import { useApolloClient } from '@mprise/gateway-client'
import { MAvatar, MSelectItem, ToMSelectItem } from '@mprise/react-ui'
import React from 'react'
import { CurrentCompanyContainer } from '../../context/current-company'
import { ScanningContainer } from '../../context/scanning'
import {
  Position,
  PositionByFilterDocument,
  PositionByFilterQuery,
  PositionByFilterQueryVariables,
} from '../../gateway/react.generated'
import { PaginatedSearchAndSelect } from '../../mprise-light/PaginatedSearchAndSelect'
import { QueryErrorMessage } from '../apollo'
import { useDebounceValue } from '../debounce-value'
import { useLocalState } from '../local-state'
import { defined, NotOptional, fail } from '../typescript'
import { useAsync } from '../use-async'
import { DialogWithBackButton } from '../../components/Dialog'

export type PositionValue = NotOptional<
  NotOptional<PositionByFilterQuery['masterdata']['company']>['positions']['page'][0]
>

const toSelectItem = ToMSelectItem<PositionValue>({
  id: x => x.id,
  icon: () => <MAvatar.Icon.Position />,
  primary: x => x.code ?? '',
})

export const SelectCarrierCodeDialog = ({
  title,
  open,
  onClose,
  onSave,
}: {
  title: React.ReactNode
  open: boolean
  onClose: () => void
  onSave: (values: any) => void
}) => {
  const scanningStatus = ScanningContainer.useDefault()
  const [search, setSearch] = useLocalState(``, [open])
  const debouncedSearch = useDebounceValue(search, 500)
  const [cursor, setCursor] = useLocalState<string | null>(null, [search])

  const carrierCodeQuery = SelectCarrierCodeDialog.useQuery({
    cursor,
    query: debouncedSearch,
  })

  const handleSelect = async (item: MSelectItem<PositionValue> | null) => {
    onSave(item?.data ?? null)
  }

  const handleSubmit: React.FormEventHandler = async e => {
    e.stopPropagation()
    e.preventDefault()
    onClose()
  }

  const handleNextPage = (cursor: string) => {
    setCursor(cursor)
  }

  const nextCursor = carrierCodeQuery?.data?.nextCursor ?? null

  const carrierCodes = carrierCodeQuery.data?.page?.filter(defined).map(toSelectItem) ?? null

  return (
    <DialogWithBackButton open={open} title={title} onClose={onClose} onSubmit={handleSubmit}>
      <QueryErrorMessage query={carrierCodeQuery} />
      <PaginatedSearchAndSelect
        scanningSetting={scanningStatus.scanning}
        items={carrierCodes}
        nextCursor={nextCursor}
        search={search}
        value={null}
        onChange={handleSelect}
        onNextPage={handleNextPage}
        onSearch={setSearch}
        showSwitchKeyboardOption={true}
      />
    </DialogWithBackButton>
  )
}

SelectCarrierCodeDialog.useQuery = ({ query, cursor }: { query: string; cursor: string | null }) => {
  // carrierCode is a Position with boolean dynamic = true
  const { current: currentCompany } = CurrentCompanyContainer.useCurrent()
  const companyId = currentCompany?.id
  const apollo = useApolloClient()

  const a = useAsync<{
    page: Array<Pick<Position, `id` | `name` | `code`>>
    nextCursor: string | null
  }>(async () => {
    if (!companyId) {
      fail('expects company id')
    }

    const response = await apollo.query<PositionByFilterQuery, PositionByFilterQueryVariables>({
      query: PositionByFilterDocument,
      variables: {
        companyId,
        positionCursor: cursor,
        wherePosition: {
          removed_eq: false,
          dynamic_eq: true,
          code_containslower: query,
        },
        sortPosition: {
          code_ASC: 1,
        },
      },
    })
    const page = response.data.masterdata.company?.positions.page.filter(defined) ?? []
    const nextCursor = response.data.masterdata.company?.positions.nextCursor ?? null
    return { page, nextCursor }
  }, [query, cursor])

  return a
}
