import { MJoinChildren } from '@mprise/react-ui'
import {
  SearchWorkItemsQuery,
  WorkItemDetailsWithTasksQuery,
  WorkStatus,
  useWorkItemDetailsWithTasksLazyQuery,
} from '../../gateway/react.generated'
import { NotOptional } from '../../shared/typescript'
import { useHistory } from '../../shared/use-history'
import { useParams } from 'react-router'
import { Card } from '../../components/Card'
import { DialogHeaderBack } from '../../components/Header'
import { useEffect, useState } from 'react'
import { TaskItem } from './TaskItem'
import { useTranslation } from 'react-i18next'

export type WorkItemValue = NotOptional<
  NotOptional<NotOptional<SearchWorkItemsQuery['searchWorkItems']>['workItems']>[0]
>
export type WorkItemDetailsValue = NotOptional<WorkItemDetailsWithTasksQuery['workItem']>

export const WorkItemDetailsRoute = () => {
  const h = useHistory()
  const { t } = useTranslation()

  const workItemId = useParams().id ?? ''

  const [getWorkItem, workItemQueryResult] = useWorkItemDetailsWithTasksLazyQuery({
    fetchPolicy: 'cache-and-network',
    variables: { workItemId },
  })

  useEffect(() => {
    getWorkItem()
  }, [])

  const workItem = workItemQueryResult.data?.workItem ?? null

  const [selectedTaskId, setSelectedTaskId] = useState<string>('')

  useEffect(() => {
    if (workItem?.tasks && workItem.tasks.length > 0) {
      const hasClosedTask = workItem.tasks.some(task => task.status === WorkStatus.Closed)
      if (hasClosedTask) {
        const lastTask = workItem.tasks[workItem.tasks.length - 1]
        setSelectedTaskId(lastTask!.id)
        return
      }
      const firstTodoOrInProgressTask = workItem.tasks.find(
        task => task?.status === WorkStatus.Todo || task?.status === WorkStatus.InProgress,
      )
      setSelectedTaskId(firstTodoOrInProgressTask?.id ?? workItem.tasks[0]!.id)
    }
  }, [workItem])

  const handleToggleTask = ({ taskId }: { taskId: string }) => {
    setSelectedTaskId(x => (x === taskId ? `` : taskId))
  }

  return (
    <>
      <DialogHeaderBack title={t(`TASK_DETAILS`)} onClose={() => h.goBack()} />
      <div className='gh-taskDetails-page-subtitle'>
        {workItem ? `${workItem?.number} - ${workItem?.name}` : `\u00A0`}
      </div>
      <Card>
        <MJoinChildren divider={() => null}>
          {workItem &&
            workItem.tasks
              .slice()
              .sort((a, b) => a.order - b.order)
              .map((task, idx) => (
                <TaskItem
                  key={`${task.id}-${idx}`}
                  taskId={task.id}
                  workItemId={workItem.id}
                  open={selectedTaskId === task.id}
                  loading={workItemQueryResult.loading}
                  onToggle={handleToggleTask}
                />
              ))}
        </MJoinChildren>
      </Card>
    </>
  )
}
