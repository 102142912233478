import { Collapse } from '@material-ui/core'
import { MColor, MField, MFlex, MText } from '@mprise/react-ui'
import IconWarning from '@material-ui/icons/Warning'

export const ValidationFriendlyWarning = ({ open, text }: { open: boolean; text: string }): JSX.Element => {
  return (
    <Collapse in={open}>
      <MField.Container vertical>
        <MField.Content>
          <MFlex alignItems='center'>
            <IconWarning style={{ color: MColor.medium }} />
            <MText block textColor={MColor.medium} style={{ paddingLeft: '0.5rem' }}>
              {text}
            </MText>
          </MFlex>
        </MField.Content>
      </MField.Container>
    </Collapse>
  )
}
