import styled from '@emotion/styled'
import { MColor, MFlexBlock, MFlexItem, MText, MTextColor } from '@mprise/react-ui'
import { Badge } from '@mprise/react-ui/dist/controls/avatar/badge'
import { MaterialIcon } from '../../components/Icon'
import { JobInventoryDetailValue } from './SelectJobInventoryDetailDialog'
import { formatUnitOfMeasure } from '../formats'
import { useTranslation } from 'react-i18next'

export const JobInventoryDetailCard = ({
  jobInventoryDetail,
  handleSelect,
}: {
  jobInventoryDetail: JobInventoryDetailValue
  handleSelect: (values: JobInventoryDetailValue) => void
}) => {
  const { t } = useTranslation()

  const {
    job,
    position,
    sortingCode,
    productionPhase,
    remainingQuantity,
    unitOfMeasure,
    carrierCode,
    remQtyPerUnitOfMeasure,
  } = jobInventoryDetail

  const avatar = <MaterialIcon value='inventory' style={{ fontSize: `1rem` }} />

  return (
    <StyledButton type='button' onClick={() => handleSelect(jobInventoryDetail)}>
      <MFlexBlock alignItems='center' gap={2} padding={3} style={{ position: 'relative' }}>
        <MFlexBlock margin={2}>
          <Badge size='medium' color={MColor.dim} children={avatar} />
        </MFlexBlock>
        <MFlexBlock vertical>
          <MFlexItem>
            <MText inline textVariant='small bold' textColor={MTextColor.dark}>
              {job.code ?? ''} &nbsp;
            </MText>
            <MText inline textVariant='small bold' textColor={MTextColor.dark}>
              {' | '} &nbsp;
            </MText>
            <MText inline textVariant='small bold' textColor={MTextColor.dark}>
              {productionPhase} &nbsp;
            </MText>
            {sortingCode && (
              <MText inline textVariant='small' textColor={MTextColor.dark}>
                | &nbsp; {sortingCode}
              </MText>
            )}
          </MFlexItem>
          <MFlexItem>
            <MText inline textVariant='small bold' textColor={MTextColor.dark}>
              {remainingQuantity?.toLocaleString('nl-NL')}
            </MText>
            <MText inline textVariant='small' textColor={MTextColor.dark}>
              {` ${formatUnitOfMeasure(unitOfMeasure)?.toLowerCase()} `}
            </MText>
            {(remQtyPerUnitOfMeasure === 0 || remQtyPerUnitOfMeasure > 1) && (
              <MText inline textVariant='small' textColor={MTextColor.dark}>
                &nbsp; | &nbsp; {`${remQtyPerUnitOfMeasure}`} {' per UOM'}
              </MText>
            )}
          </MFlexItem>
          <MFlexItem>
            <MText inline textVariant='small' textColor={MTextColor.dark}>
              {job.item?.name ?? ''}
            </MText>
          </MFlexItem>
          <MFlexItem>
            <MText inline textVariant='small bold' textColor={MTextColor.dark}>
              {position.code} &nbsp;
            </MText>
            {carrierCode && (
              <MText inline textVariant='small' textColor={MTextColor.dark}>
                | &nbsp; {carrierCode}
              </MText>
            )}
          </MFlexItem>
        </MFlexBlock>
      </MFlexBlock>
    </StyledButton>
  )
}

export const StyledButton = styled.button`
  border: 0;
  display: block;
  width: 100%;
  appearance: none;
  padding: 0;
  border-bottom: 1px lightgrey solid;
  background: transparent;
  cursor: pointer;
  text-align: inherit;
  position: 'relative';
`
