import React, { ErrorInfo } from 'react'
import ReactDOM from 'react-dom/client'
import { createPluginStore, PluginProvider, RendererPlugin } from 'react-pluggable'
import { App } from './App'
import './index.css'
import reportWebVitals from './reportWebVitals'
import { PluginStoreWithPlugins } from './types'
import { MpriseGatewayApolloClient } from '@mprise/gateway-client'
import { buildClientSchema } from 'graphql'
import { Settings } from './settings'
import { TypedTypePolicies } from './gateway/react.generated'
import { GatewayGeneratedTypePolicies } from './gateway/react-type-policies.generated'
import GatewayFragment from './gateway/fragment.generated'
import { MpriseAuthProvider, MpriseIdentityServer } from '@mprise/react-auth'
import { DialogHeader } from './components/Header'
import { Caption } from './components/Caption'
import { MaterialIcon } from './components/Icon'
import { Button } from '@material-ui/core'
import { MColor, MFlex, MpriseTheme, MText } from '@mprise/react-ui'
import { FlashAlerts } from './shared/flash-alerts'
import { MpriseMuiThemeProvider } from './material-ui-theme'

export class ErrorBoundary extends React.Component<
  { children: React.ReactNode; handleUnrejectedPromise?: boolean },
  { error?: Error; errorInfo?: ErrorInfo }
> {
  static clearState() {
    localStorage.clear()
    sessionStorage.clear()
    window.location.href = `/`
  }

  static refresh() {
    window.location.reload()
  }

  constructor(props: { children: React.ReactNode; handleUnrejectedPromise?: boolean }) {
    super(props)

    this.state = {}
  }

  componentDidMount() {
    if (this.props.handleUnrejectedPromise) {
      window.addEventListener('unhandledrejection', this.promiseRejectionHandler)
    }
  }

  componentWillUnmount() {
    if (this.props.handleUnrejectedPromise) {
      window.removeEventListener('unhandledrejection', e => this.promiseRejectionHandler(e))
    }
  }

  promiseRejectionHandler = (e: PromiseRejectionEvent) => {
    console.error(`[error-boundary]`, e.reason)

    this.setState({ error: new Error(e.reason), errorInfo: e.reason })
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error(`[error-boundary]`, { error, errorInfo })
    this.setState({ error, errorInfo })
  }

  render() {
    if (this.state.error) {
      return (
        <>
          <DialogHeader title={'Oops!'} />
          <Caption icon={<MaterialIcon value='error' style={{ fontSize: `5rem` }} />}>An error occured</Caption>
          <MFlex justifyContent='space-evenly'>
            <Button
              style={{ backgroundColor: MColor.medium, color: MColor.paper, margin: '1rem', width: '10rem' }}
              variant='contained'
              onClick={ErrorBoundary.clearState}
            >
              Clear state
            </Button>
            <Button
              style={{ backgroundColor: MColor.primary, color: MColor.paper, margin: '1rem', width: '10rem' }}
              variant='contained'
              onClick={ErrorBoundary.refresh}
            >
              Reload
            </Button>
          </MFlex>
          <MFlex justifyContent='center' style={{ padding: `2rem` }}>
            <MText block>Error: {this.state.error.message}</MText>
          </MFlex>
        </>
      )
    } else {
      return this.props.children
    }
  }
}

// When new plugins are created, also add them in the ./types.ts

const pluginStore: PluginStoreWithPlugins = createPluginStore()
pluginStore.install(new RendererPlugin())
// Add more plugins

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

const schema = buildClientSchema(require(`./gateway/gateway.schema.json`))

const typePolicies: TypedTypePolicies = {
  ...(GatewayGeneratedTypePolicies as any),
  Query: {
    fields: {
      searchWorkItems: {
        keyArgs: [
          `query`,
          `companyId`,
          `ownerResourceIds`,
          `taskType`,
          `status`,
          `assignment`,
          `positionIds`,
          `startDate`,
          `endDate`,
          `removed`,
        ],
        merge(existing, incoming, { variables }) {
          if (variables && variables.offset === 0) {
            return incoming
          } else if (existing && existing.workItems) {
            const workItems = (existing.workItems ?? []).concat(incoming.workItems ?? [])
            return { ...incoming, workItems }
          } else {
            return incoming
          }
        },
      },
    },
  },
}

const provider = new MpriseIdentityServer(Settings.login, Settings.clientId)

root.render(
  <React.StrictMode>
    <MpriseTheme />
    <MpriseMuiThemeProvider>
      <ErrorBoundary handleUnrejectedPromise={false}>
        <PluginProvider pluginStore={pluginStore}>
          <MpriseAuthProvider mpriseId={provider}>
            <MpriseGatewayApolloClient
              schema={schema}
              gatewayUrl={Settings.gateway ?? 'http://localhost:4000/graphql'}
              typePolicies={typePolicies}
              possibleTypes={GatewayFragment.possibleTypes}
            >
              <FlashAlerts>
                <App />
              </FlashAlerts>
            </MpriseGatewayApolloClient>
          </MpriseAuthProvider>
        </PluginProvider>
      </ErrorBoundary>
    </MpriseMuiThemeProvider>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
