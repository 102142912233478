import { Formik, MField, MFieldConnector, MText } from '@mprise/react-ui'
import { useTranslation } from 'react-i18next'
import { CreateInventoryFormDefinition } from './Home'
import { Section } from '../../../components/Section'
import { nameof } from '../../../shared/typescript'
import { FieldPosition } from '../../../shared/form/FieldPosition'
import { useParams } from 'react-router-dom'
import { Lot, useWorkTaskByIdWithTaskResultsQuery } from '../../../gateway/react.generated'
import { FieldQuantity } from '../../../shared/form/FieldQuantity'
import { TaskDetailsSection } from '../../../shared/form/TaskDetailsSection'
import { Divider } from '@material-ui/core'
import { Card } from '../../../components/Card'
import { FieldLotNumber } from '../../../shared/form/FieldLotNumber'
import { formatUnitOfMeasure } from '../../../shared/formats'
import { useBeforeUnload } from '../../../shared/before-unload'

export const CreateInventoryForm = () => {
  const { t } = useTranslation()
  const fc = Formik.useFormikContext<CreateInventoryFormDefinition>()
  const taskId = useParams().taskId ?? ''

  useBeforeUnload(fc.dirty && !fc.isSubmitting)

  const taskQuery = useWorkTaskByIdWithTaskResultsQuery({ variables: { taskId: taskId } })
  const task = taskQuery.data?.workTask

  function handleChangeNumber(newValue: number, index: number) {
    const value = fc.values.reportedItemConsumptions[index] ?? {
      itemId: '',
      quantity: 0,
      lotNumbers: [],
      taskResultId: '',
    }
    value.quantity = newValue
    if (value.lotNumbers.length > 0) {
      value.lotNumbers = value.lotNumbers.map(lotNumber => ({ ...lotNumber, quantity: newValue }))
    }
    const newValues = [...fc.values.reportedItemConsumptions]
    newValues[index] = value

    fc.setValues(current => ({
      ...current,
      reportedItemConsumptions: newValues,
      dirty: true,
    }))
  }

  function handleChangeLot(newValue: Lot, index: number) {
    const value = fc.values.reportedItemConsumptions[index] ?? {
      itemId: '',
      quantity: 0,
      lotNumbers: [],
      taskResultId: '',
    }
    if (newValue) {
      value.lotNumbers = [{ lotId: newValue.id, lotNumber: newValue.lotNumber ?? '', quantity: 1 }] // unknown what this quantity field is supposed to represent
    } else {
      value.lotNumbers = []
    }
    const newValues = [...fc.values.reportedItemConsumptions]
    newValues[index] = value

    fc.setValues(current => ({
      ...current,
      reportedItemConsumptions: newValues,
      dirty: true,
    }))
  }

  const reportedQuantityPutAway = fc.values.reportedQuantityPutAway ?? 0
  const plannedQuantityPutAway = fc.values.plannedQuantityPutAway ?? 0
  const quantityUnitPutAway = formatUnitOfMeasure(fc.values.quantityUnitPutAway)

  return (
    <>
      {task && <TaskDetailsSection task={task} />}

      <Section>
        <Card>
          <div> {t(`WorkResultType.ITEM_CONSUMPTION`)}</div>

          <Divider style={{ marginTop: '5px', marginBottom: '20px' }} />

          {fc.values.reportedItemConsumptions?.map((value: any, i: number) => {
            const unitOfMeasure = formatUnitOfMeasure(value.quantityUnit)
            return (
              <>
                {i !== 0 && <Divider style={{ marginTop: '25px', marginBottom: '10px' }} />}

                {value.lotNumbersRequired ? (
                  <>
                    <MText block textVariant='small' style={{ marginLeft: '0.5rem', marginBottom: '-1rem' }}>
                      {value.itemName}
                    </MText>
                    <MField
                      vertical
                      name=''
                      label=''
                      value={value.lotNumbers?.[0]}
                      onChange={newValue => handleChangeLot(newValue, i)}
                    >
                      <FieldLotNumber title={t(`ASSIGN_LOT`)} label={t(`Lot`)} itemId={value.itemId} />
                    </MField>

                    <MField
                      vertical
                      name=''
                      label=''
                      value={value.quantity}
                      onChange={newValue => handleChangeNumber(newValue, i)}
                    >
                      <FieldQuantity
                        title={`${t(`QUANTITY`)} (${value.reportedQuantity} / ${
                          value.plannedQuantity
                        } ${unitOfMeasure})`}
                      />
                    </MField>
                  </>
                ) : (
                  <MField
                    vertical
                    name=''
                    label=''
                    value={value.quantity}
                    onChange={newValue => handleChangeNumber(newValue, i)}
                  >
                    <FieldQuantity
                      title={`${value.itemName} (${value.reportedQuantity} / ${value.plannedQuantity} ${unitOfMeasure})`}
                    />
                  </MField>
                )}
              </>
            )
          })}
        </Card>
      </Section>

      <Section>
        <Card>
          <div>{t(`WorkResultType.JOB_INVENTORY_PUTAWAY`)}</div>
          <Divider style={{ marginTop: '5px', marginBottom: '20px' }} />
          <Formik.Field component={MFieldConnector} name={nameof<CreateInventoryFormDefinition>('quantityPutAway')}>
            <FieldQuantity
              title={`${t(
                `QUANTITY`,
              )}  (${reportedQuantityPutAway} / ${plannedQuantityPutAway}  ${quantityUnitPutAway})`}
            />
          </Formik.Field>
          <Formik.Field component={MFieldConnector} name={nameof<CreateInventoryFormDefinition>('toPositionPutAway')}>
            <FieldPosition title='' label={`POSITION`} neverDisable />
          </Formik.Field>
        </Card>
      </Section>
    </>
  )
}

CreateInventoryForm.validate = (values: any) => {
  const reportedItemConsumptionErrors = []
  for (let i = 0; i < values.reportedItemConsumptions.length; i++) {
    const element = values.reportedItemConsumptions[i]
    if (element?.lotNumbersRequired) {
      if (element.quantity && !element.lotNumbers?.length) {
        reportedItemConsumptionErrors.push(`Select a lot for '${element.itemName}'`)
      } else if (!element.quantity && element.lotNumbers?.length) {
        reportedItemConsumptionErrors.push(`Add a quantity for '${element.itemName}'`)
      }
    }
  }
  if (
    !values.quantityPutAway &&
    values.reportedItemConsumptions.map((c: any) => c?.quantity ?? 0).reduce((a: number, b: number) => a + b) < 1
  ) {
    reportedItemConsumptionErrors.push(`Add a quantity for at least one field`)
  }

  const errors: any = {}
  if (reportedItemConsumptionErrors && reportedItemConsumptionErrors.length > 0) {
    errors.reportedItemConsumptions = reportedItemConsumptionErrors
  }

  const reportedJobInventoryPutAwayErrors = []
  if (values.quantityPutAway && (!values.toPositionPutAway || values.toPositionPutAway.id.length === 0)) {
    reportedJobInventoryPutAwayErrors.push(`Please select a position`)
  }

  if (reportedJobInventoryPutAwayErrors && reportedJobInventoryPutAwayErrors.length > 0) {
    errors.reportedJobInventoryPutAway = reportedJobInventoryPutAwayErrors
  }

  return errors
}
